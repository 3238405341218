(function(app, $) {
	var $doc = $(document);
	function Banner(type, additionalParameters = {}) {
		this.type = type;
		this.isOpened = false;
		this.markup = '';
		this.$markup = $();
		this.contentIsNotConfigured = false;
		this.isAutoFocusDisabled = additionalParameters.isAutoFocusDisabled;
		this.customAnimationCloseClass = additionalParameters.customAnimationCloseClass;
	}

	Banner.prototype.open = function(placeSelector) {
		const placeArea = placeSelector || '.js-banners-area-left';

		if (isContentConfigured.call(this)) {
			this.$markup = $(getMarkup.call(this));

			this.$markup.appendTo($(placeArea));

			const $closeButton = this.$markup.find('.js-close-banner');

			$closeButton.on('click', this.close.bind(this));

			const $content = this.$markup.find('.js-banner-content');

			if ($content.data('closeOnEsc') === true) {
				$doc.on('keydown.' + this.type, onEscKeyDownHandler.bind(this));

				$doc.on('dialog.showed.' + this.type, function() {
					$doc.off('keydown.' + this.type);
				}.bind(this));

				$doc.on('fancybox.closed.' + this.type, function() {
					$doc.on('keydown.' + this.type, onEscKeyDownHandler.bind(this));
				}.bind(this));
			}

			if ($content.data('closeOnOutsideClick') === true) {
				$doc.on('click.' + this.type, onOutsideClickHandler.bind(this));
			}

			this.isOpened = true;

			if (!this.isAutoFocusDisabled) {
				applyFocusFunctionality.call(this, this.$markup);
			}
		} else {
			this.contentIsNotConfigured = true;
			Banner.prototype.close.call(this);
		}
	};

	Banner.prototype.close = function() {
		if (this.isOpened) {
			this.isOpened = false;
		}

		$doc.off('keydown.' + this.type);
		$doc.off('click.' + this.type);
		$doc.off('dialog.showed.' + this.type);
		$doc.off('fancybox.closed.' + this.type);

		this.removeFromDOM().then(() => {
			if (!this.isAutoFocusDisabled) {
				this.focusedElementBeforeBannerOpened.focus();
			}
		});
	};

	Banner.prototype.removeFromDOM = function() {
		const deferred = $.Deferred();

		if (this.$markup instanceof jQuery && this.$markup.length > 0) {
			const animDuration = parseFloat(this.$markup.css('animation-duration'));

			const removeAndResolve = () => {
				this.$markup.remove();
				deferred.resolve();
			};

			if (animDuration === 0 && !this.customAnimationCloseClass) {
				removeAndResolve();
			} else {
				if (this.customAnimationCloseClass) {
					this.$markup.addClass(this.customAnimationCloseClass);
				}

				this.$markup.on('animationend', function() {
					removeAndResolve();
				}.bind(this));

				this.$markup.addClass('m-hide');
			}
		}

		return deferred.promise();
	};

	function isContentConfigured() {
		var htmlElement = document.createElement('div');
		htmlElement.innerHTML = this.markup;

		return htmlElement.getElementsByClassName('js-banner-content').length > 0;
	}

	function getMarkup() {
		return app.util.renderTemplate($('#bannerTemplate').html(), {
			type: this.type,
			markup: this.markup
		});
	}

	function applyFocusFunctionality($markup) {
		/**
		 * TODO: This should be done without check. By default browser set body as document.activeElement when
		 * non of the elements on the page is focused.
		 * We should implement something like focus on first element in DOM when page is loaded.
		 * https://allyjs.io/data-tables/focusable.html
		 * */
		this.focusedElementBeforeBannerOpened = document.activeElement === document.body ? $('body').find(':focusable').get(0) : document.activeElement;

		var $focusableElements = $markup.find(':focusable');
		this.firstFocusableElement = $focusableElements.first().get(0);
		this.lastFocusableElement = $focusableElements.last().get(0);

		if (this.firstFocusableElement) {
			this.firstFocusableElement.focus();
		}

		$markup.on('keydown.' + this.type, onFocusKeyDownHandler.bind(this));
	}

	function onFocusKeyDownHandler(e) {
		var banner = this;
		var KEY_CODE_TAB = 9;

		if (e.keyCode === KEY_CODE_TAB) {
			if (e.shiftKey) {
				handleBackwardTab(e);
			} else {
				handleForwardTab(e);
			}
		}

		function handleBackwardTab(event) {
			if (document.activeElement === banner.firstFocusableElement) {
				event.preventDefault();
				banner.focusedElementBeforeBannerOpened.focus();
			}
		}

		function handleForwardTab(event) {
			if (document.activeElement === banner.lastFocusableElement) {
				event.preventDefault();
				banner.focusedElementBeforeBannerOpened.focus();
			}
		}
	}

	function onEscKeyDownHandler(e) {
		var KEY_CODE_ESC = 27;
		if (e.keyCode === KEY_CODE_ESC) {
			this.close();
		}
	}

	function onOutsideClickHandler(e) {
		if ($(e.target).closest('.js-banner').length === 0 && !app.fancybox.isFancyboxEvent(e)) {
			this.close();
		}
	}

	app.banners = {};
	app.banners.Banner = Banner;
})((window.app = window.app || {}), jQuery);
