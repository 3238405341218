(function (app, $) {
	var $cache = {};
	var mobileMenuCurrentCategoryIndexes = [];
	var specificConfigs = app.componentsconfig.specific.global.components['global.header'];

	function initializeCache() {
		$cache = {
			window: $(window),
			document: $(document),
			body: $('body'),
			breakContainer : $('.js-brekpoint-container'),
			headerMain : $(!app.preferences.isMobileView ? '.js-header_main' : '.js-mobile-header_main'),
			scrollTop: 0,
			openedMenuBodyClass: "m-menu_opened",
			mainNavigationElementSel: '.js-main_navigation',
			mobileMenuCategoriesListSel: '.js-menu_branch',
			mobileMenuCollapsedClass : 'h-collapsed',
			mobileMenuCheckedClass : 'h-checked',
			mobileMenuInputCheckedClass : 'js-checked',
			headerLogoLink: $('.js-primary_logo-link'),
			tabletMenuBackdrop : $('<div>', {
				class : 'b-main_navigation-backdrop'
			}),
			newsletterpopupWrapperSel: '.js-newsletter_popup-wrapper',
			dynamicPromoBannerWrapperSel: '.js-dynamic-promo-banner_wrapper',
			dynamicPromoBannerSel: '.js-dynamic-promo-banner_block',
			dynamicPromoBanner: '.js-dynamic-promo-banner',
			dynamicPromoBannerVisibleClass: 'b-dynamic-promo-banner_block--visible',
			headerMenuToggleClass: 'js-header_menu_toggle',
			hiddenClass: 'h-hidden',
			header: $('header'),
			serviceMenuEl: $('.js-header_service_menu'),
			headerMenuItem: $('.js-menu_category-item'),
			headerTransparent: $('.js-transparent_header'),
			headerTransparentClass: 'b-transparent_header',
			headerNoTransitionClass: 'b-no-transition',
			whiteHeaderClass: 'b-white_header',
			headerSlimClass: 'slim-header',
			navigationMenu: $('.js-main_navigation'),
			minicartTitle: $('.js-mini_cart-title'),
			miniCartWrapper: $('.js-mini_cart'),
			wishlistTitle: $('.js-wish_list-title'),
			wishlistWrapper: $('.js-wish_list'),
			hMinimizedClass: 'h-minimized',
			hToggledClass: 'h-toggled',
			menuCategoryItemSel:
				'.js-menu_category-item, .js-menu_category-level_2-item, .js-menu_category-level_3-item, .js-menu_category-level_4-item',
			menuSubcategoryItemSel:
				'.js-menu_category-level_2-item, .js-menu_category-level_3-item, .js-menu_category-level_4-item',
			toggledFlyoutFlag: false,
			events: {
				toggledFlyout: 'toggler.toggled',
				untoggledFlyout: 'toggler.untoggled',
				bodyPaddingUpdated: 'body-padding-updated'
			},
			isMobileView: app.device.isMobileView(),
			headerFlyoutContainer: $('.js-header-flyout-container'),
			flyoutWrapperSelector: '.js-slide-flyout-wrapper',
			scrollBreakPoint: specificConfigs.scrollConfig.breakPoint
		};

		$cache.productShare = $($cache.productShareSel);
		$cache.mainNavigationElement = $($cache.mainNavigationElementSel);
		$cache.dynamicPromoBannerElement = $($cache.dynamicPromoBanner);
	}

	function initializeEvents() {
		$cache.document.on('header.change', function (e) {
			$cache.scrollTop = $cache.body.data('scrollTop');

			if($(e.target).hasClass($cache.headerMenuToggleClass)) {
				if(!$($cache.newsletterpopupWrapperSel).length && !$($cache.dynamicPromoBanner).hasClass($cache.hiddenClass)) {
					$($cache.dynamicPromoBannerSel).toggleClass($cache.dynamicPromoBannerVisibleClass);
					$cache.headerMain.trigger('update.header');
				}
			}

			if(!$cache.body.hasClass($cache.openedMenuBodyClass)) {
				$cache.body.data('scrollTop', $cache.window.scrollTop());
			}
		});

		initHeaderCustomVarsEvents();
		initMiniCartFlyOutEvents();
		initWishListFlyOutEvents();
		initFlyOutEvents();

		if (!app.device.isMobileView()) {
			$cache.document.on('onscroll.updated', function() {
				if ($cache.window.scrollTop() === 0 && app.page.ns === 'search') {
					$cache.headerMain.trigger('update.header');
				}
			});
		}

		$cache.document.on('category_menu.open', function() {
			$cache.scrollTop = $cache.body.data('scrollTop');

			if(!$cache.body.hasClass($cache.openedMenuBodyClass)) {
				$cache.window.scrollTop($cache.scrollTop);
				$cache.body.removeData('scrollTop');
				$cache.tabletMenuBackdrop.remove();
			}
			else {
				$cache.body.append($cache.tabletMenuBackdrop);
			}
		});

		if ($cache.headerTransparent.length > 0) {
			initMouseEvents($cache.header);
			initMouseEvents($($cache.menuCategoryItemSel));
			$cache.document.on($cache.events.toggledFlyout, function(e, obj) {
				if ($cache.header.find(obj.$toggleElement).length) {
					setTransperentHeader(false);
					$cache.toggledFlyoutFlag = true;
				}
			}).on($cache.events.untoggledFlyout, function(e, obj) {
				if ($cache.header.find(obj.$toggleElement).length) {
					$cache.toggledFlyoutFlag = false;
				}
			});
		}
	}

	function initHeaderCustomVarsEvents() {
		$cache.document.on($cache.events.bodyPaddingUpdated, function() {
			var headerStaticHeight = $cache.isMobileView ? $cache.serviceMenuEl.outerHeight() : $cache.header.outerHeight();

			document.documentElement.style.setProperty('--promo-height', $($cache.dynamicPromoBannerSel).outerHeight() + 'px');
			document.documentElement.style.setProperty('--header-static-height', headerStaticHeight + 'px');

			document.documentElement.style.setProperty('--viewport-height', $cache.window.outerHeight() + 'px');
		});
	}

	function initMiniCartFlyOutEvents() {
		if (!$cache.isMobileView) {
			$cache.minicartTitle.on('click', function() {
				if (!app.flyoutMgr.isOpen('minicart')) {
					app.flyoutMgr.open('minicart').then(function() {
						$cache.document.trigger('minicart.afterload');
					});
				} else {
					app.flyoutMgr.close('minicart');
				}

				return false;
			});

			$cache.document.on('minicart.flyout.open', function() {
				openFlyoutHandler($cache.miniCartWrapper);
			});

			$cache.document.on('minicart.flyout.closed', function() {
				$cache.miniCartWrapper.find($cache.flyoutWrapperSelector).removeClass('active');
			});
		}
	}

	function initWishListFlyOutEvents() {
		if (!$cache.isMobileView) {
			$cache.wishlistTitle.on('click', function() {
				if (!app.flyoutMgr.isOpen('wishlist')) {
					app.flyoutMgr.open('wishlist');
					$cache.document.trigger('wishlist.afterload');
				} else {
					app.flyoutMgr.close('wishlist');
				}

				return false;
			});

			$cache.document.on('wishlist.flyout.open', function() {
				openFlyoutHandler($cache.wishlistWrapper);
			});

			$cache.document.on('wishlist.flyout.closed', function() {
				$cache.wishlistWrapper.find($cache.flyoutWrapperSelector).removeClass('active');
			});
		}
	}

	function initFlyOutEvents() {
		if (!$cache.isMobileView) {
			$cache.document.on('flyout.animationend', function() {
				app.components.toggler.custom.updateBodyPaddingTop($cache.header.height());
			});

			$cache.document.on('toggle.closed', function() {
				if (app.components.toggler.custom.areTogglesClosed()) {
					$cache.headerFlyoutContainer.find($cache.flyoutWrapperSelector).addClass('active');
				}
			});
		}
	}

	/**
	* @function
	* @description opens flyout if toggler components are closed
	* @param {jQuery} $flyoutWrapper - current flyout wrapper
	*/
	function openFlyoutHandler($flyoutWrapper) {
		if (app.components.toggler.custom.areTogglesClosed()) {
			$flyoutWrapper.find($cache.flyoutWrapperSelector).addClass('active');
		} else {
			$cache.document.trigger('toggle.hideall', {
				srcFlyout: true
			});
		}
	}

	function initMouseEvents($el) {
		$el
			.on('mouseenter', function() {
				if (!$cache.header.hasClass($cache.headerSlimClass)) {
					setTransperentHeader(false);
				}
			})
			.on('mouseleave', function() {
				if (
					!$cache.header.hasClass($cache.headerSlimClass)
					&& !$cache.header.hasClass($cache.whiteHeaderClass)
					&& !($cache.header.is(':hover'))
					&& !$cache.toggledFlyoutFlag
				) {
					setTransperentHeader(true);
				}
			});
	}

	function setTransperentHeader(enabled) {
		$cache.header.toggleClass($cache.headerTransparentClass, enabled);
		$cache.header.toggleClass($cache.headerNoTransitionClass, !enabled);
	}

	function initMobileMenu() {
		$cache.headerLogoLink.click(function() {
			$.removeCookie('mobileMenuNavigationIndex', {
				path: '/'
			});
		});

		$cache.document.on('body.changed', function () {
			initFirstList();
			var cookie = $.cookie('mobileMenuNavigationIndex');

			if(cookie !== undefined) {
				mobileMenuCurrentCategoryIndexes = JSON.parse($.cookie('mobileMenuNavigationIndex'));
				var length = mobileMenuCurrentCategoryIndexes.length;

				$.each(mobileMenuCurrentCategoryIndexes, function (index, value) {
					$cache.mobileMenuCategoriesContainer = $cache.mobileMenuNavigation.find($cache.mobileMenuCategoriesListSel).first();
					$cache.mobileMenuCategoriesList = $cache.mobileMenuCategoriesContainer.children('li');
					$cache.mobileMenuCategoriesContainer.addClass($cache.mobileMenuCollapsedClass);
					$cache.mobileMenuCategoriesItem = $($cache.mobileMenuCategoriesList.get(value));
					$cache.mobileMenuCategoriesItem.addClass($cache.mobileMenuCheckedClass).children('input').first().prop('checked', true).addClass($cache.mobileMenuInputCheckedClass);

					if(index < length) {
						$cache.mobileMenuNavigation = $cache.mobileMenuCategoriesItem;
					}
				});
			}
		});

		$cache.document.on('categories_navigation.opened', function (event, data) {
			var index = $cache.mobileMenuCategoriesList.index(data.item.parent());

			mobileMenuCurrentCategoryIndexes.push(index);

			if(index < 0) {
				mobileMenuCurrentCategoryIndexes.pop();
				$cache.mobileMenuCategoriesList = $($cache.mobileMenuCategoriesList.get(mobileMenuCurrentCategoryIndexes[0])).children($cache.mobileMenuCategoriesListSel).first().children('li');
				index = $cache.mobileMenuCategoriesList.index(data.item.parent());
				mobileMenuCurrentCategoryIndexes.push(index);
			}

			$.cookie('mobileMenuNavigationIndex', JSON.stringify(mobileMenuCurrentCategoryIndexes), {
				path: '/'
			});
		});

		$cache.document.on('categories_navigation.back', function () {
			mobileMenuCurrentCategoryIndexes.pop();

			if(mobileMenuCurrentCategoryIndexes.length === 0) {
				initFirstList();
			}

			$.cookie('mobileMenuNavigationIndex', JSON.stringify(mobileMenuCurrentCategoryIndexes), {
				path: '/'
			});
		});

		function initFirstList() {
			$cache.mobileMenuNavigation = $($cache.mainNavigationElementSel).first();
			$cache.mobileMenuCategoriesContainer = $cache.mobileMenuNavigation.find($cache.mobileMenuCategoriesListSel).first();
			$cache.mobileMenuCategoriesList = $cache.mobileMenuCategoriesContainer.children('li');
		}
	}


	/*************** app.components.header.custom public object ***************/
	app.components = app.components || {};
	app.components.header = app.components.header || {};
	app.components.header.custom = {
		init : function () {
			initializeCache();
			initializeEvents();

			if(app.device.isMobileView()) {
				initMobileMenu();
			}
		},
		headerBreakpointCalc: function() {
			return $cache.scrollBreakPoint;
		}
	};
})(window.app = window.app || {}, jQuery);
