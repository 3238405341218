/*
	jCarousel expects a very basic HTML markup structure inside your HTML document:
	<div class="jcarousel"> <--------------------------------| Root element
		<ul> <-------------------------------| List element  |
			<li>...</li> <---| Item element  |               |
			<li>...</li> <---| Item element  |               |
		</ul> <------------------------------|               |
	</div> <-------------------------------------------------|

*/
/**
 * @class app.owlcarousel
 */
(function (app, $) {
	var $cache = {},
		$selectors = {
			carousel	: ".js-j_carousel",
			next		: ".jcarousel-next",
			prev		: ".jcarousel-prev",
			pagination	: ".jcarousel-pagination"
		},
		$elements = {
			wrapper 	: function (){return $("<div>", {"class" : "jcarousel-wrapper"})},
			next		: function (){return $("<a>", {"href" : "javascript:void(0)", "class" : "jcarousel-next"})},
			prev		: function (){return $("<a>", {"href" : "javascript:void(0)", "class" : "jcarousel-prev"})},
			pagination	: function (){return $("<div>", {"class" : "jcarousel-pagination"})}
		},
		customSettings = app.jcarousel && app.jcarousel.settings ? app.jcarousel.settings : {},
		//Documentation can be find here:
		// http://sorgalla.com/jcarousel/docs/
		defaultSettings = {
			// A function or a jQuery selector to select the list inside the root element.
			// A function will be called in the context of the carousel instance.
			list : function(){return this.element().children("ul").eq(0);},
			
			// A function or a jQuery selector to select the items inside the list element.
			// A function will be called in the context of the carousel instance.
			items : function(){return this.list().children("li");},	
			
			//Important jcarousel features
			//The number of items you want to see on the screen. 
			//Type: Number. Default: 1
			visibleItems : 1,
			
			// Important jcarousel features for responsive view
			//The number of items you want to see on the screen for different resolution.
			//Has more priority than visibleItems attribute
			//Example : 1024:3|1366:4|1920:6
			breakpoints : "",
			// The speed of the scroll animation as string in jQuery terms ("slow" or "fast") or milliseconds as integer (See the documentation for jQuery animate).
			// Alternatively, this can be a map of options like the one jQuery.animate accepts as second argument.
			animation : 400,
		
			// If set to true, CSS3 transitions are used for animations.
			// Alternatively, this can be a map of the following options:
			//	transforms: If set to true, 2D transforms are used for better hardware acceleration.
			//	transforms3d: If set to true, 3D transforms are used for full hardware acceleration.
			//	easing: Value will be used as the transition-timing-function (e.g. ease or linear).
			// jCarousel does not check if the user's browser supports transitions and/or transforms. You have to do that yourself when setting the option.
			transitions : Modernizr.csstransitions ? {
					transforms : Modernizr.csstransforms,
					transforms3d : Modernizr.csstransforms3d,
					easing: 'ease'
				} : false,
		
			// Specifies whether to wrap at the first or last item (or both) and jump back to the start/end. Options are "first", "last", "both" or "circular" as string.
			// If set to null, wrapping is turned off (default).
			wrap : null,
		
			// Specifies whether the carousel appears in vertical orientation. Changes the carousel from a left/right style to a up/down style carousel.
			// If set to null, jCarousel tries to auto-detect the orientation by simply checking if the list's height is greater than the list's width.
			vertical : null,
		
			// Specifies wether the carousel appears in RTL (Right-To-Left) mode.
			// If set to null, jCarousel looks for dir="rtl" attribute on the root element (or to any of its parent elements) and if found, automatically sets rtl to true.
			rtl : null,
		
			// Specifies wether the targeted item should be centered inside the root element.
			center : false,
		
			//Start position or URL Hash string like '#id'.
			//Type: Number/String. Default: 0
			startPosition : 0,
			
			// The jCarousel Control Plugin provides controls for jCarousel.
			// http://sorgalla.com/jcarousel/docs/plugins/control/
			control : {
				//Show next/prev buttons.
				enable : true,
				//The target for the control. This is basically the same as the first argument the scroll method acceppts.
				slideBy : 1,
				//The event which triggers the control.
				event : 'click',
				//The method to call on the carousel.
				//Alternatively, method can be a function which will be called in the context of the plugin instance.
				method: 'scroll',
				//The corresponding carousel as jQuery object.
				//This is optional. By default, the plugin tries to autodetect the carousel.
				carousel : null
			},
			// The jCarousel Pagination Plugin provides dots or other navigation
			// http://sorgalla.com/jcarousel/docs/plugins/pagination/
			pagination : {
				//Show  dots or other pagination.
				enable : false,
				//The number of carousel items per page or a function returning the number.
				perPage : null,
				//A function returning the markup for a page item of the pagination either as string or jQuery object.
				//The function will be called in the context of the plugin instance and receives two arguments:
				// 1. The page number.
				// 2. A jQuery object containing the carousel items visible on this page.
				
				item : function(page, carouselItems) {
					return '<a href="#' + page + '">' + page + '</a>';
				},
				// The corresponding carousel as jQuery object.
				// This is optional. By default, the plugin tries to autodetect the carousel.
				carousel : null
			},
			// The jCarousel Autoscroll Plugin provides autoscrolling.
			// http://sorgalla.com/jcarousel/docs/plugins/autoscroll/
			autoscroll : {
				//Enable autoscrolling
				enable : false,
				//The target for the autoscrolling. This is basically the same as the first argument the scroll method acceppts.
				slideBy : 1,
				//The autoscrolling interval in milliseconds.
				interval : 3000,
				//Whether to autostart autoscrolling.
				autostart: true,
				//CUSTOM CONFIGUTATION Stop autoscroll on hover
				stoponhover : false
			},
			// The jCarousel Swipe Plugin adds support for user-friendly swipe gestures.
			// https://github.com/snake-345/jcarouselSwipe
			swipe : {
				//Enable swipe plugin
				enable: Modernizr.touchevents,
				//How many slides will be scrolls at a go.
				perSwipe : 1,
				//On/off dragging items on swipe.
				draggable : false,
				//The method to call on the carousel.
				method : "scroll"
			}, 
			
			// The jCarousel LazyLoading Plugin adds lazy loading to jcarousel.
			// https://github.com/snake-345/jcarouselLazyLoading
			lazy : false,
			
			// The jCarousel SmoothScroll Plugin forces a carousel to animate at a constant pace, regardless of varying item widths. 
			// https://github.com/aduth/jcarousel-smoothscroll
			smooth : false,
			
			// The jCarousel Status Plugin adds tatus of current carousel.
			// https://github.com/fzoccara/jcarousel-status
	
			// Callbacks -
			// http://sorgalla.com/jcarousel/docs/reference/events.html
			events : {
				
				carousel :{
					// A functions will be called in the context of the carousel instance.
					//
					//	.on('jcarousel:create', function(event, carousel) {
					//		Do something
					//	})
					
					// Triggered on creation of the carousel.
					create : null,
					// Triggered after creation of the carousel.
					createend : null,
					// Triggered when the reload method is called.
					reload : null,
					// Triggered after the reload method is called.
					reloadend : null,
					// Triggered when the destroy method is called.
					destroy : null,
					// Triggered after the destroy method is called.
					destroyend : null,
					// Triggered when the scroll method is called.
					scroll : null,
					// Triggered after the scroll method is called.
					// Note: This method is triggered at the end of the scroll method and not when the animation is finished.
					scrollend : null,
					// Triggered when the carousel starts a animation. 
					animate : null,
					// Triggered after the carousel has finished a animation.
					animateend : null
				},
				item : {
					// These events are triggered on the item elements. The recommended way is to bind via delegated events:
					//
	 				//	$('.jcarousel').on('jcarousel:targetin', 'li', function() {
					//		$(this).addClass('active');
					//	});
					
					// Triggered when the item becomes the targeted item.
					targetin : null,
					// Triggered when the item is no longer the targeted item.
					targetout : null,
					// Triggered when the item becomes the first visible item.
					firstin : null,
					// Triggered when the item is no longer the first visible item.
					firstout : null,
					//Triggered when the item becomes the last visible item.
					lastin : null,
					// Triggered when the item is no longer the last visible item.
					lastout : null,
					// Triggered when the item becomes a visible item.
					visiblein : null,
					// Triggered when the item is no longer a visible item.
					visibleout : null,
					// Triggered when the item becomes a fully visible item.
					fullyvisiblein : null,
					// Triggered when the item is no longer a fully visible item.
					fullyvisibleout : null
				}
			},
			//CUSTOM PREFERENCE, force user watch video until the end
			holdAdvertisementVideo : {
				enable: false,
				//CUSTOM CONFIGUTATION Used by "holdAdvertisementVideo" mechanizm to decide is controls disappeared
				hideControls: true,
				//CUSTOM CONFIGUTATION Used by "holdAdvertisementVideo" mechanizm when controls are disappeared
				fadeDuration : 'slow'
			}
	};

	function initCache(){
		$cache.carousels = $($selectors.carousel);
	}

	function initCarousel() {
		var $carousel = $(this),
			$carouselWrapper = $carousel.parent(),
			carouselSettings = {},
			inited = $carousel.data("jcarousel"),
			dataSettings = $carousel.data('settings') ? $carousel.data('settings') : "",
			pluginsArray = [];

		if ( dataSettings && customSettings.hasOwnProperty(dataSettings)) {
			carouselSettings = $.extend(true, {}, defaultSettings, customSettings[dataSettings]);
		}

		calculateVisibleItems(carouselSettings);

		if (!inited){
			// NEW CAROUSEL INITIALIZATION BLOCK
			initVisibleItems(carouselSettings,  $carousel);
			$carouselWrapper = $carousel.wrap($elements.wrapper).parent();
			initCarouselEvents(carouselSettings.events.carousel, $carousel);
			initItemEvents(carouselSettings.events.item, $carousel);
			carouselSettings.startPosition && scrollTo($carousel, carouselSettings.startPosition);
			
			// FINALY initialize new carousel
			$carousel.jcarousel(carouselSettings);
			
			// INITIALIZE ADDITIONAL PLUGINS BLOCK
			"jcarouselControl" in $ && carouselSettings.control && carouselSettings.control.enable && pluginsArray.push([initControl,[carouselSettings.control, $carouselWrapper]]);
			"jcarouselPagination" in $ && carouselSettings.pagination && carouselSettings.pagination.enable && pluginsArray.push([initPagination,[carouselSettings.pagination, $carouselWrapper]]);
			"jcarouselAutoscroll" in $ && carouselSettings.autoscroll && carouselSettings.autoscroll.enable && pluginsArray.push([initAutoscroll,[carouselSettings.autoscroll]]);
			"jcarouselSwipe" in $ && carouselSettings.swipe && carouselSettings.swipe.enable && pluginsArray.push(['jcarouselSwipe',[carouselSettings.swipe]]);
			"jcarouselLazyLoading" in $ && carouselSettings.lazy && pluginsArray.push(['jcarouselLazyLoading']);
			"jcarouselSmoothScroll" in $ && carouselSettings.smooth && pluginsArray.push(['jcarouselSmoothScroll']);
			
			// INITIALIZE functions after all plugins has been inited
			carouselSettings.holdAdvertisementVideo.enable && pluginsArray.push([holdAdvertisementVideo]);
			
			//INIT all plugins and wrapper functions
			pluginsArray.reduce(function(prev, next) {
				if(typeof next[0] === 'string'){
					return prev[next[0]](next[1]);
				}
				return next[0].apply(prev, next[1]);
			}, $carousel );
			
		} else {
			// RELOAD EXISTING CAROUSEL BLOCK
			$carousel.jcarousel('reload', carouselSettings);
		}
		
		toggleControl($carousel, carouselSettings.visibleItems);
	}
	function initCarouselEvents (hendlers, carousel){
		for (var hendler in hendlers){
			if (hendlers[hendler] && typeof hendlers[hendler] === "function"){
				carousel.on('jcarousel:'+hendler, hendlers[hendler]);
			}
		}
	}
	function initItemEvents (hendlers, carousel){
		//Work with video tags inside
		carousel.on("jcarousel:targetin", "li", holdAdvertisementVideo);
		
		for (var hendler in hendlers){
			if (hendlers[hendler] && typeof hendlers[hendler] === "function"){
				carousel.on('jcarousel:'+hendler, "li", hendlers[hendler]);
			}
		}
	}
	
	function holdAdvertisementVideo(event, carousel){
		var carousel = carousel || this.data('jcarousel'),
			options = carousel.options(),
			carouselBlock = event ? this : carousel.first().get(0),
			videoTags = carouselBlock.getElementsByTagName('video'),
			videoTag = videoTags && videoTags[0];
		
		if(videoTag && videoTag.autoplay){
			// This part of code guaranteed that user watch video once from beginning to the end.
			if( options.holdAdvertisementVideo.enable ) {

				var $carousel = carousel.carousel(),
				isAutoscrollLoaded = $carousel.data("jcarouselAutoscroll"),
				isAutoscrollEnable = options.autoscroll && options.autoscroll.enable,
				$controls;

				if (options.holdAdvertisementVideo.hideControls){
					$controls = $carousel.parent().find($selectors.next + ',' + $selectors.prev);
				}
				
				if( !carouselBlock.hasAttribute('data-videoadvhold') ){
					carouselBlock.setAttribute('data-videoadvhold', 'performed');
					
					if(videoTag.loop){
						//This need because otherwise we cannot detect when video is ended
						videoTag.loop = false;
						videoTag.setAttribute('data-videoloop', true);
					} 
					
					if (isAutoscrollEnable && isAutoscrollLoaded){
						$carousel.jcarouselAutoscroll('stop');
					}
		
					$controls && $controls.length && $controls.fadeOut(options.holdAdvertisementVideo.fadeDuration);
					
					$(videoTag).one('ended', function(){
						if( this.hasAttribute('data-videoloop') ){
							this.loop = true;
						}
						this.play();
						
						isAutoscrollLoaded = $carousel.data("jcarouselAutoscroll");
						if (isAutoscrollEnable && isAutoscrollLoaded){
							$carousel.jcarouselAutoscroll('start');
						}
						
						$controls = $controls && ( $controls.length ? $controls : $carousel.parent().find($selectors.next + ',' + $selectors.prev) );
						$controls && $controls.length && $controls.fadeIn();
						carouselBlock.setAttribute('data-videoadvhold', 'finished');
					})
				} else if (carouselBlock.getAttribute('data-videoadvhold') === 'performed') {
					if (isAutoscrollEnable && isAutoscrollLoaded){
						$carousel.jcarouselAutoscroll('stop');
					}
					$controls && $controls.length && $controls.fadeOut(options.holdAdvertisementVideo.fadeDuration);
				}
			}
			//Start autoplay video
			videoTag.play();
		}
		return this;
	}
	
	function calculateVisibleItems(settings){
		if (settings.breakpoints){
			var viewPortWidht = window.innerWidth,	
				breakpoints = settings.breakpoints.split("|"),
				count = null;
			for (var i = 0, length = breakpoints.length - 1; i < length; i++){
				var point = breakpoints[i].split(":"),
					nextPoint = breakpoints[i+1].split(":");
				if (viewPortWidht < point[0]){
					count = parseInt(point[1]);
					break;
				} else if (viewPortWidht > point[0] && viewPortWidht < nextPoint[0]){
					count = parseInt(nextPoint[1]);
					break;
				}
			}
			if (!count){
				count = parseInt(nextPoint[1]);
			}
			settings.visibleItems = count;
		}
	}
	function initVisibleItems (settings, carousel){
			carousel.on('jcarousel:reload jcarousel:create', function() {
				calculateVisibleItems(settings);

				var $this = $(this),
					items = $this.children("ul").children("li"),
					rect = $this[0].getBoundingClientRect(),
					width,
					height;

				if (rect.width) {
					width = rect.width;
					height = rect.height;
				} else {
					width = rect.right - rect.left;
					height = rect.bottom - rect.top;
				}


				if (!settings.vertical){
					items.css('width', Math.ceil(width/parseInt(settings.visibleItems)) + 'px');
				} else {
					items.css('height', Math.ceil(height/parseInt(settings.visibleItems)) + 'px');
				}

				toggleControl($this, settings.visibleItems);
			});
	} 
	
	function scrollTo (carousel, position){
		carousel.on('jcarousel:createend', function() {
			$(this).jcarousel('scroll', position, false);
		});
	}
	function initControl (settings, wrapper){
		wrapper.append($elements.prev);
		wrapper.find($selectors.prev)
		.on('jcarouselcontrol:active', function() {
			$(this).addClass('active');
		})
		.on('jcarouselcontrol:inactive', function() {
			$(this).removeClass('active');
		})
		.jcarouselControl({
			target		: '-='+ settings.slideBy,
			event		: settings.event,
			method		: settings.method,
			carousel	: this
		});
		wrapper.append($elements.next);
		wrapper.find($selectors.next)
		.on('jcarouselcontrol:active', function() {
			$(this).addClass('active');
		})
		.on('jcarouselcontrol:inactive', function() {
			$(this).removeClass('active');
		})
		.jcarouselControl({
			target		: '+=' + settings.slideBy,
			event		: settings.event,
			method		: settings.method,
			carousel	: this
		});
		
		return this;
	}
	
	function initPagination (settings, wrapper){
		wrapper.append($elements.pagination);
		wrapper.find($selectors.pagination).jcarouselPagination({
			perPage		: settings.perPage,
			item		: settings.item,
			carousel	: this
		});
		
		return this;
	} 
	function initAutoscroll (settings){
		this.jcarouselAutoscroll({
			interval	: settings.interval,
			target		: '+=' + settings.slideBy,
			autostart	: settings.autostart
		});

		// Initialize stop on hover configuration
		if(settings.stoponhover){
			this.hover(function(){
				$(this).jcarouselAutoscroll('stop');
			}, function(){
				$(this).jcarouselAutoscroll('start');
			});
		}
		
		return this;
	}
	function toggleControl(carousel, count){
		var items = carousel.children("ul").children("li"),
			wrapper = carousel.parent();
		if (items.length <= count){
			wrapper.find($selectors.prev+", "+ $selectors.next).addClass('hide').hide();
		} else {
			wrapper.find($selectors.prev+", "+ $selectors.next).addClass('show').show(); 
		}
	}
	function initCarousels() {
		$($selectors.carousel).each(initCarousel);
	}

	app.jcarousel = app.jcarousel || {};
	app.jcarousel = $.extend(true, app.jcarousel, {
		init : function () {
			initCache();
			initCarousels();
		},
		getInstance  : function(instanceID){
			if (!instanceID) {return;} 
			return $(document).find(instanceID).data('jcarousel'); 
		},
		initCarousel : function($carousel){
			initCarousel.call($carousel);
		}
	});
	
}(window.app = window.app || {}, jQuery));