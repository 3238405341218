import CSRF from 'oneapp/src/utils/CSRF';
import ServiceWorkerUtils from 'oneapp/src/classes/service-worker-bridge/utils';

(function(app, $) {
	function RegisterPopup() {
		app.popups.Popup.call(this, 'RegisterPopup');
	}

	RegisterPopup.prototype = Object.create(app.popups.Popup.prototype);
	RegisterPopup.prototype.constructor = app.popups.Popup;

	RegisterPopup.prototype.open = function(params) {
		this.variant = (params && params.variant) || 'primary';
		this.markup = getMarkup();
		app.popups.Popup.prototype.open.call(this, params);
		app.popups.Popup.prototype.setHeightCSSVariable.call(this);

		const $formSubmitBtn = this.$markup.find('.js-account_registration-button_submit');
		const $form = $formSubmitBtn.closest('form');

		resetFormValidation($form);
		app.validator.init();
		reinitPhoneFieldValidation($form);

		$formSubmitBtn.on('click', (e) => onSubmitButtonClick(e));
		this.$markup.find('.js-popup-close-button').remove();

		app.progress.hide();
	};

	RegisterPopup.prototype.initMarkup = function() {
		app.progress.show(this.$markup);

		app.popups.Popup.prototype.initMarkup.call(this);
	};

	function getMarkup() {
		return app.util.renderTemplate($('#registerPopupTemplate').html());
	}

	/**
	 * Resets the form validation state.
	 * @param {jQuery} $form - The form to reset the validation state on.
	 */
	function resetFormValidation($form) {
		var $fieldsWithError = $form.find('.f-state-error');

		$form.validate().resetForm();
		$fieldsWithError.removeClass('f-state-error');
		$fieldsWithError.find('input').attr('aria-invalid', false);
	}

	/**
	 * Reinitializes the phone validation on the given form of popup.
	 * This is necessary because the phone validation refers to the existing field element in DOM
	 * on page loading and needs to be updated when the popup with new form appears.
	 *
	 * @param {jQuery} $form - The form to reinitialize the validation on.
	 */
	function reinitPhoneFieldValidation($form) {
		var validator = $form.validate();
		var phoneElem = $form.find('.phone').attr('name');
		var phoneCodeElement = $form.find('.phoneCode');

		validator.settings.rules[phoneElem] = {
			required: app.validator.isRequiredElement(phoneCodeElement)
		};
	}

	/**
	 * Handles form submission for register form.
	 *
	 * @param {Event} e - The form submission event.
	 */
	function onSubmitButtonClick(e) {
		e.preventDefault();
		const $form = $(e.target).closest('form');

		$form.validate();

		if ($form.valid()) {
			CSRF.populateFormByCSRFToken($form[0], true).then(() => {
				const formData = new FormData($form[0]);
				const layoutURL = $form[0].getAttribute('action');

				$.ajax({
					url: layoutURL,
					method: 'POST',
					data: formData,
					contentType: false,
					processData: false,
					success: function(response) {
						if (response.success) {
							ServiceWorkerUtils.emptyCache();

							window.location.reload();
						} else {
							const errorFieldName = response.htmlName;

							if (errorFieldName) {
								const $errorElement = $form.find(`error_${errorFieldName}`);

								if ($errorElement.length) {
									$errorElement.find('.f-error_text').html(response.error);
								}
							}
						}
					}
				});
			});
		}
	}

	app.popups.RegisterPopup = new RegisterPopup();
})((window.app = window.app || {}), jQuery);
