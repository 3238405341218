(function(app) {
	function PasswordResetMessage() {
		app.popups.Popup.call(this, 'PasswordResetMessage');
	}

	PasswordResetMessage.prototype = Object.create(app.popups.Popup.prototype);
	PasswordResetMessage.prototype.constructor = app.popups.Popup;

	PasswordResetMessage.prototype.open = function(params) {
		this.variant = (params && params.variant) || 'primary';
		this.isOpened = true;
		this.markup = getMarkup();
		this.messageData = params.data || null;
		app.popups.Popup.prototype.open.call(this, params);
		const messageWrapperElement = this.$markup.find('.js-popup-message')[0];

		messageWrapperElement.innerHTML = this.messageData;
		app.popups.Popup.prototype.setHeightCSSVariable.call(this);
		app.progress.hide();
		this.$markup.find('.js-popup-close-button').remove();
	};

	PasswordResetMessage.prototype.initMarkup = function() {
		app.progress.show(this.$markup);
		this.$markup.find('.js-password_reset_popup').on('click', (e) => openPasswordResetPopup(e, this.variant));
		app.popups.Popup.prototype.initMarkup.call(this);
	};

	function getMarkup() {
		return app.util.renderTemplate(document.getElementById('passwordResetMessagePopupTemplate').innerHTML);
	}

	function openPasswordResetPopup(e, variant) {
		e.preventDefault();
		const resetPopupURL = e.target.getAttribute('href');

		app.popupsMgr.closeAll();

		app.popupsMgr.open('PasswordReset', {
			templateId: 'passwordResetPopupTemplate',
			variant: variant,
			layoutURL: resetPopupURL
		});
	}

	app.popups.PasswordResetMessage = new PasswordResetMessage();
})((window.app = window.app || {}), jQuery);
