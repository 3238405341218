(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			window: $(window),
			document: $(document),
			body: $('body'),
			toggledClassName: 'blured-placeholder',
			headerSearchInput: $('.js-quicksearch'),
			searchOptionsSel : app.device.isMobileView() ? '.js-search_actions_top_bar' : '.js-search_result-options',
			colorsCarousel: '.js-colors-carousel-plp',
			productTileHoverSel: '.js-product-hover_box',
			scrollBars: $('.js-refinements'),
			scrollBarsSel: '.js-refinements',
			custTogglerSel : '.js-custom-toggler',
			notifyMeLinkSel : '.js-notifyme_link',
			mainSearchSel : '.js-main_search',
			filtersCloseButton : $('.js-min_refinement_selector').find('.js-filter-close_button'),
			changeViewtypeSwitcher : $('.js-change_viewtype-switcher'),
			productsCTAsel : '.js-producttile_link, .js-producttile_name',
			separatedFilters : $('.js-separated_filters'),
			refinementLayoutSel : '.js-refinement_flyout',
			filterCloseButtonClass : 'js-filter-close_button',
			refinementCheckbox: '.js-refinement_checkbox',
			activeRefinementSel: '.js-refinement-link-active',
			mobileSeparatedFiltersContainer : $('.js-mobile_separated_filters'),
			filterContainerSel : '.js-filter-tab_content',
			filterButtonSel : '.js_accordion_title',
			filtersOpenClass : 'm-separated_filters_opened',
			fixedPositionClass : 'h-fixed',
			activeTitlePrefix : '.js-active_title_prefix',
			hidden : 'h-hidden',
			owlCarouselSelector : '.js-owl_carousel',
			productTileSelector : '.js-product_tile',
			mobileHeader: $('.js-mobile-header_main'),
			header: $('.js-header_main'),
			filterToggled : $('.js-filter_toggled'),
			dropDownToggled : $('.js-filter_visibility_hidden'),
			hiddenVisibility: 'h-visibility-hidden',
			hToggledClass: 'h-toggled',
			productTilesImagesContainerSelector: '.js-image-container',
			productTilesImagesSelector: '.js-producttile_link',
			productTilesLazyloadSelector: '.js-lazyloadxt-sizer',
			productTileImageClass: 'js-producttile_image',
			infiniteScrollSelector: '.js-infinite_scroll-placeholder',
			infiniteScrollUnloadedSelector: '.js-infinite_scroll-placeholder[data-loading-state="unloaded"]',
			productTilesSelector: '.js-list_item_page',
			resultContentElement: $('.js-search_result-content'),
			shopthelookProdAnchorSel: '.js-shopthelook_productanchor',
			slotGridBottomSel: '.js-slot-grid_bottom',
			filterToggler: '.js-filter-toggler',
			filterFlyout: '.js-filter-flyout',
			clearFiltersBtn: '.js-clear_search_filters-link',
			searchActionsTopBar: '.js-search_actions_top_bar',
			filterSubmitBtn: '.js-filter_submit',
			refinementOpenSelector: '.js_accordion_description--open',
			classes: {
				showFilter: 'm-show',
				filterOpened: 'm-filters-opened',
			}
		};
	}

	function initializeScrollBar(){
		var rescrollBars = $($cache.scrollBarsSel);
		rescrollBars.find('.js-refinement>ul').scrollbar({
			ignoreMobile: false,
			disableBodyScroll: true
		});
	}

	function initCarousels() {
		$('.js-owl_carousel').each(function() {
			var carousel = $(this);

			if(!carousel.attr('data-owl-loaded')){
				app.owlcarousel.initCarousel(carousel);
				carousel.attr('data-owl-loaded', true);
			}
		});
	}

	function initializeDOM() {
		initializeScrollBar();
		initRefineBarClass();
		$($cache.searchOptionsSel).Stickyfill();
	}

	function initializeEvents() {
		$cache.document.on('mouseenter', $cache.productTileHoverSel, function(){
			$(this).addClass('m-show');
			if(!$(this).attr('data-owl-loaded')){
				app.owlcarousel.initCarousel($(this).find($cache.colorsCarousel));
				$(this).attr('data-owl-loaded', true);
			}
		}).on('mouseleave', $cache.productTileHoverSel, function(){
			$(this).removeClass('m-show');
		});

		$cache.document.on('click', $cache.filterToggler, function() {
			toggleFilterFlyout();
		});

		$cache.document.on('click', $cache.clearFiltersBtn, function() {
			const url = $(this).data('href');

			toggleFilterFlyout();
			app.search.updateProductListing(url);
		});

		$cache.document.on('refinements-loaded', function() {
			$($cache.searchActionsTopBar).tabs({ active: 0 });
		});

		$cache.document.on('click', $cache.filterSubmitBtn, function(e) {
			e.preventDefault();

			$($cache.searchActionsTopBar).tabs({ active: false });
		});

		$cache.document.on('mobile.filter.reset', function() {
			$($cache.searchActionsTopBar).tabs({ active: false });
		});

		$cache.document.on('change', $cache.refinementCheckbox, function() {
			const url = $(this).data('url');
			const openRefinements = [];
			const params = {
				isFilterOpened: true
			};

			if (app.device.isMobileView()) {
				$($cache.tabContent).addClass($cache.classes.loadingClass);
			} else {
				$($cache.filterFlyout).addClass($cache.classes.loadingClass);
			}

			for (const node of getOpenRefinements()) {
				openRefinements.push(node.dataset.id)
			}

			params.openRefinements = openRefinements.toString();

			app.search.updateProductListing(app.util.appendParamsToUrl(url, params));
		});

		$cache.document.on('refinements-update', function(e, extParams) {
			app.components.search.filters.init();
			initRefineBarClass();
			initializeScrollBar();
			initCarousels();
			showCetegoryRecommendation();

			if (extParams && extParams.tabClass) {
				$('.' + extParams.tabClass).siblings($cache.custTogglerSel).trigger('toggle');
			}

			$cache.document.trigger('click', $cache.filtersCloseButton);
		});

		$cache.headerSearchInput.on('focus blur', function() {
			var $this = $(this);
			$this.toggleClass($cache.toggledClassName, $this.val() === $this.attr("placeholder"));
		});

		$($cache.mainSearchSel).on("click", $cache.notifyMeLinkSel, app.product.openNotifyMePopup);

		$cache.document.on('changeVariation.changed', function (event, eventData) {
			var container = eventData.container;
			app.wishlist.updateWishlistButtons(container);
		});
	}


	/**
	 * Retrieves all open refinement elements.
	 * This function searches for refinement elements that are currently open within a specific container
	 * element identified by `$cache.scrollBarsSel`. If no such container is found, an empty array is returned.
	 * @returns {Node[]} An array of open refinement elements.
	 */
	const getOpenRefinements = () => {
		const refinementsNode = document.querySelector([$cache.scrollBarsSel, $cache.filterContainerSel].toString());

		if (!refinementsNode) {
			return [];
		}

		const openRefinementsNodes = refinementsNode.querySelectorAll($cache.refinementOpenSelector);

		return Array.from(openRefinementsNodes);
	}

	/** Toggling css classes for filter flyout visibility.  */
	function toggleFilterFlyout() {
		const filterWrapperBlock = $cache.document.find($cache.filterFlyout);
		const params = new URLSearchParams(window.location.search);

		if (params.has('isFilterOpened')) {
			params.delete('isFilterOpened');
		} else {
			params.append('isFilterOpened', 'true');
		}

		if (params.has('openRefinements')) {
			params.delete('openRefinements');
		}

		/**
		 * Close all refinements on close of filters layout
		 */
		for (const node of getOpenRefinements()) {
			const openClasses = (app.util?.getConfig('accordion.openClass')?.split(' ') ?? [])

			node.classList.remove(...openClasses)
		}

		window.history.replaceState(null, '', '?' + params);

		if (filterWrapperBlock.hasClass($cache.classes.showFilter)) {
			$cache.body.removeClass($cache.classes.filterOpened);
			filterWrapperBlock.removeClass($cache.classes.showFilter);
		} else {
			filterWrapperBlock.addClass($cache.classes.showFilter);
			$cache.body.addClass($cache.classes.filterOpened);
		}
	}

	function initChangeViewType() {
		var keepCookie = false;
		var productTilesImagesContainer = $($cache.productTilesImagesContainerSelector);
		var switches = $('.js-change_viewtype-switch');
		var categoryID = $cache.changeViewtypeSwitcher.data('category_id');
		var activeClass = 'm-active';
		var activeViewType = $('.js-change_viewtype-switch.m-active').data('viewtype');

		function switchImage(elements, viewtypeClass) {
			elements.each(function () {
				var element = $(this);

				if(element.hasClass(viewtypeClass)) {
					element.find("img").each(function() {
						var img = $(this);
						if(!img.prop("src") && img.data("viewtypesrc")) {
							img.prop("src", img.data("viewtypesrc"));
						}
					});
					element.addClass(activeClass);
				}
				else {
					element.removeClass(activeClass);
				}
				element.find("img").each(function() {
					var img = $(this);
					if(img.hasClass($cache.productTileImageClass)) {
						img.addClass($cache.hiddenVisibility);
						app.components.global.images.imageLoaded(img).then(function() {
							img.removeClass($cache.hiddenVisibility);
						});
					}
				});
			});
		}

		function updateViewType(activeViewType, categoryID) {
			var expiration = new Date();

			expiration.setTime(expiration.getTime() + 604800000 ); // one week
			$.cookie('changeViewtype-' + categoryID, activeViewType, { expires: expiration, path: '/' });
		}

		function initContainers(imageContainer) {
			imageContainer.each(function(){
				var newTile = $(this);
				var images = newTile.find($cache.productTilesImagesSelector);
				var viewtypeClass = 'js-' + activeViewType;
				switchImage(images, viewtypeClass);
			});
		}

		initContainers(productTilesImagesContainer);

		$cache.document.on('infiniteScroll.content.appended infiniteScroll.content.prepended', function(event, eventData) {
			var newTileImages = eventData.container.find($cache.productTilesImagesContainerSelector);
			initContainers(newTileImages);
			productTilesImagesContainer = productTilesImagesContainer.add(newTileImages);
		});

		if (!$.cookie('changeViewtype-' + categoryID)) {
			updateViewType(activeViewType, categoryID);
		}

		switches.click(function() {
			var $switch = $(this);

			if(!$switch.hasClass(activeClass)) {
				activeViewType = $switch.data('viewtype');
				initContainers(productTilesImagesContainer);
				updateViewType(activeViewType, categoryID);

				switches.each(function() {
					$(this).removeClass(activeClass);
				});

				$switch.addClass(activeClass);
				$cache.resultContentElement.data('viewtype', activeViewType);

				$cache.document.trigger('viewType.changed', {
					'viewType': activeViewType
				});
			}
		});

		$cache.body.on('click', $cache.productsCTAsel, function(){
			keepCookie = true;
		});

		$cache.window.bind('beforeunload', function() {
			if(!keepCookie) {
				$.removeCookie('changeViewtype-' + categoryID, {
					path: '/'
				});
			}

			return undefined;
		});
	}

	function initBackButton() {
		var header = app.isMobileView() ? $cache.mobileHeader : $cache.header;

		if (app.page.currentPage === 'search') {
			var data;
			try {
				data = JSON.parse($.cookie("previous_plp-data"));
			}
			catch (e) {

			}

			if (data !== undefined) {
				if (data.url !== window.location.href) {
					$.removeCookie('previous_plp-data', {
						path: '/'
					});
				} else {
					$cache.resultContentElement.css({
						'min-height': data.containerHeight
					});
					$cache.window.scrollTop($cache.resultContentElement.offset().top + data.relativeScrollPosition + header.outerHeight());

					$cache.window.load(function() {
						$cache.resultContentElement.css('');
					});
				}
			}

			$cache.body.on('click', $cache.productsCTAsel, function(){
				var productContainer = $(this).closest($cache.productTilesSelector);
				var data = {
					'url': window.location.href,
					'relativeScrollPosition': $cache.window.scrollTop() - productContainer.offset().top,
					'containerHeight': productContainer.outerHeight()
				};

				$.cookie("previous_plp-data", JSON.stringify(data), {
					path: '/'
				});
			});
		} else if (app.page.currentPage === 'landingpage') {
			$cache.body.on('click', $cache.shopthelookProdAnchorSel, function() {
				var data = {
					'url': window.location.href
				};

				$.cookie("previous_plp-data", JSON.stringify(data), {
					path: '/'
				});
			});
		}
		else if (app.page.type !== 'product') {
			$.removeCookie("previous_plp-data", {
				path: '/'
			});
		}
	}

	function initRefineBarClass(){
		var bar = $($cache.searchOptionsSel);
		bar.addClass('m-tabs-'+bar.find('li').length);
	}

	function showCetegoryRecommendation() {
		var slotGridBottom = $($cache.slotGridBottomSel);
		if (slotGridBottom.length) {
			slotGridBottom.removeClass($cache.hiddenVisibility);
		}
	}

	function initSeparatedFilters() {
		var container = $('.js-refinement_visibility');
		var filters = container.find($cache.custTogglerSel);

		filters.each(function () {
			var filter = $(this);
			var target = $(filter.data('slide'));
			var hasFlyout = target.find($cache.refinementLayoutSel).length > 0;
			var count = target.find($cache.activeRefinementSel).length;
			var carousel = target.find($cache.owlCarouselSelector);

			if(count > 0) {
				filter.append('(' + count + ')');
				filter.find($cache.activeTitlePrefix).removeClass($cache.hidden);
			}

			if(!target.hasClass(filter.data('toggle-class'))) {
				container.css('padding-bottom', target.outerHeight());

				if(carousel.length > 0) {
					carousel.one('owl.afterUpdate', function() {
						container.css('padding-bottom', target.outerHeight());
					});
				}
			}

			filter.on('click toggle', function () {
				if(filter.hasClass(filter.data('toggle-elem-class')) || filter.hasClass($cache.filterCloseButtonClass) || hasFlyout) {
					container.css('padding-bottom', 0);
				}
				else {
					container.css('padding-bottom', target.outerHeight());
				}
			});
		});

		$cache.window.load(function () {
			$cache.filterToggled.addClass($cache.hToggledClass);
			$cache.dropDownToggled.removeClass($cache.hiddenVisibility);

			if(!$cache.dropDownToggled.find($cache.refinementLayoutSel).length > 0) {
				container.css('padding-bottom', $cache.dropDownToggled.outerHeight());
			}
		});
	}

	function initMobileSeparatedFilters() {
		initCustomSortBy();

		var mobileSeparatedFiltersContainer = $('.js-mobile_separated_filters');
		var scrollableElement = mobileSeparatedFiltersContainer.find($cache.filterContainerSel);
		var accordionWrapper = $('.js_accordion_title-wrapper');
		var scrollPosition;
		var filters = mobileSeparatedFiltersContainer.find($cache.filterButtonSel);

		function closeFilter() {
			accordionWrapper.removeClass($cache.fixedPositionClass);
			mobileSeparatedFiltersContainer.removeClass($cache.fixedPositionClass);
			$cache.body.removeClass($cache.filtersOpenClass);
			mobileSeparatedFiltersContainer.scrollLeft(-scrollPosition);
		}
		filters.each(function () {
			var title = $(this);
			var filter = title.parent();
			var count = filter.find('.f-checkbox:checked').length;

			if(count > 0) {
				title.append(' (' + count + ') ');
			}

			title.on('accordion.opening', function() {
				scrollPosition = scrollableElement.offset().left;
				$cache.body.addClass($cache.filtersOpenClass);
				accordionWrapper.addClass($cache.fixedPositionClass);
				mobileSeparatedFiltersContainer.addClass($cache.fixedPositionClass);
			}).on('accordion.closed', closeFilter);
		});

		$cache.document.on('mobile.filter.reset mobile.filter.submit', closeFilter);
		function initCustomSortBy() {
			var toggler = $('.js-sortby_toggler');
			var target = $(toggler.data('target'));
			var toggleClass = toggler.data('toggle-class');
			var togglerClass = toggler.data('toggle-elem-class');

			toggler.click(function() {
				target.toggleClass(toggleClass);
				toggler.toggleClass(togglerClass);
				mobileSeparatedFiltersContainer.scrollLeft(mobileSeparatedFiltersContainer.outerWidth());
				$cache.body.toggleClass($cache.filtersOpenClass);
				mobileSeparatedFiltersContainer.toggleClass($cache.fixedPositionClass);
			})
		}
	}

	function fixSeparatedFilters() {
		$cache.document.on('refinements-before_update', function(event, eventData) {
			if(eventData.refinementClass.indexOf('b-refinement--') > 0){
				var refinementClass = eventData.refinementClass.split('b-refinement--')[1].split(' ')[0].replace(' ', '');
				$.cookie('current-refinement-applied', refinementClass, {
					path: '/'
				});
			}
		});

		$cache.document.on('refinements-update', function () {
			$.removeCookie("current-refinement-applied", {
				path: '/'
			});
		});
	}

	/**
	* @namespace app.search.custom public object
	**/
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.custom = {
		init : function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
			initBackButton();
			showCetegoryRecommendation();

			if($cache.changeViewtypeSwitcher.length > 0) {
				if(!app.isMobileView()) {
					initChangeViewType();
				}

				$cache.document.on('refinements-update', function () {
					initChangeViewType();
				});
			}

			if($cache.separatedFilters.length > 0) {
				initSeparatedFilters();
				fixSeparatedFilters();

				$cache.document.on('refinements-update', function () {
					initSeparatedFilters();
				})
			}

			if($cache.mobileSeparatedFiltersContainer.length > 0) {
				$cache.document.on('refinements-update', function () {
					initMobileSeparatedFilters();
				})
			}
		}
	};
})(window.app = window.app || {}, jQuery);