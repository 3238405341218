(function(app, $) {
	var $cache = {};
	var documentEventsInitialized = false;
	var mobileArrowVisible = true;
	var fancyboxSelector = '.fancybox-wrap';
	var notifymeContainerSelector = '.js-swatches_size';
	var notifymeLinkSelector = '.js-notifyme_link';
	var sizeNotifyMeSelector = '.js-size-nofity_me';
	var tooltipTargetSelector = '.js-tooltip-target';
	var selectedSizeItemSelector = '.js-swatches_size-item-selected';
	var $document = $(document);
	var $window = $(window);
	var body = $('body');
	var productDetailStickyInited = false;

	function initializeCache(container) {
		$cache = {
			container: container,
			document: $document,
			window: $window,
			productImagesCntr: container.find('.js-product_images_container'),
			productTabsMenu: $('.js-product_tabs-menu'),
			productDetail: $('.js-product_detail'),
			productDetailWrap: $('.js-product-details-wrapper'),
			productDetailWrapSelector: '.js-product-details-wrapper',
			thumbnailsCntrSel: '.js-thumbnails_slider',
			imagesContainerForZoom: $('.js-product_images_container-zoom'),
			thumbnailItemSel: '.js-thumbnail',
			thumbnailImgSel: '.js-thumbnail_img-item, .js-video_product_thumbnail',
			thumbnailImgCls: 'js-thumbnail_img-item',
			thumbnailVimeoCls: 'js-video_product_thumbnail',
			thumbnailsBlockClass: '.js-thumbnails_block',
			horCarouselSel: '.jcarousel-clip-horizontal',
			mainImageCntrSel: '.js-container_main_image',
			colorsCarousel: $('.js-colors-carousel-pdp'),
			carouselWrapperSel: '.js-owl_carousel',
			carouselImageSel: '.js-producttile_image',
			headerSel: '.js-header_main',
			header: $('.js-header_main'),
			pdpAssetSel: '.js-pdp_asset-container',
			thumbSelectedCls: 'b-product_thumbnail-selected',
			carouselItemSel: '.b-owl_carousel-item',
			lastVisitedSel: '.js-last_visited',
			jsHideClassName: 'h-hidden',
			productImagesCntrSel: '.js-product_images_container',
			colorsCarouselPDPSel: '.js-colors-carousel-pdp',
			productImagesContainer: $('.js-product_images_container'),
			printContainer: $('.js-swatches'),
			printLinkElements: '.js-swatches_color-link',
			fixedClass: 'h-fixed',
			pdpStickyNeedHelpButtonSel: '.js-need-help-pdp',
			pdpStickyNeedHelpButton: $('.js-need-help-pdp'),
			productPrimaryImage: $('.js-product_primary_image'),
			productPrimaryImageSelector: '.js-product_primary_image',
			firstImageSelector: '.js-primary_image img',
			productSetScrollArrowSelector: '.js-tab_product_set-scroll_arrow',
			productSetTabContainerSelector: '.js-tab_product_set-cont',
			productSetTabItemLinkSelector: '.js-tab_product_set',
			needHelpStaticClass: 'm-static',
			shippingReturnsLink: $('.js-shipping_returns-link_main'),
			productSubtitleSel: '.js-product_name-subtitle',
			tabletOrMobileDevice: app.device.isTabletUserAgent() || app.device.isMobileUserAgent(),
			containerStars: $('.js-star'),
			starItemFullClass: 'stars-icon-full',
			starItemEmptyClass: 'stars-icon-empty',
			starItemTemplate: $('<li>'),
			notifymeContainer: $('.js-swatches_size'),
			mobileThumbnailSlider: $('.js-mobile_thumbnails_slider'),
			variationItemColorSelector: '.js-variation-item--color',
			printSelectedClass: 'js-swatches_color-item-selected',
			swatchesColorItem: '.b-swatches_color-item',
			productContent: $('.js-product_content'),
			productTabSelector: '.js-tab_product_set-link',
			productTabs: '.js-product_tabs',
			productTabsContent: '.js-product_tabs-content',
			shippingTabSelector: 'tab4',
			vimeoPlayerClass: 'js-vimeo-player',
			vimeoPlayerSel: '.js-vimeo-player',
			origImg: '',
			roundButtonSel: '.js-round-button',
			isCarouselItemSliding: false,
			originalSrcSetDataAttr: 'originalsrcset',
			thumbSrcSet: '',
			thumbnailWasClicked: false,
			previousSlide: ''
		};
	}

	function switchThumbnail(index) {
		var $thumbImg = $($cache.thumbnailsCntrSel).find('[data-thumbnailindex=' + index + ']');

		if ($thumbImg.length) {
			$($cache.thumbnailsCntrSel)
				.find($cache.thumbnailItemSel)
				.removeClass($cache.thumbSelectedCls);
			$thumbImg.parent($cache.thumbnailItemSel).addClass($cache.thumbSelectedCls);
		}
	}

	function showSelectedPrint() {
		var $this = $(this);

		if (app.page.ns === 'product') {
			var perPage = JSON.stringify($this.data('settings').items);
			var prints = $this.find($($cache.carouselItemSel));
			var activePrint;

			prints.each(function() {
				var print = $(this);

				if (print.children($cache.swatchesColorItem).hasClass($cache.printSelectedClass)) {
					activePrint = print;

					return false;
				}
			});

			if (typeof activePrint === 'undefined') return;

			var activePrintIndex = prints.index(activePrint) + 1;
			var pageNumber = activePrintIndex - perPage;

			if (pageNumber > 0) {
				$this.trigger('to.owl.carousel', pageNumber);
			}
		}
	}

	function modifyZoomCntr(params) {
		const container = params.container;

		container.find($cache.mainImageCntrSel).html(params.target.parent().parent().attr('style', '').clone(true));

		return container;
	}

	function updateDetailHeight() {
		$cache.productDetail.height($($cache.productDetailWrap).outerHeight());
	}

	function initSticky(params) {
		var elementToFollow;
		var stickyElement;
		var breakpointTopVariation;
		var fixedTop;
		var fixedBottom;
		var notFixed;
		var breakpointTop;
		var breakpointBottom;
		var cssRight;
		var cssTop;
		var cssWidth;
		var cssHeight;
		var cssInitial = {};
		var cssFixedTop = {};
		var cssFixedBottom = {};
		var cssPlaceholder = {};
		var usePlaceholder;
		var placeholder = $('<div>');
		var reverse;
		var onScrollCondition;

		function initPlaceholder(css) {
			removePlaceholder();

			if (usePlaceholder) {
				placeholder.css(css).insertAfter(stickyElement);
			}
		}

		function removePlaceholder() {
			placeholder.remove();
		}

		function initValues(params) {
			elementToFollow = params.elementToFollow || $cache.window;
			stickyElement = params.stickyElement;
			breakpointTopVariation = params.breakpointTopVariation || 0;
			usePlaceholder = params.usePlaceholder || false;
			reverse = params.reverse || false;
			fixedTop = false;
			fixedBottom = false;
			notFixed = true;
			breakpointTop = stickyElement.offset().top - breakpointTopVariation;
			breakpointBottom = breakpointTop + elementToFollow.height() - stickyElement.outerHeight();
			cssRight = $cache.window.width() - (stickyElement.offset().left + stickyElement.outerWidth());
			cssTop = breakpointTopVariation;
			cssWidth = stickyElement.outerWidth();
			cssHeight = stickyElement.outerHeight();

			// workaround to set correct fixed position on device rotation
			if ($cache.window.scrollTop() > breakpointBottom && params.elementToFollow) {
				fixedTop = true;
				fixedBottom = false;
				notFixed = false;
			}

			cssInitial = {
				position: stickyElement.css('position'),
				top: stickyElement.css('top'),
				right: stickyElement.css('right'),
				bottom: stickyElement.css('bottom'),
				width: stickyElement.css('width'),
				transform: stickyElement.css('transform')
			};

			cssFixedTop = {
				position: 'fixed',
				top: cssTop,
				right: cssRight,
				bottom: 'auto',
				width: cssWidth,
				transform: 'none'
			};

			cssFixedBottom = {
				position: 'absolute',
				top: 'auto',
				right: 'auto',
				bottom: 0,
				width: cssWidth,
				transform: 'none'
			};

			cssPlaceholder = {
				height: cssHeight
			};
		}

		function onScroll() {
			if (notFixed) {
				onScrollCondition = $cache.window.scrollTop() >= breakpointTop;
				onScrollCondition = reverse ? !onScrollCondition : onScrollCondition;

				if (onScrollCondition) {
					stickyElement.css(cssFixedTop);
					initPlaceholder(cssPlaceholder);
					fixedTop = true;
					notFixed = false;
				}
			} else if (fixedTop && !fixedBottom && params.elementToFollow) {
				if ($cache.window.scrollTop() > breakpointBottom) {
					stickyElement.css(cssFixedBottom);
					initPlaceholder(cssPlaceholder);
					fixedBottom = true;
					fixedTop = false;
				}
			} else if (!fixedTop && fixedBottom) {
				onScrollCondition = $cache.window.scrollTop() < breakpointBottom;
				onScrollCondition = reverse ? !onScrollCondition : onScrollCondition;

				if (onScrollCondition) {
					stickyElement.css(cssFixedTop);
					initPlaceholder(cssPlaceholder);
					fixedTop = true;
					fixedBottom = false;
				}
			}
			if (!notFixed) {
				onScrollCondition = $cache.window.scrollTop() < breakpointTop;
				onScrollCondition = reverse ? !onScrollCondition : onScrollCondition;

				if (onScrollCondition) {
					removePlaceholder();
					stickyElement.css(cssInitial);
					notFixed = true;
					fixedTop = false;
					fixedBottom = false;
				}
			}
		}

		function unsetScroll() {
			$cache.window.off('scroll', onScroll);
		}

		function reInit(params) {
			stickyElement.removeAttr('style');
			initScroll(params);
		}

		function initScroll(params) {
			unsetScroll();
			initValues(params);

			var shouldAttachScroll = false;

			if (typeof elementToFollow.offset() !== 'undefined') {
				shouldAttachScroll = ((elementToFollow.offset().top + elementToFollow.outerHeight() - (stickyElement.offset().top + stickyElement.outerHeight()) + (params.usePlaceholder ? stickyElement.outerHeight() : 0)) <= 0);
			}

			if (params.elementToFollow !== undefined && shouldAttachScroll) {
				return false;
			}

			onScroll();
			$cache.window.on('scroll', onScroll);
		}

		initScroll(params);

		return {
			reInit: reInit
		};
	}

	function initializeDOM() {
		window.addEventListener('load', () => {
			app.owlcarousel.initCarousel($cache.colorsCarousel);
		});
	}

	function initializeEvents(params) {
		$cache.imagesContainerForZoom.on('click', $cache.carouselActiveItemSel, function(e) {
			var videoWasClicked = $(e.target).hasClass($cache.vimeoPlayerClass);

			if (!videoWasClicked) {
				$cache.imagesContainerForZoom.find($cache.thumbnailsBlockClass).removeClass($cache.jsHideClassName);
			}
		});

		if (!app.isMobileView()) {
			$cache.shippingReturnsLink.on('click', function(e) {
				e.preventDefault();
				$($cache.productTabs).tabs({
					active: getShippingTabIndex()
				});
				$('html,body').animate({
					scrollTop: ($('#' + $cache.shippingTabSelector).parent().offset().top / 2) + $('#' + $cache.shippingTabSelector).outerHeight()
				}, 1000);
			});
		}

		// Init sticky product detail section after product carousel is inited;
		$cache.productPrimaryImage.find($cache.carouselWrapperSel).on('owl.afterUpdate', function() {
			var firstImage = $(this).find($cache.firstImageSelector).first();

			$cache.firstImage = firstImage;

			if (firstImage.css('opacity') === 1) {
				if (!productDetailStickyInited) {
					initStickyEvents(params);
					productDetailStickyInited = true;
				}
			} else {
				firstImage.on('load transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', function() {
					if (!productDetailStickyInited) {
						initStickyEvents(params);
						productDetailStickyInited = true;
					}
				});
			}
		});

		if (app.isMobileView()) {
			var printSliderElement = $cache.productDetail.find($cache.variationItemColorSelector);

			if (printSliderElement.length > 0) {
				initStickyMobileEvents(printSliderElement);
			}
		}

		// Switches thumbnail when slide in product carousel is changed
		$cache.productImagesCntr.on('translated.owl.carousel', $cache.carouselWrapperSel, function() {
			var $this = $(this);
			var activeElement = $this.find($cache.carouselActiveItemSel);
			var index = activeElement.find($cache.carouselImageSel).data('imageindex');

			$this.find($cache.carouselItemSel).removeClass('active');
			activeElement.addClass('active');
			switchThumbnail(index);
		});

		// Navigates carousel by clicking on thumbnail
		$cache.productImagesCntr.on('click', $cache.thumbnailImgSel, function() {
			$cache.isCarouselItemSliding = true;
			$cache.thumbnailWasClicked = true;
			$cache.previousSlide = $($cache.firstImageSelector);
			$($cache.firstImageSelector).attr('src', $cache.origImg);
			$($cache.roundButtonSel).remove();
			$($cache.productImagesCntrSel)
				.find($cache.carouselWrapperSel)
				.trigger('to.owl.carousel', [$(this).data('positionindex'), 150]);
		});

		$($cache.carouselWrapperSel).on('translated.owl.carousel', function() {
			$cache.origImg = $($cache.firstImageSelector).attr('src');
			$cache.isCarouselItemSliding = false;
		});

		// Update height of product details section when product tabs are clicked
		$cache.productTabsMenu.on('click', 'li', function() {
			updateDetailHeight();
		});

		// Switches main image when hover on product thumbnail
		$($cache.thumbnailImgSel).hover(function() {
			if (!$cache.isCarouselItemSliding) {
				var thumbSel = $(this);
				var thumbImg;
				var thumbSrcSet;

				$cache.origImg = $($cache.firstImageSelector).attr('src');
				$cache.origSrcSet = $($cache.firstImageSelector).parent('picture').data($cache.originalSrcSetDataAttr);
				$($cache.roundButtonSel).remove();

				if (thumbSel.hasClass($cache.thumbnailVimeoCls)) {
					thumbImg = thumbSel.attr('src');
					thumbSrcSet = thumbImg + ' 1x, ' + thumbImg + ' 2x';
					$($cache.carouselActiveItemSel).append('<div class="js-round-button round-button"></div>');
				} else if (thumbSel.hasClass($cache.thumbnailImgCls)) {
					$($cache.roundButtonSel).remove();
					$($cache.vimeoPlayerSel).addClass($cache.jsHideClassName);
					thumbImg = thumbSel.data('lgimg').url;
					thumbSrcSet = thumbSel.attr('data-srcset');
				}

				$cache.thumbSrcSet = thumbSrcSet;
				var firstImageElem = $($cache.firstImageSelector);

				firstImageElem.attr('src', thumbImg);
				var pictureSib = firstImageElem.siblings('source');

				if (thumbSrcSet && pictureSib.length) {
					pictureSib.attr('srcset', thumbSrcSet);
				}
			}
		}, function() {
			if (!$cache.isCarouselItemSliding) {
				$($cache.roundButtonSel).remove();
				$($cache.vimeoPlayerSel).removeClass($cache.jsHideClassName);
				$($cache.firstImageSelector).attr('src', $cache.origImg);

				if ($cache.thumbnailWasClicked) {
					$($cache.firstImageSelector).siblings('source').attr('srcset', $cache.thumbSrcSet);
					$cache.previousSlide.siblings('source').attr('srcset', $cache.origSrcSet);
				} else {
					$($cache.firstImageSelector).siblings('source').attr('srcset', $cache.origSrcSet);
				}
			}

			$cache.thumbnailWasClicked = false;
		});

		$cache.document.on('recommendations.loaded', function(event, eventData) {
			var notifyMeContainer = $(eventData).find(notifymeContainerSelector);

			notifyMeContainer.each(function() {
				initTooltip($(this));
			});
		});

		$cache.productContent.find([$cache.printLinkElements, $cache.productTabSelector].join(', ')).each(function() {
			var element = $(this);

			element.click(function() {
				var url = element.attr('href');

				window.history.replaceState({}, '', url);
			});
		});
	}

	function initToolTipEvents() {
		$document.on('dialog.showed', function() {
			var notifyMeContainer = body.children(fancyboxSelector).find(notifymeContainerSelector);

			notifyMeContainer.each(function() {
				initTooltip($(this));
			});
		});
	}

	function initStickyEvents(params) {
		var productDetailWrap = $($cache.productDetailWrapSelector);

		if ((params.sticky !== undefined && params.sticky.enabled) || ($cache.firstImage.length > 0 && params.container.length > 0)) {
			var productDetailWrapSticky = initSticky({
				elementToFollow: $cache.firstImage,
				stickyElement: productDetailWrap,
				breakpointTopVariation: $cache.header.outerHeight(),
				usePlaceholder: true
			});

			$cache.window.on('resize', function() {
				productDetailWrapStickyReInit(productDetailWrapSticky);
			});

			$cache.header.on('onscroll.updated', function() {
				productDetailWrapStickyReInit(productDetailWrapSticky);
			});

			$cache.productPrimaryImage.on('owl.afterUpdate', $cache.carouselWrapperSel, function() {
				productDetailWrapStickyReInit(productDetailWrapSticky);
			});
		}

		if ($cache.pdpStickyNeedHelpButton.length > 0) {
			if ($cache.tabletOrMobileDevice) {
				$cache.pdpStickyNeedHelpButton.one('click', function() {
					event.preventDefault();
				});
			}

			if ($cache.productDetailWrap.outerHeight() + $cache.pdpStickyNeedHelpButton.outerHeight() + parseInt($cache.pdpStickyNeedHelpButton.css('bottom')) >= $cache.firstImage.outerHeight()) {
				$cache.pdpStickyNeedHelpButton.addClass($cache.needHelpStaticClass);
			}

			var pdpStickyNeedHelpButtonSticky = initSticky({
				stickyElement: $cache.pdpStickyNeedHelpButton,
				breakpointTopVariation: $cache.window.height() - $cache.pdpStickyNeedHelpButton.outerHeight() - parseInt($cache.pdpStickyNeedHelpButton.css('bottom'))
			});

			$cache.header.on('onscroll.updated', function() {
				pdpStickyNeedHelpButtonStickyReInit(pdpStickyNeedHelpButtonSticky);
			});

			$cache.productPrimaryImage.on('owl.afterUpdate', $cache.carouselWrapperSel, function() {
				pdpStickyNeedHelpButtonStickyReInit(pdpStickyNeedHelpButtonSticky);
			});

			$cache.window.on('resize', function() {
				pdpStickyNeedHelpButtonStickyReInit(pdpStickyNeedHelpButtonSticky);
			});
		}
	}

	function initStickyMobileEvents(printSliderElement) {
		printSliderElement = printSliderElement || $cache.productDetail.find($cache.variationItemColorSelector);
		var printSliderSticky;
		var printSliderStickyInited = false;
		var eventListeners = {
			'body-padding-updated': false,
			owl: false
		};

		function waitForEvents(event) {
			eventListeners[event.type] = true;
			var ready = true;

			$.each(eventListeners, function(eventType, eventValue) {
				if (!eventValue) {
					ready = false;

					return false;
				}
			});

			return ready;
		}

		function initPrintSliderSticky() {
			if (printSliderStickyInited) {
				printSliderSticky.reInit({
					stickyElement: printSliderElement,
					breakpointTopVariation: $cache.window.height() - printSliderElement.outerHeight(),
					usePlaceholder: true,
					reverse: true
				});
			} else {
				printSliderSticky = initSticky({
					stickyElement: printSliderElement,
					breakpointTopVariation: $cache.window.height() - printSliderElement.outerHeight(),
					usePlaceholder: true,
					reverse: true
				});
				printSliderStickyInited = true;
			}
		}

		$cache.document.one('body-padding-updated', function(event) {
			if (waitForEvents(event)) {
				initPrintSliderSticky();
			}
		});

		$cache.mobileThumbnailSlider.one('owl.afterUpdate', function(event) {
			if (waitForEvents(event)) {
				initPrintSliderSticky();
			}
		});

		$cache.window.on('resize', function() {
			initPrintSliderSticky();
			eventListeners = {
				'body-padding-updated': false,
				owl: false
			};

			$cache.document.one('body-padding-updated', function(event) {
				if (waitForEvents(event)) {
					initPrintSliderSticky();
				}
			});

			$cache.mobileThumbnailSlider.one('owl.afterUpdate', function(event) {
				if (waitForEvents(event)) {
					initPrintSliderSticky();
				}
			});
		});
	}

	function productDetailWrapStickyReInit(stickyObject) {
		stickyObject.reInit({
			elementToFollow: $cache.firstImage,
			stickyElement: $cache.productDetailWrap,
			breakpointTopVariation: $cache.header.outerHeight(),
			usePlaceholder: true
		});
	}

	function pdpStickyNeedHelpButtonStickyReInit(stickyObject) {
		$cache.pdpStickyNeedHelpButton.removeAttr('style');

		if ($cache.productDetailWrap.outerHeight() + $cache.pdpStickyNeedHelpButton.outerHeight() + parseInt($cache.pdpStickyNeedHelpButton.css('bottom')) >= $cache.firstImage.outerHeight()) {
			$cache.pdpStickyNeedHelpButton.addClass($cache.needHelpStaticClass);
		} else {
			$cache.pdpStickyNeedHelpButton.removeClass($cache.needHelpStaticClass);
		}

		stickyObject.reInit({
			stickyElement: $cache.pdpStickyNeedHelpButton,
			breakpointTopVariation: $cache.window.height() - $cache.pdpStickyNeedHelpButton.outerHeight() - parseInt($cache.pdpStickyNeedHelpButton.css('bottom'))
		});
	}

	function initializeDocumentEvents() {
		$cache.document.on('product.variation.reloaded', function() {
			$cache.colorsCarousel = $($cache.colorsCarouselPDPSel);
			app.owlcarousel.initCarousel($cache.colorsCarousel);

			$cache.productDetailWrap = $($cache.productDetailWrapSelector);
			$cache.pdpStickyNeedHelpButton = $($cache.pdpStickyNeedHelpButtonSel);
			$cache.firstImage = $cache.productPrimaryImage
				.find($cache.carouselWrapperSel)
				.find($cache.firstImageSelector)
				.first();
		});

		$cache.document.on('fancybox.closed', function() {
			$cache.imagesContainerForZoom.find($cache.thumbnailsBlockClass).addClass($cache.jsHideClassName);
		});

		// init carousel with recommendations
		$cache.document.on('carousel.init', function(e, data) {
			if (data.container) {
				app.owlcarousel.initCarousel($(data.container).find($cache.horCarouselSel));
			}
		});
	}

	function fixForChangeViewType() {
		var categoryID = app.page.params.cgid;
		var keepCookie = false;

		$cache.printContainer.on('click', $cache.printLinkElements, function() {
			keepCookie = true;
		});

		$cache.window.on('backbutton.clicked', function() {
			keepCookie = true;
		});

		$cache.window.unload(function() {
			if (!keepCookie) {
				$.removeCookie('changeViewtype-' + categoryID, {
					path: '/'
				});
			}
		});
	}

	function initProductTabsScroll() {
		var productSetTabContainer = $($cache.productSetTabContainerSelector);

		if (productSetTabContainer.length === 0) {
			return false;
		}

		$cache.document.off('changeVariation.changed', initElements);
		$cache.document.off('changeVariation.changed', checkContainer);

		var productSetScrollArrow = $($cache.productSetScrollArrowSelector);
		var productSetTabLastItem = productSetTabContainer.find($cache.productSetTabItemLinkSelector).last().parent();

		if (productSetTabLastItem.length === 0) {
			return false;
		}

		var productSetTabLastItemOffsetLeft = productSetTabLastItem.offset().left + productSetTabLastItem.outerWidth() - productSetTabContainer.width();

		function initElements() {
			productSetTabContainer = $($cache.productSetTabContainerSelector);
			productSetScrollArrow = $($cache.productSetScrollArrowSelector);
			productSetTabLastItem = productSetTabContainer.find($cache.productSetTabItemLinkSelector).last().parent();
		}

		function turnArrowOff() {
			productSetScrollArrow.hide();
			mobileArrowVisible = false;
			productSetTabContainer.off('scroll', turnArrowOff);
			$cache.window.off('resize', checkContainer);
			$cache.document.off('changeVariation.changed', checkContainer);
		}

		function checkContainer() {
			productSetTabLastItemOffsetLeft = productSetTabLastItem.offset().left + productSetTabLastItem.outerWidth() - productSetTabContainer.width();

			if (productSetTabLastItemOffsetLeft <= 0) {
				if (mobileArrowVisible) {
					productSetScrollArrow.hide();
				} else {
					turnArrowOff();
				}
			} else if (!mobileArrowVisible) {
				turnArrowOff();
			} else {
				productSetScrollArrow.show();
			}
		}

		productSetTabContainer.on('scroll', turnArrowOff);
		$cache.window.on('resize load', checkContainer);

		productSetScrollArrow.one('click', function() {
			productSetTabContainer.animate({
				scrollLeft: productSetTabLastItemOffsetLeft
			}, 500);
		});

		$cache.document.on('changeVariation.changed', initElements);
		$cache.document.on('changeVariation.changed', checkContainer);
	}

	function initChangeSubtitle() {
		var $mainProductSubtitle = $cache.productDetail.find($cache.productSubtitleSel);

		if ($mainProductSubtitle.length === 0) {
			return false;
		}

		var prints = $($cache.printLinkElements);
		var originalSubtitle = $mainProductSubtitle.text();

		prints.each(function() {
			var print = $(this);
			var subtitle = $(print.data('subtitle')).text();

			print.mouseenter(function() {
				$mainProductSubtitle.text(subtitle);
			});

			print.mouseleave(function() {
				$mainProductSubtitle.text(originalSubtitle);
			});
		});
	}

	function counterStarsReview() {
		$cache.containerStars.each(function() {
			var counterStarFull = 0;
			var starContainer = $(this);
			var starsNumber = starContainer.data('stars-number');
			var starsFull = starContainer.data('star-full');

			for (var counter = 0; counter < starsNumber; counter++) {
				var starItem = $cache.starItemTemplate.clone();

				starContainer.append(starItem);

				if (counterStarFull < starsFull) {
					starItem.addClass($cache.starItemFullClass);

					counterStarFull++;
				} else {
					starItem.addClass($cache.starItemEmptyClass);
				}
			}
		});
	}

	function initTooltip(notifyMeContainer) {
		var notifyMeLinks = notifyMeContainer.find(notifymeLinkSelector);
		var selectedNotifyMeLinks = notifyMeContainer.find(selectedSizeItemSelector).find(notifymeLinkSelector);
		var tooltipContainerParent = notifyMeContainer.parent();
		var tooltipContainer = tooltipContainerParent.find(tooltipTargetSelector);
		var sizeNotifyMeContainer = tooltipContainerParent.find(sizeNotifyMeSelector);
		var tooltipOn = false;

		function showTooltip(element) {
			var top = element.offset().top + element.outerHeight();
			var left = element.offset().left + (element.outerWidth() / 2);

			tooltipContainer.css({
				position: 'absolute',
				display: 'block',
				top: top,
				left: left
			});
			body.append(tooltipContainer);
			tooltipOn = true;
		}

		function hideTooltip() {
			tooltipContainer.remove();
			tooltipOn = false;
		}

		if (selectedNotifyMeLinks.length > 0) {
			sizeNotifyMeContainer.show();
		}

		if (!app.isMobileView()) {
			notifyMeLinks.each(function() {
				var container = $(this).parent();

				container.on('mouseenter', function() {
					showTooltip(container);
				}).on('mouseleave click', function() {
					hideTooltip();
				});
			});

			$window.scroll(function() {
				if (tooltipOn) {
					hideTooltip();
				}
			});
		}
	}

	function getShippingTabIndex() {
		var tabs = $($cache.productTabs).find($cache.productTabsContent);

		for (var i = 0; i < tabs.length; i++) {
			if ($(tabs[i]).attr('id') === $cache.shippingTabSelector) {
				return i;
			}
		}
	}

	/**
	 * @namespace app.product.custom public object
	 * */
	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.custom = {
		init: function(params) {
			app.product.modifyZoomCntr = modifyZoomCntr;
			var container = 'container' in params && params.container instanceof $ ? params.container.find('.js-pdp_primary_content') : $('.js-pdp_primary_content');

			if (!container || container.hasClass('js-product-custom-initialized')) {
				return;
			}

			initializeCache(container);
			initializeEvents(params);
			initializeDOM();
			container.addClass('js-product-custom-initialized');
			fixForChangeViewType();
			counterStarsReview();

			if (app.isMobileView()) {
				initProductTabsScroll();
				app.dynamicpromobanner.init();
			}

			if (!documentEventsInitialized) {
				initializeDocumentEvents();
				documentEventsInitialized = true;
			}

			$cache.notifymeContainer.each(function() {
				initTooltip($(this));
			});

			if (!app.isMobileView()) {
				initChangeSubtitle();
				$cache.printContainer.on('owl.afterUpdate', showSelectedPrint);
			}
		},
		initToolTipEvents: initToolTipEvents
	};
})((window.app = window.app || {}), jQuery);
