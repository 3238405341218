/**
 * Components specific config
 **/
(function (app, $) {

	var componentsConfig = {
		global: {
			components: {
				'global.fancybox': {
					padding: 0
				},
				'header.custom' : {},
				'global.header': {
					disablePaddingManagement : true,
					scrollConfig : {
						'cssClass' : 'b-white_header slim-header',
						'breakPoint' : 0
					},
					miniCartEmpty : "b-mini_cart-empty",
					miniCartEmptySel : ".js-mini_cart-title"
				},
				'checkout.custom' : {},
				'global.toggler': {enabled: false},
				'global.searchplaceholder': {enabled: false},
				'simplesearch.gender': {},
				'toggler.custom': {
					'headerTopSel': !app.preferences.isMobileView ? '.js-header_main-top' : '.js-header_service_menu',
					excludeFromHeaderHeight : {
						'pages' : [
							'homepage',
							'landingpage'
						],
						'breadcrumbs' : true,
						'elementSel' : !app.preferences.isMobileView ? '.js-header_main-top' : '.js-header_service_menu'
					},
					isSlimHeaderOnScroll: true,
					updateOtherElementsSel: '.b-menu_category-level_1_list.js-menu_category-list.js-menu_branch'
				},
				'quickview.custom': {},
				'global.scrollToTop': {},
				'global.newsletter': {
					closeOpacity: false,
					closeEffect: 'fade',
					closeSpeed: 2000,
					closeEasing: 'swing',
					closeMethod: 'zoomOut'
				},
				'global.accordion': {},
				'global.quickview': {quickViewBlockSel: '.js-quickview, .js-hp_quickview'},
				'newsletter.handlepopup': {closeTimeout: 2000},
				'newsletter.custom': {},
				'countryselector.custom': {
					searchEnabled: false,
					elementListCustomClass: 'countrySelectorItems'
				},
				'localeselector.custom': {
					searchEnabled: false,
					elementListCustomClass: 'countrySelectorItems'
				},
				'categoryflyout.custom': {
					openDelay: 100,
					closeDelay: 300,
					getOpenByDefault: true,
					depthRhestriction: 2,
					depthRhestrictionTablet: 3,
					activeLevelClass: ['b-menu_category-item--hovered', 'b-menu_category-level_2-item--hovered']
				},
				'wishlist.custom': {},
				'account.addresses': {width: '50%', height: 'auto', autoSize: false},
				'account.paymentinstruments': {width: '50%', height: 'auto', autoSize: false},
				'global.simplesearch': {
					simpleSearchHandler: app.simpleSearchFunctions && app.simpleSearchFunctions.simpleSearchHandler,
					successSimpleSearchCallback:
					app.simpleSearchFunctions && app.simpleSearchFunctions.successSimpleSearchCallback
				},

				'search.custom': {},
				'global.clusterlazyload' : {},
				'global.firstvisitbanner': { updateHeader: false, hideClass: 'js-hidden-animated' },
				'global.categoriesNavigation' : {

					openPreselectedMenu: true,
					disableToggleOnSwipe: true
				},
				'global.shopthelook' : {
					"calcHeightElement": app.preferences.isMobileView,
					"showMobileTitle": true,
					"fancyboxSettings": {
						closeOpacity: false,
						closeEffect: 'fade',
						closeSpeed: 600,
						closeEasing: 'swing',
						closeMethod: 'zoomOut',
						helpers: {
							title : {
								type : 'inside',
								position: 'top'
							},
							overlay : {
								locked : true
							}
						}
					}
				},
				'global.countrydetectionblock' : {}
			}
		},
		search: {
			components: {
				"searchfilters.custom": {},
				"search.custom": {},
				"search.mobile": {
					'updateOnSelect': false
				},
				'search.filters': { loaderClass: 'refinement-loader' },
				'product.video': {
					mobileEnabled: true
				},
				'video.custom': {}
			}
		},
		product: {
			components: {
				'toggler.custom': {
					includeToBodyPadding: {
						elementSel: app.preferences.isMobileView ? '.js-header_main' : null
					}
				},
				'social.custom': {},
				'product.video': {
					mobileEnabled: true
				},
				'video.custom': {},
				'product.custom': {
					sticky: {
						enabled: true
					}
				},
				'recomtabs.custom': {}
			}
		},
		checkout: {
			components: {
				'simplesearch.gender': {
					enabled: false
				},
				'product.video': {
					mobileEnabled: true
				},
				'video.custom': {}
			}
		},
		storefront: {
			components: {
				'storefront.custom': {},
				'loadmore.custom': {},
				'global.videoMgr': { elementSelector: '.js-videomgr' }
			},
			pages: {
				homepage: {
					components: {
						'homepage.custom': {},
						'global.transparentheader': {},
						'product.video': {
							mobileEnabled: true
						},
						'video.custom': {}
					}
				}
			}
		},
		contentclusters: {
			components: {
				'cluster.custom': {}
			}
		},
		orderhistory: {
			components: {
				'account.navigation': {}
			}
		}
	};

	/*************** publish app.components.config.specific***************/
	app.componentsconfig = app.componentsconfig || {};
	app.componentsconfig.specific = componentsConfig;
})((window.app = window.app || {}), jQuery);