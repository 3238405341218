(function(app, $) {
	function Newsletter() {
		const type = 'newsletter';

		app.banners.Banner.call(this, type, app.util.getConfig(`banners.${type}`));
		countPageOpened();
	}

	Newsletter.prototype = Object.create(app.banners.Banner.prototype);
	Newsletter.prototype.constructor = app.banners.Banner;

	Newsletter.prototype.isShouldBeOpened = function() {
		if (this.contentIsNotConfigured || this.isOpened) {
			return false;
		}

		if (localStorage.getItem('newsletterBannerWasClosed') === 'true') {
			return false;
		} else {
			return isUserOpenedEnoughPagesToShowBanner();
		}
	};

	Newsletter.prototype.open = function() {
		this.markup = getMarkup();

		setTimeout(function() {
			app.banners.Banner.prototype.open.call(this);
		}.bind(this), app.preferences.openingTimeForNewsletterBanner);

		initEvents.bind(this);
	};

	Newsletter.prototype.close = function() {
		app.banners.Banner.prototype.close.call(this);
		localStorage.setItem('newsletterBannerWasClosed', true);
		app.bannersMgr.checkAndOpenBanner();
	};

	const countPageOpened = () => {
		localStorage.setItem('newsletterBannerPagesOpenedTimes', +localStorage.getItem('newsletterBannerPagesOpenedTimes') + 1);
	};

	const isUserOpenedEnoughPagesToShowBanner = () => {
		return +localStorage.getItem('newsletterBannerPagesOpenedTimes') >= app.preferences.minClicksToShowNewsletterPopup;
	};

	const getMarkup = () => {
		const template = document.getElementById('newsletterBannerTemplate');

		return template && template.innerHTML;
	};

	function initEvents() {
		if (this.isInitialized) {
			return;
		}

		const eventsToInit = app.util.getConfig('banners.newsletter.eventsToInit');

		for (const eventsNames of eventsToInit) {
			const component = app.util.getAppComponentByPath(eventsNames);

			if (component) {
				component.init();
			}
		}

		this.isInitialized = true;
	}

	app.banners.newsletter = new Newsletter();
})((window.app = window.app || {}), jQuery);
