(function(app, $) {
	var $cache = {};
	var $settings = {};

	function initParams(params) {
		$settings = params;
	}

	function initializeCache() {
		$cache = {
			localeSelector: $('.js-locale_selector').find('select')
		};
	}

	function initializeDOM() {
		if ($cache.localeSelector.length > 0 && !app.device.isMobileView()) {
			$cache.localeSelector.select2({
				minimumResultsForSearch: $settings.searchEnabled ? 0 : -1,
				dropdownCssClass: $settings.elementListCustomClass ? $settings.elementListCustomClass : ''
			});
		}
	}

	function initializeEvents() {
		$cache.localeSelector.change(function(event) {
			if (event.target.selectedOptions.length) {
				let url = event.target.selectedOptions[0].dataset.url;

				if (app.page.type.item === 'orders.orderhistory') {
					url = app.util.removeParamFromURL(event.target.selectedOptions[0].dataset.url, 'dwcont');
				}

				window.location.href = url;
			}
		});
	}

	/*
	* @namespace app.components.global.localselector public object
	*/
	app.components = app.components || {};
	app.components.localeselector = app.components.localeselector || {};
	app.components.localeselector.custom = {
		// initializations
		init: function(params) {
			initializeCache();
			initParams(params);
			initializeDOM();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
