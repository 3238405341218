(function (app, $) {
	var $cache = {},
		configs = {
			newsletterTop: {
				"0": 96,
				"90": 96
			}
		},
		focusTimeout,
		orientationChangeTimeout;

	function initializeCache() {
		$cache = {
			document : $(document),
			window: $(window),
			html: $('html'),
			body : $('body'),
			link: $('a[href]'),
			headerContainer: $('.js-header_container'),
			genderCategory: '.js-gender_category',
			genderPlaceholder: '.js-gender-placeholder input',
			typeBtnsSelector: '.js-gender_category input[type="radio"]',
			typeBtnsSelectorChecked : '.js-gender_category input[type="radio"]:checked',
			newsletterPopupSel1: '.fancybox-wrap.l-newsletter_popup',
			newsletterPopupSel2: '.fancybox-wrap.l-newsletter_popup_desktop',
			newsletterFirstStepClass: 'first-step',
			newsletterMailFieldSel: '.js-newsletterpopup-mail-field',
			stickerContainer: $('.js-lips_sticker-container'),
			stickerAnchor: $('.js-lips_sticker-anchor'),
			stickerHide: $('.js-lips_sticker-hide'),
			footerNewsletter: $('.js-footer_newsletter'),
			cookieBar: $('.js-first-visit-banner'),
			fieldEmail: $('.js-action_simple .f-field-email'),
			fieldValid : 'f-state-valid',
			newsletterpopupWrapperSel: '.js-newsletter_popup-wrapper',
			newsletterpopupOverlayClass: 'b-newsletter_popup-overlay',
			bodyClassOnNewsletterPopupOpened: 'm-newsletter-popup-opened',
			bodyClassOnConfirmationNewsletterPopupOpened: 'm-newsletter-confirmation-popup-opened',
			bodyClassOnSimpleConfirmationNewsletterPopupOpened: 'm-newsletter-simple-confirmation-popup-opened',
			openedMenuBodyClass: "m-menu_opened",
			closingNewsletterPopupElementsSel: '.js-header_container .js-toggler',
			headerMenuToggleSel: '.js-header_menu_toggle',
			fancyboxOverlay: '.fancybox-overlay',
			fancyboxOverlayFixedSel: '.fancybox-overlay-fixed',
			overflowHiddenMobileClass: 'html_fancybox_opened body',
			overflowHiddenClass: 'h-overflow-hidden',
			firstVisitBannerSel: '.js-first-visit-banner',
			hiddenClass: 'h-hidden',
			firstVisitBannerAnimatedClass: 'm-first-visit-banner-animated',
			countryDetectionBlock: '.js-country-detection-block',
			bottomBannerClasses: 'b-bottom-banner js-bottom-banner',
			bottomBannerClassSel: '.js-bottom-banner',
			bottomBannerOpen: 'open',
			cookieCloseBtn: '.js-cookies_informer-close_button',
			dynamicPromoBannerSel: '.js-dynamic-promo-banner_block',
			dynamicPromoBanner: '.js-dynamic-promo-banner',
			dynamicPromoBannerVisibleClass: 'b-dynamic-promo-banner_block--visible',
			backToTopButton: '.js-scroll_to_top_mobile',
			shopthelookContentSel: '.js-shopthelook_content',
			countryDetectionBlockSel: '.js-country-detection-block'
		};
	}

	function initializeDOM() {
		$($cache.typeBtnsSelectorChecked).each(function(){
			setDefaultGenderType.call(this);
		});
	}

	function showBottomBanner() {
		var bottomBanner = $('<div>').addClass($cache.bottomBannerClasses);

		bottomBanner.appendTo($cache.body);

		if ($($cache.newsletterpopupWrapperSel).length) {
			$($cache.newsletterpopupWrapperSel).appendTo($cache.bottomBannerClassSel);
			$($cache.fancyboxOverlay).appendTo($cache.bottomBannerClassSel);
		} else if ($($cache.countryDetectionBlock).length) {
			$($cache.countryDetectionBlock).appendTo($cache.bottomBannerClassSel);
		}

		if ($($cache.firstVisitBannerSel).length) {
			$($cache.firstVisitBannerSel).appendTo($cache.bottomBannerClassSel);
		}

		setTimeout(function() {
			$($cache.bottomBannerClassSel).addClass($cache.bottomBannerOpen);
		}, 1000);

		handleBottomBannerEvents();
	}

	function getAnimationSpeed(animationBlock, animationBlockDuration) {
		var duration = animationBlockDuration || parseInt(animationBlock.css('transition-duration')) * 1000;

		return +(animationBlock.height() / duration).toFixed(2);
	}

	function backToTopButtonPosition(slideDownDistance, animationDelay) {
		var animationSlideDown = Number.isInteger(slideDownDistance) && slideDownDistance > 0;

		if (app.device.currentDevice() !== 'mobile' || (slideDownDistance && !animationSlideDown)) {
			return false;
		}

		var animationDistance = animationSlideDown ? slideDownDistance : 0;
		var animationSpeed;
		var animationTranslateToPoint = 0;
		var animationDuration = 0;
		var $backToTopButton = $($cache.backToTopButton);
		var $newsletterPopup = $($cache.newsletterpopupWrapperSel);
		var $singleCountryDetectionBlock = $($cache.countryDetectionBlock);
		var backToTopButtonMargin = parseInt($backToTopButton.css('right'));
		var backToTopButtonBottomOffset = +$backToTopButton.attr('data-bottom') || backToTopButtonMargin;
		var DEFAULT_ANIMATION_SPEED = 0.25;

		if ($newsletterPopup.length) {
			animationSpeed = getAnimationSpeed($newsletterPopup);
		} else if ($singleCountryDetectionBlock.length) {
			animationSpeed = getAnimationSpeed($singleCountryDetectionBlock);
		} else {
			animationSpeed = DEFAULT_ANIMATION_SPEED;
		}

		if (!animationSlideDown) {
			if ($cache.cookieBar.is(':visible')) {
				animationDistance += $cache.cookieBar.outerHeight();
			}

			if ($newsletterPopup.length) {
				animationDistance += $newsletterPopup.outerHeight();
			}

			if (!app.preferences.enableNewsletterPopup && $singleCountryDetectionBlock.length) {
				animationDistance += $($cache.countryDetectionBlock).outerHeight();
			}

			animationTranslateToPoint = animationDistance;
		} else {
			animationTranslateToPoint = backToTopButtonBottomOffset - slideDownDistance;
		}

		animationDuration = animationSpeed !== 0 ? animationDistance / animationSpeed : 0;
		$backToTopButton.attr('data-bottom', animationTranslateToPoint);

		$backToTopButton.css({
			transform: 'translateY(-' + animationTranslateToPoint + 'px)',
			'transition-duration': +(animationDuration / 1000).toFixed(1) + 's',
			'transition-delay': Number.isInteger(animationDelay) && animationDelay > -1 ? animationDelay / 1000 + 's' : ''
		});
	}

	function initializeEvents() {

		$cache.document.on('newsletterpopup.opened', function(){
			if(app.device.currentDevice() === 'mobile'){
				$($cache.newsletterPopupSel1).addClass($cache.newsletterFirstStepClass);
				$cache.html.removeClass($cache.overflowHiddenMobileClass);
			} else {
				$cache.body.removeClass($cache.overflowHiddenClass);
			}
			
			$($cache.typeBtnsSelectorChecked).each(function(){
				setDefaultGenderType.call(this);
			});
			
			$($cache.newsletterpopupWrapperSel).next('.fancybox-overlay').addClass($cache.newsletterpopupOverlayClass);

			$cache.body.addClass($cache.bodyClassOnNewsletterPopupOpened);
			
			handleNewsletterPopupEvents();
	
			showBottomBanner();

			backToTopButtonPosition();
		});

		$cache.document.on('newsletterpopup.beforeclosed', function() {
			$cache.body.removeClass($cache.bodyClassOnNewsletterPopupOpened);

			if ($($cache.newsletterpopupWrapperSel).hasClass('fancybox-opened')) {
				backToTopButtonPosition($($cache.newsletterpopupWrapperSel).outerHeight(), 0);
			}
		});

		$cache.document.on('newsletterpopup.closed', function () {
			if (!$($cache.body).hasClass($cache.openedMenuBodyClass)) {
				$cache.document.trigger('promoBanner.show');
			}
			$cache.document.off('click', newsletterPopupOutsideClick);

			$($cache.closingNewsletterPopupElementsSel).off('click', newsletterPopupOutsideClick);
		});

		$cache.document.on('newsletterpopupconfirmation.opened', function () {
			newsletterConfirmationPopupOpen();
			$($cache.countryDetectionBlockSel).hide();
		});

		$cache.document.on('newsletterpopupconfirmation.closed', function () {
			app.banners.newsletter.close();
			$cache.body.removeClass($cache.bodyClassOnConfirmationNewsletterPopupOpened);
		});

		$cache.document.on('simplenewsletterpopupconfirmation.opened', function () {
			newsletterConfirmationPopupOpen(true);
		});

		$cache.document.on('simplenewsletterpopupconfirmation.closed', function () {
			$cache.body.removeClass($cache.bodyClassOnSimpleConfirmationNewsletterPopupOpened);
		});

		$cache.document.on('countrydetectionblock.closed', function() {
			if (!app.preferences.enableNewsletterPopup) {
				backToTopButtonPosition($($cache.countryDetectionBlock).outerHeight());
			}
		});

		if (app.device.currentDevice() === 'mobile') {
			$cache.document.on('focus', $cache.newsletterMailFieldSel, function() {
				if (app.device.isAndroidOS()) {
					clearTimeout(focusTimeout);
					focusTimeout = setTimeout(function(){
						var mobileNewsletterPopup = $($cache.newsletterPopupSel1 + '.' + $cache.newsletterFirstStepClass);
						mobileNewsletterPopup.css({top: window.innerHeight - mobileNewsletterPopup.height() + 'px'});
					}, 500);
				}
			});

			$cache.document.on('blur', $cache.newsletterMailFieldSel, function(){
				var orientation = window.innerHeight > window.innerWidth ? 0 : 90;
				$($cache.newsletterPopupSel1 + '.' + $cache.newsletterFirstStepClass).css({top: configs.newsletterTop[orientation] + 'px'});
			});
		}

		$cache.document.on('click', $cache.typeBtnsSelector, function() {
			setDefaultGenderType.call(this);
		});

		$cache.document.ready(function() {
			if(sessionStorage.getItem("lipsSticker") !== '0' && $.cookie("lipsSticker") !== '0') {
				if(!$cache.cookieBar.hasClass('h-hidden')) {
					$cache.stickerContainer.css({
						'bottom': $cache.cookieBar.outerHeight() + parseInt($cache.stickerContainer.css('bottom'), 10)
					})
				}

				$cache.stickerContainer.show();
			}

			if (!app.preferences.enableNewsletterPopup) {
				showBottomBanner();
				backToTopButtonPosition();
			}
		});

		$cache.document.on('cookie.closed', function () {
			$cache.stickerContainer.css({
				'bottom': ''
			});

			setNewsletterPopupBottomPosition();
		});

		$cache.stickerAnchor.click(function () {
			$('html, body').animate({
				scrollTop: $cache.footerNewsletter.offset().top
			}, 1000);
		});

		$cache.stickerHide.click(function () {
			$cache.stickerContainer.hide();
			$.cookie("lipsSticker", 0);
			sessionStorage.setItem("lipsSticker", 0);
		});

		$cache.document.on('footer.newsletter.success', function() {
			$cache.fieldEmail.removeClass($cache.fieldValid);
		});

		$cache.window.on('resize', setNewsletterPopupBottomPosition);

		$cache.document.on('click', $cache.cookieCloseBtn, function() {
			$($cache.firstVisitBannerSel).slideUp('slow');
			backToTopButtonPosition($($cache.firstVisitBannerSel).outerHeight(), 200);
		});

		$cache.document.on('click touchstart', $cache.newsletterpopupWrapperSel + ', ' + $cache.firstVisitBannerSel + ', ' + $cache.countryDetectionBlock,  function(e) {
			e.stopPropagation();
		});
	}

	function newsletterConfirmationPopupOpen(simple) {
		var bodyPopupConfirmationClass = simple ? $cache.bodyClassOnSimpleConfirmationNewsletterPopupOpened : $cache.bodyClassOnConfirmationNewsletterPopupOpened;
		var newsletterPopupSel = simple ? $cache.newsletterPopupSel2 : $cache.newsletterPopupSel1;

		$cache.body.addClass(bodyPopupConfirmationClass);
		$(newsletterPopupSel).next('.fancybox-overlay').addClass($cache.newsletterpopupOverlayClass);
	}

	function newsletterPopupOutsideClick() {
		$cache.body.removeClass($cache.bodyClassOnNewsletterPopupOpened);

		setTimeout(function() {
			app.fancybox.close();
		}, 2000);
	}

	function bottomBannerClose() {
		if ($($cache.bottomBannerClassSel).hasClass($cache.bottomBannerOpen)) {
			$($cache.bottomBannerClassSel).removeClass($cache.bottomBannerOpen);

			setTimeout(function() {
				if ($($cache.shopthelookContentSel).length === 0) app.fancybox.close();
			}, 2000);
		}
	}

	function handleBottomBannerEvents() {
		if (app.device.isMobileView()) {
			$($cache.headerMenuToggleSel).on('click touchstart', bottomBannerClose);

			var touchmoved;
			$cache.link.on('touchend', function(){
				if(touchmoved !== true){
					bottomBannerClose();
				}
			}).on('touchmove', function(){
				touchmoved = true;
			}).on('touchstart', function(){
				touchmoved = false;
			});
		} else {
			$cache.document.on('click', bottomBannerClose);
		}
	}

	function handleNewsletterPopupEvents() {
		$($cache.closingNewsletterPopupElementsSel).on('click', newsletterPopupOutsideClick);
	}

	function setNewsletterPopupBottomPosition(newsletterPopupSel) {
		var bottomNLPosition = 0;

		if($cache.cookieBar.length) {
			bottomNLPosition += $cache.cookieBar.outerHeight();
		}

		$($cache.fancyboxOverlayFixedSel).css({
			'bottom': bottomNLPosition
		});

		$(newsletterPopupSel).css('bottom', bottomNLPosition);
	}

	function setDefaultGenderType(){
		var genderRadio = $(this),
			genderCategory = genderRadio.closest($cache.genderCategory),
			genderPageSection = genderCategory.data('signupSection'),
			typeField;

		if(genderCategory){
			typeField = $('[name$="_newsletter_' + genderPageSection + '_type"]');
			typeField.attr('value', genderRadio.data('gender'));

			genderCategory.find($cache.genderPlaceholder).attr('value', genderRadio.data('gender'));
		}
	}

	app.components = app.components || {};
	app.components.newsletter = app.components.newsletter || {};
	app.components.newsletter.custom = {
		init : function () {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));