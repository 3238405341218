(function (app, $) {
	var $cache = {};
	var $settings = {};

	function initParams(params){
		$settings = params;
	}

	function initializeCache() {
		$cache = {
			document: $(document),
			countrySelector: $('.js-country_selector').find('select')
		};

		$cache.form = $cache.countrySelector.closest('form');

		if($cache.form.length === 1) {
			$cache.actionUrl = decodeURIComponent(app.util.removeParamFromURL($cache.form.attr('action'), 'Country'));
		}
	}

	function initializeDOM(){
		if ($cache.countrySelector.length > 0 && !app.device.isMobileView()) {
			$cache.countrySelector.select2({
				minimumResultsForSearch: $settings.searchEnabled ? 0 : -1,
				dropdownCssClass: $settings.elementListCustomClass ? $settings.elementListCustomClass : ''
			});
		}
	}

	function initializeEvents() {
		$cache.countrySelector.change(function(){
			$cache.actionUrl = app.util.appendParamToURL($cache.actionUrl, 'Country', $cache.countrySelector.val());
			$cache.countrySelector.prop("disabled", true);

			var data = {
				url: $cache.actionUrl,
				selectedCountryCode: app.user.country.value
			};

			app.components.global.localeselector.redirectURL = data.url;
			$cache.document.trigger('modal.removegiftcert.confirm', data);
		});
	}

	/**
	 * @namespace app.components.global.countryselector public object
	 **/
	app.components = app.components || {};
	app.components.countryselector = app.components.countryselector || {};
	app.components.countryselector.custom = {
		// initializations
		init : function (params) {
			initializeCache();
			initParams(params);
			initializeDOM();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);