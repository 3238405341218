;(function (app, $) {
	//update product grid count based on selected mode
	var $lazyLoadxtSizer = $('.js-lazyloadxt-sizer');
	if ($lazyLoadxtSizer.length) {
		app.components.global.images.imageLoaded($lazyLoadxtSizer.eq(0)).always(showSearchResultContent);
	}
	else {
		showSearchResultContent();
	}

	function showSearchResultContent() {
		$(".l-search_result-content")
			.removeClass('m-four-columns')
			.addClass($('.b-change_view-type-active').data('grid-class'))
			.removeClass('h-hidden');
	}

	var $cache = {
		body : $('body'),
		swipeContOpenMenuSel : '.m-site_opened .js-header_menu_toggle',
		document: $(document),
		window: $(window),
		htmlBody: $('html, body'),
		header: $('.js-header_main'),
		headerContainer: $('.js-header_container'),
		headerSearchFormClass: 'js-min_search',
		headerSearchTitleClass: 'js-header_search-title',
		headerColorClass: 'm-header-white',
		siteWrapperSel: '.js-site_wrapper',
		quickViewSel: '.js-quick_view_main',
		dynamicPromoBannerSel: '.js-dynamic-promo-banner_block',
		dynamicPromoBanner: '.js-dynamic-promo-banner',
		dynamicPromoBannerCloseBtnSel : '.js-header-promo-close',
		overflowHiddenClass: 'h-overflow-hidden',
		overflowHiddenMobileClass: 'html_fancybox_opened body',
		hiddenClass : 'h-hidden',
		dynamicPromoBannerVisibleClass: 'b-dynamic-promo-banner_block--visible',
		newsletterpopupWrapperSel: '.js-newsletter_popup-wrapper',
		firstVisitBannerSel: '.js-first-visit-banner',
		firstVisitBannerAnimatedClass: 'm-first-visit-banner-animated',
		shopthelookSel: '.js-shopthelook',
		hotspotLinkSel: '.js-hotspot-link',
		hotspotLinkTooltipClass: 'b-hotspot-link-tooltip',
		hotspotLinkTooltipLeftClass: 'm-tooltip-left',
		minimizedClass: 'h-minimized',
		hotspotLinkTooltipWrapperOverhiddenSel: '.m-tooltip-overflowed',
		activeClass: 'm-active',
		minicartProductTiles: '.js-mini-cart-product-tiles',
		minicartFlyoutTitleClass: '.js-mini-cart-flyout-title',
		homepageMain: $('.js-homepage-main'),
		dynamicPromoBannerCookieName: app.preferences.promoBannerCookieName,
		pdScope: '.js-scope',
		pdWidthItem: '.js-width-impacter',
		pdImage: '.js-image-type',
		pdSameTypeClass: 'same-type',
		pdCombinedClass: 'combo-type',
		pdNavAccordionToggler: '.js-pd-accordion_toggler',
		pdNavAccordionContainer: '.js-pd-accordion_container',
		pdNavAccordionOpenedClass: 'b-pd-accordion_container--open',
		pdCategoryListLink: '.js-image-on-hover',
		pdCategoryImagePlaceholder: '.js-category-image-placeholder',
		pdNavRegion: '.experience-region',
		pdCategoryLists: '.js-category-list',
		pdImageDefaultPointer: '.js-default-image'
	};

	if (app.device.isMobileView()) {
		$cache.body.on('swipeleft', $cache.swipeContOpenMenuSel, app.components.global.categoriesNavigation.menuToggle);

		$cache.document.on('mobile_menu.open', function() {
			if (!$($cache.dynamicPromoBanner).hasClass($cache.hiddenClass)) {
				$($cache.dynamicPromoBannerSel).removeClass($cache.dynamicPromoBannerVisibleClass);
				$cache.headerContainer.css('top', 0);
				app.components.toggler.custom.updateBodyPaddingTop(0);
			}

			$cache.document.one('mobile_menu.close', function() {
				$cache.document.trigger('promoBanner.show');
			});
		});

		$cache.document.on('click', $cache.pdNavAccordionToggler, function() {
			const $this = $(this);

			$this.closest($cache.pdNavAccordionContainer).toggleClass($cache.pdNavAccordionOpenedClass);
		});

		$cache.document.on('toggler.untoggled toggler.toggled', function(e, eventObj) {
			var togglerElement = eventObj.$toggleElement;

			if (togglerElement && (togglerElement.hasClass($cache.headerSearchTitleClass) || togglerElement.hasClass($cache.headerSearchFormClass))) {
				$cache.headerContainer.toggleClass($cache.headerColorClass, e.namespace === 'toggled' && !$cache.headerContainer.hasClass($cache.headerColorClass));
			}
		});

		var scrolltop;

		function lockBody() {
			if($cache.window.scrollTop()) {
				scrolltop = $cache.window.scrollTop();
				$($cache.body).css({
					top: -scrolltop
				});
			}
		}

		function unlockBody() {
			$($cache.body).css({
				top: ''
			});

			$cache.window.scrollTop(scrolltop);
			setTimeout(function() {
				scrolltop = null;
			}, 0);
		}

		$cache.document.on('shopthelook.beforeShow', lockBody);

		$cache.document.on('shopthelook.closed', function() {
			unlockBody();

			if ($($cache.hotspotLinkSel).length) {
				removeAnchorBackForHotspot();
			}
		});

		$cache.document.on('ready', function() {
			var $mobHeader = $($cache.headerContainer);
			$mobHeader.headroom({
				offset: $mobHeader.outerHeight(true),
				tolerance: {
					up: 60,
					down: 0
				},
				onTop: function() {
					app.components.toggler.custom.updateBodyPaddingTop($mobHeader.outerHeight());
				}
			});
		});
	}

	$cache.document.on('promoBanner.show', function() {
		if (!$.cookie($cache.dynamicPromoBannerCookieName)) {
			if (!$cache.homepageMain.length || $.cookie('nlPopupCount')) {
				$($cache.dynamicPromoBannerSel).addClass($cache.dynamicPromoBannerVisibleClass);
				$cache.headerContainer.trigger('update.header');
			}
		}
	});

	$cache.document.on('feedpage-updated', function(e, data) {
		if ('shopthelook' in app.components.global) {
			app.components.global.shopthelook.initializeElements($(data));
		}
	});

	if(!app.device.isMobileView()) {
		$cache.document.on('click', $cache.dynamicPromoBannerCloseBtnSel, function () {
			$cache.header.trigger('update.header');
		});

		$cache.document.on('scroll', function() {
			if (isDynamicBannerVisible()) {
				dynamicBannerBehavior($cache.dynamicPromoBannerSel);
			}
		});
	}

	$cache.document.on('ready', () => {
		if(!app.configs.countryDetectionBlock && !app.preferences.enableNewsletterPopup || app.page.currentPage !== 'homepage') {
			$($cache.firstVisitBannerSel).addClass($cache.firstVisitBannerAnimatedClass);
		}

		if (app.device.currentDevice() === 'desktop') {
			pdNavInit();
		}
	});

	$cache.window.on('load', function() {
		var hotspotLinks = $($cache.hotspotLinkSel);

		if (app.device.currentDevice() === 'desktop') {
			if (hotspotLinks.length) {
				$($cache.hotspotLinkSel).each(function() {
					var $this = $(this);

					buildHotspotLinkTooltips($this);

					$this.hover(function() {
						$(this).addClass($cache.activeClass).find('.' + $cache.hotspotLinkTooltipClass).show();
					}, function() {
						$(this).removeClass($cache.activeClass).find('.' + $cache.hotspotLinkTooltipClass).hide();
					});
				});
			}
		} else if (app.isMobileView()) {
			if (hotspotLinks.length) {
				anchorBackForHotspot();
			} else if (app.page.type !== 'product') {
				removeAnchorBackForHotspot();
			}
		}
	});

	function removeAnchorBackForHotspot() {
		localStorage.removeItem('hotspotActiveData');
	}

	function anchorBackForHotspot() {
		var data;

		try {
			data = JSON.parse(localStorage.getItem('hotspotActiveData'));
		} catch (e) {
			return false;
		}

		if (data && data.url === window.location.href) {
			$cache.window.scrollTop(data.relativeScrollPosition);

			removeAnchorBackForHotspot();
		}

		$($cache.hotspotLinkSel).on('click', function() {
			var hotspotActiveData = {
				url: window.location.href,
				relativeScrollPosition: $cache.window.scrollTop()
			};

			localStorage.setItem('hotspotActiveData', JSON.stringify(hotspotActiveData));
		});

		return true;
	}

	function dynamicBannerBehavior(el) {
		var promoBannerHeight = $(el).height();
		var scrollPosition = $(this).scrollTop();
		var headerTopIndentation = (scrollPosition <= promoBannerHeight) ? (-scrollPosition) : (-promoBannerHeight);

		if (scrollPosition <= 0) {
			headerTopIndentation = 0;
		}

		$cache.headerContainer.css('top', headerTopIndentation);
	}

	function isDynamicBannerVisible() {
		return $($cache.dynamicPromoBannerSel).length && $($cache.dynamicPromoBannerSel).hasClass($cache.dynamicPromoBannerVisibleClass) && !$($cache.dynamicPromoBanner).hasClass($cache.hiddenClass);
	}

	app.components.product.custom.initToolTipEvents();

	/**
	 * @function
	 * @description Append part of suggested phrase
	 * @param {Array} Array of searched product + suggested phrase
	 * @param {Object} global cache
	 */
	var placeSuggest = function (data, $cache) {
		if (!data || data.length === 0) {
			$cache.suggestInput.empty();
			return;
		}
		var firstElem = data[0],
			searchTerm = $cache.searchInput.val();

		if ("suggest" in firstElem){
			var suggestPhrase = firstElem.suggest;
			suggestPhrase = suggestPhrase.substring(searchTerm.length, suggestPhrase.length);
			$cache.suggestInput.html(suggestPhrase);
		}
	};

	app.recommendations.initCarouselAfterAjaxLoad = function( $carouselContainer ){
		$($carouselContainer).each(function(){
			// Skip carousels which are already initialized (in case when several carousel present on page)
			if(!app.owlcarousel.getInstance(this)){
				app.owlcarousel.initCarousel(this);
			}
		})
	};

	/**
	 * @function
	 * @description pageDesigner navigation builder, sets class to provide correct width for blocks
	 */
	function pdNavInit() {
		var $scopes = $($cache.pdScope);

		$scopes.each(function() {
			const $this = $(this);
			const $rightRegion = $this.find('.experience-rightWideRegion');
			const $rightElements = getWidthElements($rightRegion);
			const $imagePlaceholderComponent = $this.find($cache.pdCategoryImagePlaceholder);

			var regionWidth = 0;

			$rightElements.each((index, item) => {
				regionWidth += $(item).outerWidth();
			});

			$imagePlaceholderComponent.each(function() {
				const $self = $(this);
				const $image = $self.find('img');
				const defaultSource = $image.attr('src');

				$image.data('default', defaultSource);
			});

			$rightRegion.css('width', regionWidth + 25 + 'px'); // 25px left padding + border
		});

		$cache.document.find($cache.pdCategoryImagePlaceholder).on('restoreDefaults', function() {
			const $this = $(this);
			const $source = $this.find('source');

			$source.attr('srcset', $source.data('srcset'));
		});

		$cache.document.find($cache.pdCategoryListLink).on('mouseover', function() {
			const $this = $(this);
			const imageSrc = $this.data('image');
			const $placeholder = $this.closest($cache.pdNavRegion).find($cache.pdCategoryImagePlaceholder);

			$placeholder.find('source').attr('srcset', imageSrc);
		}).on('mouseleave', () => {
			$cache.document.find($cache.pdCategoryImagePlaceholder).trigger('restoreDefaults');
		});
	}

	function getWidthElements(parentSelector) {
		return parentSelector.find($cache.pdWidthItem);
	}

	/**
	 * @function
	 * @description callback functions that are using in DG brand
	 */
	app.simpleSearchFunctions = {
		simpleSearchHandler : function ($cache) {
			isLoading = true;
			$.extend($cache, {
				selectedCategory : $(".js-min_search .js-simple_search_category_button.active").val() || app.preferences.simpleSearchDefaultCategoryId
			});

			var that = this,
				searchTerm = $cache.searchInput.val(),
				url = app.util.appendParamsToUrl(app.urls.searchSuggestions, {"cgid" : $cache.selectedCategory, "q" : searchTerm});

			app.ajax.load({
				url: url,
				callback : function (data) {
					that.successSimpleSearchCallback(data, $cache);
				}
			});

		},

		/**
		 * @function
		 * @description Append part of suggested phrase
		 * @param {Array} Array of searched product + suggested phrase
		 * @param {Object} global cache
		 */
		successSimpleSearchCallback : function(data, $cache) {
			this.buildResponseDom(data, $cache);
			placeSuggest(data, $cache);
			$cache.resultContainer.removeClass(this.minimizedClass);
			setTimeout(function() {
				isLoading = false;

				var bodyPaddingTop = parseInt($("header").height());
				$('body').animate({"padding-top": bodyPaddingTop + "px"}, 1000, "easeInExpo");

			}, this.delay);

		}
	};

	if('toggler' in app.components && 'custom' in app.components.toggler){
		$cache.document.on('quickview.beforeShow dialog.opened', function () {
			app.components.toggler.custom.attachTogglerOnContainer($($cache.quickViewSel))
		});

		if (app.device.isMobileView()) {
			$cache.document.on('minicart.flyout.open minicart.flyout.update', function() {
				if (app.minicart.products.length > 1) {
					app.components.toggler.custom.attachTogglerOnContainer($('.js-slide-flyout-wrapper'));
					$($cache.minicartFlyoutTitleClass).addClass($cache.activeClass);
				} else {
					$($cache.minicartProductTiles).removeClass($cache.minimizedClass);
					$($cache.minicartFlyoutTitleClass).removeClass($cache.activeClass);
				}
			});
		}

		$cache.document.on('dialog.showed', function() {
			app.components.toggler.custom.attachTogglerOnContainer($('.js-addtostatus'));
		});
	}

	function buildHotspotLinkTooltips(hotspotLink) {
		if (!hotspotLink.text()) {
			return false;
		}

		var tooltip = $('<div>', { class: $cache.hotspotLinkTooltipClass });
		var tooltipContent = $('<div>', {
			class: $cache.hotspotLinkTooltipClass + '_content',
			text: hotspotLink.text()
		});

		var tipX = hotspotLink.outerWidth();
		var tipY = '50%';

		// Set default position of tooltip before correction
		tooltip.append(tooltipContent).css({ top: tipY, left: tipX });
		hotspotLink.append(tooltip);

		// If tooltip text is too long,it should get 50% of parent tile width
		if (tooltip.width() > hotspotLink.parent().width() / 2) {
			tooltip.css({ width: hotspotLink.parent().width() / 2, 'white-space': 'normal' });
		}

		// Get calculated tooltip coordinates and size
		var tooltipRect = tooltip[0].getBoundingClientRect();
		var hotspotWrapper = hotspotLink.parent();
		var diff = Math.abs(tooltipRect.left % $cache.window.width());
		var tooltipLeft = diff + tooltipRect.width > $cache.window.width();

		if (tooltip.closest($cache.hotspotLinkTooltipWrapperOverhiddenSel).length) {
			var hotspotWrapperRect = hotspotWrapper[0].getBoundingClientRect();

			diff = Math.abs(tooltipRect.left - hotspotWrapperRect.left) % hotspotWrapper.width();
			tooltipLeft = diff + tooltipRect.width > hotspotWrapper.width();
		}

		if (tooltipLeft) {
			tipX = -tooltipRect.width;
			tooltip.addClass($cache.hotspotLinkTooltipLeftClass);
		}

		// Apply corrected position
		tooltip.css({ top: tipY, left: tipX, display: 'none' });
	}
}(window.app = window.app || {}, jQuery));
