/*
Documentations: http://api.jqueryui.com/tabs/#method-widget
Structure example:

<div class="js-recommendations-tabs">
	<ul>
		<li data-tab-id="recommendationTab1"></li>
		<li><a href="#recommendationTab2">First</a></li>
		<li data-tab-id="recommendationTab3"></li>
	</ul>
	<div id="recommendationTab1" class="js-recom-tab-content">
		<!-- AJAX TAB CONTENT AJAX TAB CONTENT -->
	</div>
	<div id="recommendationTab2">
		<!-- STATIC TAB CONTENT STATIC TAB CONTENT-->
	</div>
	<div id="recommendationTab3" class="js-recom-tab-content">
		<!-- AJAX TAB CONTENT AJAX TAB CONTENT -->
	</div>
</div>
*/
(function (app, $) {
	var $cache = {},
		settings = {
			//Make first loaded with ajax tab is active.
			"activateFirstLoaded": false,
			//First tab always active. Have a biggest priority over the "activateFirstLoaded"
			"firstTabAlwaysActive": true
		};
	
	function initializeCache() {
		$cache = {
			document: $(document),
			container: $('.js-recommendations-tabs'),
			tabHeaderTmpl: $('#recomTabHeaderTmpl').text(),
			recomTabContentSel: '.js-recom-tab-content',
			tabHeadersSel: '.js-recommendation_title, .js-last_visited-title',
			ajaxLoadEvents: 'recommendations.loaded last.visited.loaded',
			ajaxNotFoundEvents: 'recommendations.notfound',
			owlCaroselsSelectors: '.js-productrecommendations-jcarousel',
			jCarouselsSelectors: '.js-j_carousel',
			tabContainerSelector: '.js-recommendations-tabs'
		}
	}
	
	function initializeConfiguration(options){
		if(typeof options === 'object'){
			settings = $.extend({}, settings, options);
		}
	}
	
	function initializeDom() {
		$cache.container.tabs({
			collapsible: false,
			active: false,
			activate: function(event, ui){
				//Refresh owl carousels
				var owlCarousel = ui.newPanel.find($cache.owlCaroselsSelectors),
					jCarousel = ui.newPanel.find($cache.jCarouselsSelectors);
				if(owlCarousel.length){
					app.owlcarousel.initCarousel(owlCarousel);
					owlCarousel.data('owlCarousel').reload();
				}
				if(jCarousel.length){
					jCarousel.jcarousel({
						initCallback: function(){
							jCarousel.jcarousel('reload');
						}
					});
				}
				document.dispatchEvent(new CustomEvent('lazyload-reinit'));
				// For extend: here you can add some other type of carousels which need to be refreshed after tab is switched
			}
		});
	}

	function initializeEvents(){
		// Add tab event
		$cache.document.on($cache.ajaxLoadEvents, function(event, tab){
			var $tab = $cache.container.find(tab),
				headerElement = $tab.find($cache.tabHeadersSel),
				header = headerElement.text();
			if($tab.is(':empty') || !headerElement.length){
				removeTab($tab);
			}
			else{
				addTab($tab, header);
			}
		})
		.on($cache.ajaxNotFoundEvents, function(event, tab){
			var $tab = $cache.container.find(tab);
			if($tab && $tab.length){
				removeTab($tab);
			}
		});

		$cache.document.on('changeVariation.changed', function(event, eventData) {
			$cache.container = eventData.container.find($cache.tabContainerSelector);

			app.components.recomtabs.custom.initDom();
		});
	}
	
	function addTab($tab, header){
		var targetTab = $tab.closest($cache.recomTabContentSel);
		if (targetTab.length){
			var targetTabData = targetTab.attr('id');
			var li = $cache.tabHeaderTmpl.replace(/\{\{href\}\}/g, '#' + targetTabData).replace(/\{\{label\}\}/g, header);
			var targetTabHeader = $cache.container.find('[data-tab-id=' + targetTabData + ']');

			if (targetTabHeader.length){

				//Add tab header and reinit tabs
				var newTargetTabHeader = $(li).replaceAll(targetTabHeader);
				var allHeaders = $cache.container.children('ul:first-child').find('li').not('[data-tab-id]');
				var index = allHeaders.index(newTargetTabHeader);

				$cache.container.tabs("refresh");

				// Try to activate first loaded tab
				if(settings.activateFirstLoaded && !settings.firstTabAlwaysActive && (index !== -1) && ($cache.container.tabs("option", "active") === false) ){
					$cache.container.tabs("option", "active", index);
				}
				
				// try to activate first tab
				if(settings.firstTabAlwaysActive && ($cache.container.tabs("option", "active") !== 0) ){
					$cache.container.tabs("option", "active", 0);
				}
			}
		}
	}
	
	function removeTab($tab){
		var targetTab = $tab.closest($cache.recomTabContentSel);
		if(targetTab.length){
			var targetTabHeader = $cache.container.find('[data-tab-id=' + targetTab.attr('id') + ']');
			targetTab.remove();
			targetTabHeader.remove();
			$cache.container.tabs("refresh");
		}
	}
	

	/*************** app.components.recomtabs.custom public object ***************/
	app.components = app.components || {};
	app.components.recomtabs = app.components.recomtabs || {};
	app.components.recomtabs.custom= {
		init : function (options) {
			initializeConfiguration(options);
			initializeCache();
			initializeDom();
			initializeEvents();
		},
		initDom: initializeDom
	};
})(window.app = window.app || {}, jQuery);
