(function(app, $) {
	var $cache = {},
		tabs = {},
		sortByUrl = '';

	function initializeCache() {
		$cache = {
			document: $(document),
			filtersDiv: $('.js-refinement_visibility .js-min_refinement_selector'),
			sortbyDiv: $('.js-refinement_visibility .js-min_sortby_selector'),
			refinements: $('.js-refinement_visibility'),
			breadcrumbsContainer: $('.js-breadcrumb-refinement_container'),
			breadcrumbLinkClass: 'js-breadcrumb_refinement-link',
			breadcrumbAttributeClass: 'js-attribute-breadcrumb-refinement',
			refinementCntrSel: '.js-refinement_container',
			activeRefinementSel: '.js-refinement_container .js-refinement-link-active',
			activeRefinementClass: 'b-refinement-link--active js-refinement-link-active',
			activeSortingSel: '.js-filter-sortby-link.selected',
			loadrClassKey: 'searchLoaderClass',
			bcRefinementCntrSel: '.js-breadcrumb-refinement_container',
			filtersDivSel: '.js-min_refinement_selector',
			closeButtonSel: '.js-filter-close_button',
			sortBySel: '.js-min_sortby_selector',
			custTogglerSel: '.js-custom-toggler',
			toggledSel: '.h-toggled',
			activeTabCls: '.ui-state-active',
			minimizedCls: 'h-minimized',
			hidenCls: 'h-hidden',
			refinementsSel: '.js-refinement_visibility',
			refinementListSel: '.js-refinement-list',
			refinementTogglerSel: '.js-filter-toggler',
			applyOnClickClass: 'js-applyOnClick',
			applyButtonSelector: '.js-btn-apply'
		};
		$cache.appliedFilters = $($cache.activeRefinementSel);
	}

	function initializeEvents() {
		$cache.refinements.on('click', '.js-refinement a, .' + $cache.breadcrumbLinkClass, function() {
			var $that = $(this);
			if ($that.parent().hasClass('js-unselectable')) {
				return;
			}
			if ($that.hasClass($cache.breadcrumbLinkClass)) {
				var dataAttr = {
						name: $that.data('prefn'),
						value: $that.data('prefv')
					},
					activeElem = $(
						$cache.activeRefinementSel + "[data-prefn='" + dataAttr.name + "'][data-prefv='" + dataAttr.value + "']"
					);

				activeElem.removeClass($cache.activeRefinementClass);
			} else {
				$that.toggleClass($cache.activeRefinementClass);
			}
			return false;
		});

		$cache.refinements.on('click', '.js-refinement a, .' + $cache.breadcrumbLinkClass, function(e) {
			var $this = $(this);
			var curTabCls = $this.closest($cache.filtersDivSel).data('tab-class')
				|| $this.closest($cache.refinementsSel).find($cache.filtersDivSel).data('tab-class')
				|| 'js-refinement_visibility';
			var newUrl = app.util.removeCountedParametersFromURL(tabs[curTabCls].url, ['prefn', 'prefv']);
			newUrl = app.util.removeParamsFromURL(newUrl, ['isSortByOpened', 'isFilterOpened']);
			var refinements = app.components.search.filters.getTabActiveRefinements(curTabCls, $cache.appliedFilters);
			var refinementGroup = $this.data('prefn');
			var queryObj = collectFilterParams(refinements);
			queryObj = addStateParams(queryObj);
			tabs[curTabCls].url = app.util.appendParamsToUrl(newUrl, queryObj, true);

			if (!app.preferences.enableFilterApplyBtn || $(this).closest($cache.bcRefinementCntrSel).length) {
				app.search.updateProductListing(tabs[curTabCls].url, false, { isFilterOpened: true, lastAppliedFilter: refinementGroup }, $cache.loadrClassKey);
			}
			$('.js-refinement_visibility .js-toggler').trigger(Modernizr.touchevents ? 'touchstart' : 'click');
			
			if($this.hasClass($cache.applyOnClickClass)) {
				$this.closest($cache.refinementCntrSel).find($cache.applyButtonSelector).click();
			}

			e.stopPropagation();
		});

		$cache.refinements.on('click', '.js-sort_select-value', function(e) {
			sortByUrl = app.util.appendParamToURL($(this).attr('href'), 'isSortByOpened', 'true');
			$(this).attr('href', sortByUrl);
		});

		$cache.refinements.on('click', $cache.applyButtonSelector, function() {
			var $this = $(this),
				wrapper = $this.closest($cache.filtersDivSel),
				curTabCls = wrapper.data('tab-class');
			
			if (!app.util.equalUrlParams(tabs[curTabCls].url, location.href, new RegExp('prefn|prefv'))) {
				$cache.document.trigger('refinements-before_update', {
					"refinementClass" : wrapper.attr('class')
				});
				app.progress.show(wrapper.find($cache.refinementCntrSel), $cache.loadrClassKey);
				$this.hide();
				app.search.updateProductListing(tabs[curTabCls].url, false, false, null, false);
			} else {
				wrapper.siblings($cache.custTogglerSel).each(function() {
					var toggler = $(this);
					var classWhichTogglerSlides = toggler.data('slide');
					var togglerSlidesThisApply = $this.parents(classWhichTogglerSlides).length;
					if(togglerSlidesThisApply) {
						toggler.trigger('toggle');
						return false;
					}
				});
			}
		});

		$cache.refinements.on('click', $cache.closeButtonSel, function() {
			$($cache.refinementTogglerSel + '[data-slide="' + $(this).data('tab-sel') + '"]').trigger('toggle');
		});

		$cache.breadcrumbsContainer.on('click', '.' + $cache.breadcrumbLinkClass, function(e) {
			var $this = $(this);
			if($this.hasClass($cache.breadcrumbAttributeClass) && !$this.closest($cache.refinements).length && app.util.getConfig('filters.showOnNoHits')){
				e.preventDefault();
				app.search.updateProductListing($this.attr('href') || $this.data('url'), false, { isFilterOpened: false }, null, false);
			}
		});

		$cache.document.on('refinements-update', function(e, extParams) {
			$cache.appliedFilters = $($cache.activeRefinementSel);
			
			if(extParams && extParams.noHitsPage){
				if(app.device.isMobileUserAgent()){
					$cache.document.trigger('click', $('.' + app.util.getConfig('filters.switcherClass') + $cache.activeTabCls));
					$cache.document.trigger('click', $('.' + app.util.getConfig('filters.switcherSortClass') + $cache.activeTabCls));
				}
				else{
					$cache.document.trigger('click', $($cache.filtersDivSel).not('.' + $cache.minimizedCls).find($cache.closeButtonSel));
					$cache.document.trigger('click', $($cache.custTogglerSel + $cache.toggledSel).filter(function(){ $(this).data('slide') === $cache.sortBySel; }) );
				}
				app.progress.hide();
				$cache.refinements.find($cache.applyButtonSelector).show();
			}
		});
	}

	function initializeDOM(param) {
		setSearchLoaderClass(param);
		setFilterDropdownClass();
	}

	function addStateParams(queryObj) {
		var queryExtended = $.extend({}, queryObj);
		if ($cache.filtersDiv.is(':visible') && app.preferences.isFilterOpenedOnUpdate) {
			queryExtended.isFilterOpened = true;
		}
		if ($cache.sortbyDiv.is(':visible')) {
			queryExtended.isSortByOpened = true;
		}
		return queryExtended;
	}

	function collectFilterParams(refinements) {
		var queryObj = {};
		Object.keys(refinements).forEach(function(key, index) {
			queryObj['prefn' + (index + 1)] = key;
			queryObj['prefv' + (index + 1)] = refinements[key];
		});
		return queryObj;
	}

	function setFilterDropdownClass() {
		$cache.filtersDiv.addClass('l-cols-' + $cache.refinements.find($cache.refinementListSel).length);
	}

	function setSearchLoaderClass(param) {
		if (param != null && typeof param === 'object' && param.hasOwnProperty('loaderClass')) {
			app.progress.setAditionalClass($cache.loadrClassKey, param.loaderClass);
		}
	}
	
	function initTabsConfigs() {
		var tabsConfigs = app.util.getConfig('filters.tabs'),
			url = $('.js-subcategory_refinement_list').data('parentCategoryUrl') || window.location.href;
		
		for (var i = 0, len = tabsConfigs.length; i < len; i++) {
			var tabCls = $('.' + tabsConfigs[i].dropdownClass).length ? tabsConfigs[i].dropdownClass : 'js-refinement_visibility';
			$('.' + tabCls + ' .js-clear_search_filters').toggleClass(
				$cache.hidenCls,
				!$('.' + tabCls + ' .js-refinement-link-active').length
			);
			tabs[tabCls] = { url: url };
		}
	}

	/**
	 * @namespace app.components.search.filters public object
	 **/
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.filters = {
		init: function(param) {
			initializeCache();
			initTabsConfigs();
			initializeDOM(param);
			initializeEvents();
		},

		getTabActiveRefinements: function(tabCls, $applied) {
			var result = {},
				tabsLinkApplied = $applied && tabCls ? $applied.not('.' + tabCls + ' a') : {};
			active_refiments_link = tabCls ? $('.' + tabCls + ' .js-refinement-link-active') : $($cache.activeRefinementSel);

			active_refiments_link = $.merge(active_refiments_link, tabsLinkApplied);
			$.each(active_refiments_link, function() {
				var $this = $(this),
					$link_data = $this.data();
				if ($link_data && 'prefn' in $link_data && 'prefv' in $link_data) {
					var prefn = $link_data.prefn,
						prefv = $link_data.prefv;
					if (prefn in result) {
						result[prefn] = result[prefn] + '|' + prefv;
					} else {
						result[prefn] = prefv;
					}
				}
			});
			return result;
		},

		/**
		* Check if products sorting is applied
		* @return {string} Applied sorting rule name
		*/
		getTabActiveSorting: function() {
			var appliedSorting = $($cache.refinementCntrSel).find($cache.activeSortingSel);

			if (appliedSorting.length > 0) {
				return appliedSorting[0].dataset.name;
			} else {
				return '';
			}
		},

		getTabRefinements: function(tabCls) {
			var result = [];
			var tabLinks = tabCls ? $('.' + tabCls + ' a') : [];

			$.each(tabLinks, function () {
				var $this = $(this);
				var $link_data = $this.data();
				if ($link_data && 'prefn' in $link_data) {
					var prefn = $link_data.prefn;
					if (result.indexOf(prefn) === -1) {
						result.push(prefn);
					}
				}
			});
			return result;
		}
	};
})((window.app = window.app || {}), jQuery);
