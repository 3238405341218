(function(app, $) {
	function LoginPopup() {
		app.popups.Popup.call(this, 'LoginPopup');
	}

	LoginPopup.prototype = Object.create(app.popups.Popup.prototype);
	LoginPopup.prototype.constructor = app.popups.Popup;

	LoginPopup.prototype.open = function(params) {
		this.variant = (params && params.variant) || 'primary';
		this.markup = getMarkup();
		app.popups.Popup.prototype.open.call(this, params);
		app.popups.Popup.prototype.setHeightCSSVariable.call(this);
		app.validator.init();

		this.$markup.find('.js-popup-close-button').remove();

		app.progress.hide();
	};

	LoginPopup.prototype.initMarkup = function() {
		app.progress.show(this.$markup);

		this.$markup.find('.js-password_reset_popup').on('click', (e) => openPasswordResetPopup(e, this.variant));
		this.$markup.find('.js-login_account-login_button').on('click', (e) => onSubmitButtonClick(e));

		app.popups.Popup.prototype.initMarkup.call(this);
	};

	function getMarkup() {
		return app.util.renderTemplate($('#loginPopupTemplate').html());
	}

	function openPasswordResetPopup(e, variant) {
		e.preventDefault();
		const resetPopupURL = e.target.getAttribute('href');

		app.popupsMgr.closeAll();

		app.popupsMgr.open('PasswordReset', {
			templateId: 'passwordResetPopupTemplate',
			variant: variant,
			layoutURL: resetPopupURL
		});
	}

	function onSubmitButtonClick(e) {
		e.preventDefault();

		var $form = $(e.target).closest('form');

		$form.validate();

		if ($form.valid()) {
			$form.submit();
		}
	}

	app.popups.LoginPopup = new LoginPopup();
})((window.app = window.app || {}), jQuery);
