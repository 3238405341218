import 'brand/old/components/components.config.specific';
import 'oneapp/old/components/global.firstvisitbanner';
import 'oneapp/old/components/global.newsletter';
import 'oneapp/old/components/global.simplesearch';
import 'oneapp/old/components/newsletter.handlepopup';
import 'brand/old/components/newsletter.custom';
import 'oneapp/old/components/header/simplesearch.gender';
import 'oneapp/old/components/toggler.custom';
import 'oneapp/old/components/global.countrydetectionblock';
import 'brand/old/components/categoryflyout.custom';
import 'brand/old/components/header.custom';
import 'oneapp/old/components/global.header';
import 'brand/old/components/checkout.custom';
import 'brand/old/components/product.custom';
import 'brand/old/components/quickview.custom';
import 'brand/old/components/searchfilters.custom';
import 'oneapp/old/components/search.filters';
import 'brand/old/components/social.custom';
import 'brand/old/components/storefront.custom';
import 'brand/old/components/wishlist.custom';
import 'brand/old/components/loadmore.custom';
import 'brand/old/components/search.custom';
import 'oneapp/old/components/global.clusterlazyload';
import 'brand/old/components/cluster.custom';
import 'brand/old/components/recomtabs.custom';
import 'brand/old/components/countryselector.custom';
import 'brand/old/components/localeselector.custom';
import 'oneapp/old/components/global.shopthelook';
import 'oneapp/old/components/app.feedpage';
import 'brand/old/components/video.custom';
import 'oneapp/old/lib/select2/select2.min';
import 'oneapp/old/lib/jquery/jcarousel/jquery.jcarousel-swipe';
import 'oneapp/old/lib/jquery/jcarousel/jquery.jcarousel-lazyloading';
import 'oneapp/old/lib/jquery/jcarousel/jquery.jcarousel-fade';
import 'oneapp/old/lib/jquery/jcarousel/jquery.jcarousel-statusbar';
import 'oneapp/old/lib/jquery/jcarousel/jquery.jcarousel-smoothscroll';
import 'brand/old/app.jcarousel.custom.settings';
import 'oneapp/old/app.jcarousel';
import 'brand/old/app.custom';
import 'oneapp/old/components/flyouts/flyout';
import 'oneapp/old/components/flyouts/flyoutMgr';
import 'oneapp/old/components/flyouts/minicart';
import 'oneapp/old/components/flyouts/wishlist';
import 'oneapp/old/components/popups/popup';
import 'oneapp/old/utils/popupsMgr';
import 'oneapp/old/components/popups/confirmActionPopup';
import 'oneapp/old/components/popups/WorldpayThreeDS';
import 'oneapp/old/components/popups/login';
import 'oneapp/old/components/popups/passwordReset';
import 'oneapp/old/components/popups/passwordResetMessage';
import 'oneapp/old/components/popups/register';
import 'oneapp/src/classes/minicart';
import 'oneapp/src/classes/wishlist';
import 'oneapp/src/classes/lazyLoad';
import 'oneapp/old/components/banners/banner';
import 'oneapp/old/components/banners/newsletter';
import 'oneapp/src/classes/banners/onetrust';
import 'oneapp/old/utils/bannersMgr';
import 'oneapp/src/managers/VideoMgr';
