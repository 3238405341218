(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			window: $(window),
			document : $(document),
			html: $('html'),
			htmlFancyboxOpenedClass: 'html_fancybox_opened',
			owlQuickviewSel : ".js-quickview_content .js-owl_carousel",
			productVarReloadEvent : "product.variation.reloaded",
			carouselActiveItemSel : '.js-primary_image',
			carouselItemSel: '.b-owl_carousel-item',
			colorsCarousel: ".js-colors-carousel-quickview"
		};
	}
	
	function initializeEvents() {
		$cache.document.on('quickview.beforeShow', function(){
			app.owlcarousel.initCarousel($($cache.owlQuickviewSel));
			app.owlcarousel.initCarousel($($cache.colorsCarousel));
		});
		
		$cache.document.on($cache.productVarReloadEvent, function(event, data){
			if (data && data.mode === 'quickview') {
				var carousel = $($cache.owlQuickviewSel);
				app.owlcarousel.initCarousel(carousel);
				app.owlcarousel.initCarousel($($cache.colorsCarousel));
			}
		});
		
		$cache.document.on('translated.owl.carousel', $cache.owlQuickviewSel, function(){
			var $this = $(this);
			$this.find($cache.carouselItemSel).removeClass('active');
			$this.find($cache.carouselActiveItemSel).addClass('active');
		});

		$cache.document.on('fancybox.closed', function() {
			$cache.html.removeClass($cache.htmlFancyboxOpenedClass);
		});
	}
	
	app.components = app.components || {};
	app.components.quickview = app.components.quickview || {};
	app.components.quickview.custom = {

		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);