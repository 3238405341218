(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			fakeLoginLink: $('.js-fakelogin-link'),
			fakeLoginContainer: $('.js-fakelogin-link .js-login_dropdown'),
			fakeLoginCloseButton: $('.js-fakelogin-link .js-close_button'),
			checkoutContainer: $('.js-main_checkout'),
			classes: {
				dropdownActive: 'b-login_dropdown-active',
				minimized: 'h-minimized',
				linkActive: 'b-login_header-link-active',
				hidden: 'h-hidden'
			},
			selectors: {
				taxInfoButton: '.js-sale-tax-icon-custom',
				taxInfoCloseButton: '.js-sale-tax-info-close_button',
				taxInfoContent: '.js-sale-tax-content',
				taxContainer: '.js-sale-tax-container'
			}
		};
	}

	function initializeEvents() {
		if (app.device.isMobileView()) {
			$cache.fakeLoginLink.off();

			$cache.fakeLoginLink.click(function() {
				if ($cache.fakeLoginContainer.hasClass($cache.classes.minimized)) {
					$cache.fakeLoginContainer.removeClass($cache.classes.minimized).addClass($cache.classes.dropdownActive);
					$cache.fakeLoginLink.addClass($cache.classes.linkActive);
				} else {
					closeDropdown();
				}
			});

			$cache.fakeLoginCloseButton.click(function(e) {
				e.stopPropagation();
				closeDropdown();
			});

			$cache.fakeLoginContainer.click(function(e) {
				e.stopPropagation();
			});
		}

		initTaxInfoPopup();
	}

	function closeDropdown() {
		$cache.fakeLoginContainer.addClass($cache.classes.minimized).removeClass($cache.classes.dropdownActive);
		$cache.fakeLoginLink.removeClass($cache.classes.linkActive);
	}

	/**
	 * Initializes the tax info popup functionality.
	 */
	const initTaxInfoPopup = () => {
		const taxContainerNode = document.querySelector($cache.selectors.taxContainer);

		if (!taxContainerNode) {
			return;
		}

		const taxInfoButtonNode = taxContainerNode.querySelector($cache.selectors.taxInfoButton);
		const taxInfoContentNode = taxContainerNode.querySelector($cache.selectors.taxInfoContent);
		const taxInfoCloseButtonNode = taxContainerNode.querySelector($cache.selectors.taxInfoCloseButton);
		const hiddenClass = $cache.classes.hidden;

		/**
		 * Handles the click event on the tax info button.
		 * @param {Event} event - The opening event.
		 */
		const handleTaxInfoButtonClick = (event) => {
			event.stopPropagation();

			taxInfoContentNode.classList.toggle(hiddenClass);
		};

		/**
		 * Handles the click event on the tax info close button.
		 */
		const handleTaxInfoCloseButtonClick = () => {
			taxInfoContentNode.classList.add(hiddenClass);
		};

		taxInfoButtonNode.addEventListener('click', handleTaxInfoButtonClick);
		taxInfoCloseButtonNode.addEventListener('click', handleTaxInfoCloseButtonClick);
	};

	/*************** app.components.checkout.custom public object ***************/
	app.components = app.components || {};
	app.components.checkout = app.components.checkout || {};
	app.components.checkout.custom = {
		init() {
			initializeCache();
			initializeEvents();
		}
	}
})(window.app = window.app || {}, jQuery);
