(function(app, $) {
	const commonBannersNames = ['homepage', 'alcoholWarning'];

	function BannersMgr() {
		this.commonBanners = getCommonBanners();
		this.banners = getBanners();
	}

	BannersMgr.prototype.openAllCommonBanners = function() {
		for (const banner of this.commonBanners) {

			if (banner.isShouldBeOpened()) {
				banner.open();
			}
		}
	};

	BannersMgr.prototype.checkAndOpenBanner = function() {
		for (const banner of this.banners) {
			if (banner.isShouldBeOpened()) {
				banner.open();
				break;
			}
		}
	};

	const getCommonBanners = () => {
		const banners = [];

		for (const bannerName of commonBannersNames) {
			if (Object.prototype.hasOwnProperty.call(app.banners, bannerName)) {
				banners.push(app.banners[bannerName]);
			}
		}

		return banners;
	}

	const getBanners = () => {
		const banners = [];

		try {
			const bannersAppearanceOrder = JSON.parse(app.preferences.bannersAppearanceOrder);

			for (const bannerName of bannersAppearanceOrder) {
				if (Object.prototype.hasOwnProperty.call(app.banners, bannerName)) {
					banners.push(app.banners[bannerName]);
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn(`Failed to parse Banners Appearance Order. Error: ${e.message}`);
		}

		return banners;
	}

	app.bannersMgr = new BannersMgr();

	$(window).on('pageshow', function() {
		app.bannersMgr.openAllCommonBanners();
		app.bannersMgr.checkAndOpenBanner();
	});
})((window.app = window.app || {}), jQuery);
