(function(app, $){
	var $cache = {},
		configs = {};
	
	var contentNumber = 0,
		currentScrollPosition = [];
	
	function initializeConfigs(){
		configs = {
			numberToDisplay: 2
		};
	}
	
	function initializeCache(){
		var pageName = $('.js-journalpage-main').data('pagename');
		$cache = {
			window: $(window),
			document: $(document),
			slotWrappers: $("div[class^='journal-slot']"),
			journalContainer: '.js-journalpage-main',
			journalFooter: $('body.s-'+pageName+' .l-footer'),
			hiddenClass: 'h-hidden_minor',
			loadingClass: 'loading-slots',
			slotWrappersSelector: "div[class^='journal-slot']",
			errorNotifierClass: '.error_notifier',
			videoTagSel: '.js-slot-video',
			initedClass: 'js-initedVideo',
			tabletOrMobileDevice: app.device.isTabletUserAgent() || app.device.isMobileUserAgent(),
			loadMoreControlSel: '.js-load-next-control',
			loadMoreNextPageSel: '.js-load_next_page',
			categoriesListSel: '.js-categories_list',
			header: $('.js-header_main')
		};
		
		$cache.mobileOffset = $cache.tabletOrMobileDevice ? ((app.device.currentDevice() === 'mobile') ? 200 : (app.device.isAndroidOS() ? 150 : 70)) : 0;
	}
	
	function initializeDOM(){
		//in case the video is on the top, in purpose do not init it twice
		var videoBlock = $($cache.videoTagSel + ':not(".' + $cache.initedClass + '")');
		videoBlock.addClass($cache.initedClass);
		videoBlock.closest('div'+$cache.videoTagSel).addClass($cache.initedClass);
		if($cache.journalFooter.length){
			if($cache.slotWrappers.length > configs.numberToDisplay){
				markSections();
				checkViewPortSize();
			}
		}
		
		var infiniteLoads = $($cache.loadMoreControlSel).data('infinite-loads');
		if (infiniteLoads > 0) {
			var url = document.location.href,
				deffereds = [];
			for (var i = infiniteLoads; i > 0; i--) {
				deffereds.push($.ajax({
					url: app.util.appendParamToURL(url, 'page', i),
					data: { format: 'page-element' }
				}))
			}
			loadInfinite(deffereds, true);
		}
	}
	
	function checkViewPortSize(){
		var lastVisibleSlot = $($cache.slotWrappersSelector).not('.'+$cache.loadingClass).last(),
			slotTop = lastVisibleSlot.offset().top,
			slotBottom = slotTop + lastVisibleSlot.outerHeight(),
			viewPortTop = $cache.window.scrollTop(),
			viewPortBottom = viewPortTop + $cache.window.height();
		
		if(slotBottom < viewPortBottom){
			var slotIndex = contentNumber++;
			getRemoteContent($cache.slotWrappers.eq(slotIndex), true);
		}
		
	}
	
	function markSections(){
		contentNumber = contentNumber + configs.numberToDisplay;
		$cache.slotWrappers.eq(contentNumber - 1).nextAll($cache.slotWrappersSelector).addClass($cache.loadingClass);
		
		//hiding footer
		$cache.journalFooter.addClass($cache.hiddenClass);
	}
	
	function loadInfinite(deffereds, prepend) {
		$.when.apply($, deffereds).done(function(response) {
			if (response) {
				if(deffereds.length === 1){
					var args = [Array.prototype.slice.call(arguments)];
				} else {
					var args = Array.prototype.slice.call(arguments);
				}
				for(var j = 0; j < args.length; j++){
					if (prepend) {
						$($cache.categoriesListSel).prepend($(args[j][0]).filter('.b-category-item'));
					} else {
						$($cache.loadMoreControlSel).replaceWith(args[j][0]);
					}
				}
				$cache.window.scrollTop($($cache.categoriesListSel).find('.b-category-item[data-currentpage]:last').offset().top - $cache.header.height());
			}
		})
			.fail(function() {
				location.href = location.href;
			});
	}
	
	function initializeEvents(){
		if($cache.journalFooter.length && $cache.slotWrappers.length > configs.numberToDisplay){
			$cache.window.on('scroll.journal', function(){
				if($cache.window.scrollTop() + $cache.window.height() >= ($cache.document.height() - $cache.mobileOffset)){
					if($cache.tabletOrMobileDevice && currentScrollPosition.length){
						if(!($cache.window.scrollTop() + $cache.window.height() >= currentScrollPosition[currentScrollPosition.length - 1].topPositionSegment && 
								$cache.window.scrollTop() + $cache.window.height() <= currentScrollPosition[currentScrollPosition.length - 1].bottomPositionSegment)){
							revealContent();
						}
					}
					else{
						revealContent();
					}
				}
			});
			if($cache.tabletOrMobileDevice){
				$cache.window.resize(checkViewPortSize);
			}
		}
		
		$cache.document.on('click', $cache.loadMoreNextPageSel, function(){
			var $this = $(this);
			var deffereds = [$.ajax({
				url: $this.attr('href'),
				data: {
					format: 'page-element',
					position: $this.attr('data-position'),
					loaderbar: $this.attr('data-loaderbar'),
					subview: $this.attr('data-subview'),
					comparecgid: $this.attr('data-comparecgid'),
					subcategory: $this.attr('data-issubcategory'),
					viewtype: $this.attr('data-viewtype')
				}
			})];

			loadInfinite(deffereds);
			return false;
		});
		
		$cache.window.on('scroll resize', function(){
			var page = 1;
			var scrollTop = $cache.window.scrollTop();
			var url = document.location.href;
			var currentPage = app.util.getParamFromUrl(url, 'page') || 1;
			
			var $elements = $('.b-category-item[data-currentpage]');
			$.each($elements, function(e, el){
				if ($(el).offset().top < scrollTop + $cache.header.height() + 1) {
					page = $(el).data('currentpage');
				}
			});
			
			if (Number(page) !== Number(currentPage)) {
				url = app.util.removeParamFromURL(url, 'page');
				url = app.util.appendParamToURL(url, 'page', Number(page));
				history.replaceState(null, document.title, url);
			}
			
			var $loadMoreNextEl = $($cache.loadMoreNextPageSel);
			var canLoadMore = $cache.tabletOrMobileDevice && 
				$loadMoreNextEl.length && 
				(scrollTop + $(window).height() > $loadMoreNextEl.offset().top) &&
				!$loadMoreNextEl.data("isLoading");
			if (canLoadMore) {
				$loadMoreNextEl.data("isLoading", true);
				var deffereds = [$.ajax({
					url: $loadMoreNextEl.attr('href'),
					data: { format: 'page-element' }
				})];
				loadInfinite(deffereds);
			}
		});
	}
	
	function revealContent(){
		var endPointNumber = contentNumber + configs.numberToDisplay,
			mobileCorrection;
		if(endPointNumber > $cache.slotWrappers - 1){
			endPointNumber = $cache.slotWrappers.length - 1;
		}
		
		$cache.window.trigger('resize');
		
		for(var i = contentNumber; i<endPointNumber; i++){
			getRemoteContent($cache.slotWrappers.eq(i));
		}
		contentNumber = endPointNumber;
		if($cache.tabletOrMobileDevice){
			mobileCorrection = (app.device.currentDevice() === 'mobile') ? 200 : 0;
			currentScrollPosition.push({
				topPositionSegment: $cache.document.height() - $cache.mobileOffset,
				bottomPositionSegment: $cache.document.height() + mobileCorrection
			});	
		}
	}
	
	function getRemoteContent(currentSlot, checkViewPortFlag){
		var configBlock = currentSlot.children('.config_content-block'),
			assetIDs = configBlock.data('assetIds'),
			decoratorTemplate = configBlock.data('decoratorTemplate'),
			remoteContentObject,
			isTheEndOfDocument;
		
		$.ajax({
			url: app.util.appendParamsToUrl(app.urls.slotsCluster, {
				"assetIDs": assetIDs,
				"decoratorTemplate": decoratorTemplate
			}),
			method: "get",
			dataType: "html",
			beforeSend: function(){
				if(checkViewPortFlag == null){
					app.progress.show($cache.journalContainer);
				}
			},
			complete: function(){
				app.progress.hide();
			},
			success: function(remoteContent){
				currentSlot.removeClass($cache.loadingClass);
				configBlock.remove();
				remoteContentObject = $(remoteContent);
				if(!remoteContentObject.closest($cache.errorNotifierClass).data('error')){
					currentSlot.html(remoteContentObject);
					//init the video if the block exists
					$cache.window.trigger('storefrontvideo.init', [$cache.initedClass]);
					isTheEndOfDocument = monitorTheEndOfDocument();
					if(checkViewPortFlag != null && !isTheEndOfDocument){
						checkViewPortSize();
					}
				}
			}
		});
	}
	
	function monitorTheEndOfDocument(){
		if($($cache.slotWrappersSelector).not('.'+$cache.loadingClass).length === $cache.slotWrappers.length){
			app.progress.hide();
			$cache.journalFooter.removeClass($cache.hiddenClass);
			
			$cache.window.off('.journal');
			
			return true;
		}
	}
	
	app.components = app.components || {};
	app.components.loadmore = app.components.loadmore || {};
	app.components.loadmore.custom = {
			
			init: function(){
				initializeConfigs();
				initializeCache();
				initializeDOM();
				initializeEvents();
			}
	
	};
	
})(window.app = window.app || {}, jQuery);