(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			wishlistElement 		: {},
			header					: $("header"),
			document				: $(document),
			loginToggler: $('.js-login_dropdown-title.js-toggler'),
			carouselOwlSel: '.js-wishlist_dropdown-flyout .js-owl_carousel'
		};
	}
	function initializeHelpers() {
		app.components.account.fakelogin.show = function (){
			$cache.document.trigger("wishlist.notauthenticated");
		};
	}
	function initializeEvents() {
		$cache.document.on('wishlist.added', function() {
			if (app.page.ns !== 'checkout') {
				var carousel = $($cache.carouselOwlSel);
				app.owlcarousel.initCarousel(carousel);
			}

			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
		});

		$cache.document.on('wishlist.uploaded', function() {
			var carousel = $($cache.carouselOwlSel);
			app.owlcarousel.initCarousel(carousel);
		});

		$cache.document.on('wishlist.beforeadded', function(event, element) {
			$cache.wishlistElement = element;
		});

		$cache.document.on('wishlist.notauthenticated', function() {
			$cache.loginToggler.trigger("click", "wishlist");
		});
	}

	app.components = app.components || {};
	app.components.wishlist = app.components.wishlist || {};
	app.components.wishlist.custom = {
		// initializations
		init : function (params) {
			initializeHelpers();
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
