( function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			window: $(window),
			html: $('html, body'),
			header: $('header'),
			assetWrapper: $('.js-asset_body-wrap'),
			scrollDownBlock: $('.js-scroll-down_block'),
			lpSectionSel: '.js-section',
			productTileSel: '.js-product_tile',
			notifyMeLinkSel: '.js-notifyme_link',
			assetImagesSel: '.js-asset_body-section_imgs',
			assetImgSel: '.js-asset_body-section_img',
			assetImgLinkSel: '.js-asset_body-section_link'
		};
	}

	// Checks if we really need to initialize iPad "pageshow" listener
	function isInitPageShowListener() {
		var isHomePage = app.page && app.page.currentPage && (app.page.currentPage === 'homepage'),
			areImagesAvailable = $($cache.assetImagesSel).length && $($cache.assetImgLinkSel).length;

		return (isHomePage && areImagesAvailable);
	}

	function initializeEvents() {
		$cache.assetWrapper.on('mouseenter mouseleave', $cache.assetImagesSel, function(){
			var $this = $(this);
			$this.find($cache.assetImgSel).add($this.find($cache.assetImgLinkSel)).toggle(0);
		});

		//Force IPhone reload page, instead get page from cache.
		if (isInitPageShowListener()) {
			$(window).on('pageshow', function(event) {
				if (event.originalEvent.persisted) {
					window.location.reload();
				}
			});
		}

		$cache.scrollDownBlock.on('click', function(){
			var nextElement = $(this).closest($cache.lpSectionSel).next($cache.lpSectionSel).first();
			if(nextElement.length){
				$cache.html.animate({
					scrollTop: nextElement.offset().top - $cache.header.height()
				}, 500);
			}
		});

		$($cache.productTileSel).on('click', $cache.notifyMeLinkSel, app.product.openNotifyMePopup);
	}

	app.components = app.components || {};
	app.components.storefront = app.components.storefront || {};
	app.components.storefront.custom = {

		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);