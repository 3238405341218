(function(app, $) {

	var $cache = {},
		default_options = {
			type: 'ajax',
			wrapCSS : 'b-quickview-wrapper b-quickview-shopthelook',
			beforeShow: function () {
				$cache.document.trigger('shopthelook.beforeShow');
			},
			afterShow: function() {
				app.components.global.shopthelook.isOpened = true;
				$cache.document.trigger('shopthelook.opened');
			},
			beforeClose: function() {
				app.components.global.shopthelook.isOpened = false;
				$cache.document.trigger('shopthelook.beforeClose');
			},
			afterClose: function () {
				$cache.document.trigger('shopthelook.closed');
			}
		};

	function initializeCache(){
		$cache = {
			document : $(document),
			body: $('body'),
			window: $(window),
			shopTheLookBlockSel : '.js-shopthelook',
			shopTheLookContentSel : '.js-shopthelook_content',
			mainUrl : app.urls.shopthelookMain,
			singleProductUrl : app.urls.shopthelookProduct,
			owlSel : ".js-owl_carousel ",
			nextBtnSel : ".js-shopthelook-next",
			prevBtnSel : ".js-shopthelook-prev",
			activeClass : "active",
			prodAnchorSel : ".js-shopthelook_productanchor",
			activeProdAnchor : null,
			prodContainer : null,
			singleprodContainerSel : ".js-shopthelook-singleprod",
			currentGroup : 'default',
			quickviewSourceParam: 'quickview',
			overflowHiddenClass: 'h-overflow-hidden',
			componentGroups : {},
			productTileImageSel : '.js-producttile_image',
			thumbImgShopTheLook : '.js-shopthelook_productanchor',
			shopTheLookContent : '.js-shopthelook_content',
			quickviewWrapper : '.b-quickview-wrapper',
			thumbImg : 0,
			windowHeight : 0,
			bagClass: 'h-bag'
		};

		$cache.shopOwlSel = $cache.shopTheLookContentSel + ' ' + $cache.owlSel;

	}

	function initializePostEvents(){
		$($cache.prevBtnSel).on('click', prevStl);
		$($cache.nextBtnSel).on('click', nextStl);

		if (app.device.currentDevice() === 'desktop') {
			$($cache.prodAnchorSel).on('click', showTheProdView);
		}

		$cache.activeProdAnchor = $($cache.prodAnchorSel + "." + $cache.activeClass);
	}

	function initializeConfigs(params) {
		if (params) {
			$cache = $.extend({}, $cache, params);

			if(params.fancyboxSettings) {
				default_options = $.extend({}, default_options, params.fancyboxSettings);
			}
		}
	}

	function prevStl() {
		if($cache.currentIndex !== -1){
			var lnt = $cache.componentGroups[$cache.currentGroup].length-1;

			if($cache.currentIndex > 0 && lnt >= $cache.currentIndex){
				startSTLCarousel($cache.currentGroup, $cache.componentGroups[$cache.currentGroup][$cache.currentIndex-1]);
			}
			else{
				startSTLCarousel($cache.currentGroup, $cache.componentGroups[$cache.currentGroup][lnt]);
			}
		}
	}

	function nextStl() {
		if($cache.currentIndex !== -1){
			var lnt = $cache.componentGroups[$cache.currentGroup].length-1;

			if($cache.currentIndex < lnt){
				startSTLCarousel($cache.currentGroup, $cache.componentGroups[$cache.currentGroup][$cache.currentIndex+1]);
			}
			else{
				startSTLCarousel($cache.currentGroup, $cache.componentGroups[$cache.currentGroup][0]);
			}
		}
	}

	function startSTLCarousel(group, PID){
		$cache.currentGroup = group;
		$cache.currentIndex = $cache.componentGroups[group].indexOf(PID);

		$cache.document.trigger("toggle.hideall");
		showTheLookView(PID);
	}

	function calcHeightElement() {
		var thumbImgShopTheLook = $($cache.thumbImgShopTheLook);
		var shopTheLookContent = $($cache.shopTheLookContent);
		var quickviewWrapper = $($cache.quickviewWrapper);
		var thumbImg = $cache.thumbImg;
		var windowHeight = $cache.windowHeight;
		var wrapHeight = parseInt(quickviewWrapper.css('max-height'));
		var images = {
			'thumb' : false,
			'primary': false
		};

		function waitForImages() {
			if(images.thumb && images.primary) {
				windowHeight = $cache.window.height()*wrapHeight/100;
				thumbImg = thumbImgShopTheLook.parent().outerHeight();
				shopTheLookContent.find($cache.productTileImageSel).parent().css('max-height', windowHeight - thumbImg);
			}
		}

		function handleImageLoading(img, imageType){
			if (img.prop('complete') && !images[imageType]) {
				images[imageType]=true;
				waitForImages();
			} else {
				img.load(function(){
					images[imageType]=true;
					waitForImages();
				});
			}
		}

		thumbImgShopTheLook.find('img').each(function () {
			handleImageLoading($(this), 'thumb');
		});

		shopTheLookContent.find($cache.productTileImageSel).each(function () {
			handleImageLoading($(this), 'primary');
		});

		$($cache.shopOwlSel).one('owl.afterUpdate', waitForImages);

	}

	function initializeElements(container) {
		if(typeof container === 'undefined'){
			container = $cache.document;
		}
		$.each(container.find($cache.shopTheLookBlockSel), function(){
			var groupId = 'default',
				pids = [],
				$this = $(this);

			if($this.data('stl_attached') !== true){
				if(typeof $this.data('pids') === 'undefined'){
					return;
				}
				else{
					pids = $this.data('type') === 'shop' ? $this.data('look').split(',') : $this.data('pids').split(',');

					if(typeof $this.data('group-id') !== 'undefined' ){
						groupId = $this.data('group-id');
					}
				}

				if(!$cache.componentGroups.hasOwnProperty(groupId)){
					$cache.componentGroups[groupId] = [];
				}

				$.each(pids, function (key, pid) {
					if($cache.componentGroups[groupId].indexOf(pid) === -1){
						$cache.componentGroups[groupId].push(pid);
					}
				});

				$this.click(function (e) {
					e.preventDefault();

					startSTLCarousel.call(this, groupId, pids[0]);
				}.bind(this));

				$this.data('stl_attached', true);
			}
		});
	}

	function initializeEvents() {
		initializeElements();

		$cache.document.on('shopthelook.opened', function(){
			app.product.init(app.product.QUICKVIEW);
			initializePostEvents();
			$($cache.shopOwlSel).each(function () {
				app.owlcarousel.initCarousel($(this));
			});

			$cache.productContainer = $($cache.singleprodContainerSel);

			if ($cache.calcHeightElement) {
				calcHeightElement();
			}
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
		});

		$cache.document.on('dialog.opened', function() {
			$cache.body.addClass($cache.overflowHiddenClass);
		});

		$cache.document.on('fancybox.closed', function() {
			$cache.body.removeClass($cache.overflowHiddenClass);
		});
	}

	function showTheLookView(PID){
		if ($cache.showMobileTitle && app.device.isMobileView()) {
			var fancyBoxTitle;
			var $currentTargetEl = $(event.currentTarget);

			if ($currentTargetEl.hasClass($cache.bagClass)) {
				fancyBoxTitle = $currentTargetEl.next('h2').text();
			} else {
				fancyBoxTitle = $currentTargetEl.find('h2').text()
			}

			var customOptions = {
				title: fancyBoxTitle,
				afterClose: function() {
					$cache.document.trigger('shopthelook.closed');
				}
			}
			default_options = $.extend({}, default_options, customOptions);
		}

		var type = $('[data-pids=' + PID + ']').data('type');

		var pdpUrl = app.util.appendParamsToUrl($cache.mainUrl, {
			/**
			 * We set quickview as source to inherit its custom template when changing variation by clicking the swatches
			 */
			source: $cache.quickviewSourceParam,
			pid: PID,
			type: type,
			includeAPMs: app.util.getConfig('product.apms.shopthelook', false)
		});

		app.fancybox.open(pdpUrl, default_options);
	}


	function showTheProdView(e){
		e.preventDefault();

		if($cache.activeProdAnchor !== $(this)){
			if($cache.activeProdAnchor){
				$cache.activeProdAnchor.removeClass($cache.activeClass);
			}
			$(this).addClass($cache.activeClass);
			$cache.activeProdAnchor = $(this);

			var PID = $(this).data('pid');
			$.ajax({
				method: "POST",
				url: $cache.singleProductUrl,
				data: {
					pid: PID,
					source: $cache.quickviewSourceParam
				}
			}).done(function( data ) {
				$cache.productContainer.html(data);
				app.product.init(app.product.QUICKVIEW);

				$cache.productContainer.find($cache.owlSel).each(function () {
					app.owlcarousel.initCarousel($(this));
				});

				document.dispatchEvent(new CustomEvent('lazyload-reinit'));
				$cache.document.trigger('shopthelook.opened');
			});
		}
	}

	/*************** app.components.global.shopthelook public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.shopthelook = {
		init: function(params) {
			initializeCache();
			initializeConfigs(params);
			initializeEvents();
		},
		isOpened : false,
		initializeElements: initializeElements
	};
})(window.app = window.app || {}, jQuery);
