(function (app, $) {
	
	var $cache = {};
	
	function initializeCache() {
		$cache = {
			filterSelector : '.js-filter',
			filterTitleSelector : '.js-filter .js-filter-title'
		};
		$cache.filterTitles = $($cache.filterTitleSelector);
	}
	
	function initializeEvents() {
		$cache.filterTitles.on('click', function() {
			$(this).closest($cache.filterSelector).toggleClass('active');
		});
	}
	
	/**
	 * @namespace app.components.search.filters.custom public object
	 **/
	app.components = app.components || {};
	app.components.searchfilters = app.components.searchfilters || {};
	app.components.searchfilters.custom = {
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
	
}(window.app = window.app || {}, jQuery));
