(function (app, $) {
	function initializeCache() {  
		$cache = {
			document : $(document),
			body: $('body'),
			window: $(window),
			countryDetectionBlockSel : '.js-country-detection-block',
			firstVisitBannerSel: '.js-first-visit-banner',
			countryDetectionOverlaySel : '.js-country-detection-overlay',
			closeCountryDetectionBlockBtn : $('.js-country-detection-block_close'),
			closeCountryDetectionOverlayBtnSel : '.js-country-detection-overlay_close',
			hidden : 'h-hidden',
			loadModalCountryLangSelectorSel: '.js-country-detection-block_change-link',
			bodyClassOnSingleCountryDetectionBlockOpened: 'm-body-single-country-detection',
			newsletterpopupMailFieldSel: '.js-newsletterpopup-mail-field',
			newsletterpopupMailFieldFocusedClass: 'm-input-focus-in',
			newsletterpopupMailFieldUnFocusedClass: 'm-input-focus-out',
			newsletterpopupSubmitBtnSel: '.js-popup_submit',
			newsletterpopupWrapperSel: '.js-newsletter_popup-wrapper',
			countryDetectionOverlayScrollAreaSel: '.js-country-detection-overlay_scroll_area',
			countryDetectionOverlayCountryLinkSel: '.js-country-detection-overlay_country-link',
			countryDetectionBlockCurrentCountrySel: '.js-country-detection-block_current-country',
			countryDetectionBlockAssetSel: '.js-country-detection-block_asset',
			bodyClassOnCountryDetectionOverlayOpened: 'm-body-country-detection-overlay-opened',
			closingNewsletterPopupElementsSel: '.js-header_container .js-toggler, .js-header_menu_toggle',
			clickOnBlur: false,
			firstVisitBannerSel: '.js-first-visit-banner',
			firstVisitBannerAnimatedClass: 'm-first-visit-banner-animated'
		}
	}

	function handleSingleCountryDetectionBlockClick() {
		$cache.document.on('click touchstart', closeSingleCountryDetectionBlock);
		$($cache.closingNewsletterPopupElementsSel).on('click', closeSingleCountryDetectionBlock);

		$cache.document.on('click touchstart', $cache.countryDetectionBlockSel + ', ' + $cache.firstVisitBannerSel + ', ' + $cache.countryDetectionOverlaySel,  function(e) {
			e.stopPropagation();
		});
	}

	function initializeEvents() {
		var resizeTimer;
		
		if(app.preferences.enableNewsletterPopup) {
			//Focus-blur events of email field in NL popup
			$cache.document.on('focus', $cache.newsletterpopupMailFieldSel, function() {
				$cache.clickOnBlur = false;
				$(this).closest($cache.newsletterpopupWrapperSel).addClass($cache.newsletterpopupMailFieldFocusedClass).removeClass($cache.newsletterpopupMailFieldUnFocusedClass);
			});
			
			$cache.document.on('mousedown touchstart', $cache.newsletterpopupSubmitBtnSel + ', '  + $cache.loadModalCountryLangSelectorSel, function() {
				$cache.clickOnBlur = true;
			});
			
			$cache.document.on('mouseup touchend', $cache.loadModalCountryLangSelectorSel, function() {
				showCountryDetectionOverlay();
			});
			
			$cache.document.on('blur', $cache.newsletterpopupMailFieldSel, function() {
				if(!$cache.clickOnBlur) {
					$(this).closest($cache.newsletterpopupWrapperSel).addClass($cache.newsletterpopupMailFieldUnFocusedClass).removeClass($cache.newsletterpopupMailFieldFocusedClass);
				}
			});
			
		} else {
			$cache.document.trigger('singlecountrydetection.open');

			if(app.page.currentPage !== 'homepage') {
				return false;
			}

			if (!$.cookie(app.configs.hideCountryDetectionBlock.keyName)) {
				$($cache.countryDetectionBlockSel).removeClass($cache.hidden);
			}
			
			$cache.body.addClass($cache.bodyClassOnSingleCountryDetectionBlockOpened);
			setSingleCountryDetectionBottomPosition();
			
			$cache.document.on('cookie.closed', function () {
				$($cache.countryDetectionBlockSel).css({
					'bottom': ''
				});
			});

			handleSingleCountryDetectionBlockClick();
		}
		
		$cache.document.on('click', $cache.loadModalCountryLangSelectorSel, showCountryDetectionOverlay);
		
		$cache.closeCountryDetectionBlockBtn.on('click', closeSingleCountryDetectionBlock);
		$cache.document.on('click', $cache.closeCountryDetectionOverlayBtnSel, closeCountryDetectionOverlay);
		
		$cache.document.on('click', $cache.countryDetectionOverlayCountryLinkSel, function(e) {
			var selectedCountry = $.trim($(e.target).text());

			$($cache.countryDetectionBlockCurrentCountrySel).text(selectedCountry);
			$($cache.countryDetectionBlockAssetSel).hide();
			closeCountryDetectionOverlay();
		});
		
		$cache.window.on('resize', function() {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(function() {
				if(!app.preferences.enableNewsletterPopup) {
					setSingleCountryDetectionBottomPosition();
				}
			}, 50);
		});
	}
	
	function setSingleCountryDetectionBottomPosition() {
		if($($cache.firstVisitBannerSel) && $($cache.firstVisitBannerSel).length) {
			$($cache.countryDetectionBlockSel).css({
				'bottom': $($cache.firstVisitBannerSel).outerHeight() + 'px'
			});
		}
	}
	
	function closeSingleCountryDetectionBlock() {
		$cache.body.removeClass($cache.bodyClassOnSingleCountryDetectionBlockOpened);
		$($cache.countryDetectionBlockSel).addClass($cache.hidden);

		$.cookie(app.configs.hideCountryDetectionBlock.keyName, true, {path: '/'});

		$cache.document.trigger('countrydetectionblock.closed');
	}
	
	function showCountryDetectionOverlay() {
		$cache.body.addClass($cache.bodyClassOnCountryDetectionOverlayOpened);
		
		if(app.preferences.enableNewsletterPopup) {
			$cache.clickOnBlur = false;
		}
		
		//Init custom scroll for country list
		if (!app.device.isMobileView()) {
			$cache.document.trigger('flyout.reload', {
				wrapper: $($cache.countryDetectionOverlayScrollAreaSel)
			});
		}
	}
	
	function closeCountryDetectionOverlay() {
		$cache.body.removeClass($cache.bodyClassOnCountryDetectionOverlayOpened);
		$cache.clickOnBlur = false;
		$($cache.newsletterpopupWrapperSel).removeClass($cache.newsletterpopupMailFieldFocusedClass);
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.countrydetectionblock = {
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
