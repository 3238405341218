(function (app, $) {
	var $cache = {};
	
	function initializeCache() {
		$cache = {
			document: $(document),
			productShareTextSelector : '.js-product-share-text',
			productShareSel : '.js-product-share',
			hoverClass: 'b-product_share-tablet',
			shareIconsContainerSel : '.b-product_share-content'
		};
	}

	function initializeEvents() {
		initShare();

		$cache.document.on('changeVariation.changed', function () {
			initShare();
		});
	}

	function initShare() {
		$cache.productShareText = $($cache.productShareTextSelector);
		$cache.productShare = $($cache.productShareSel);
		$cache.shareIconsContainer = $($cache.shareIconsContainerSel);

		$cache.shareIconsContainer.hide();

		//emulate tablet onhover effect
		if (app.device.isTabletUserAgent()) {
			$cache.productShareText.on('touchstart', function(e) {
				e.preventDefault();
				e.stopPropagation();
				$cache.productShare.addClass($cache.hoverClass);
			});

			$cache.document.on('touchstart', function(e) {
				if (!$(e.target).closest($cache.productShareSel)[0]) {
					$cache.productShare.removeClass($cache.hoverClass);
				}
			});

			$cache.document.on('social.clicked', function(e) {
				$cache.productShare.removeClass($cache.hoverClass);
			});
		}

		$cache.productShareText.on('mouseenter', function(){
			$cache.productShareText.hide();
			$cache.shareIconsContainer.show();
		});

		$cache.productShare.on('mouseleave', function(){
			$cache.shareIconsContainer.hide();
			$cache.productShareText.show();
		});

		if(app.device.isMobileView()) {
			$cache.document.ready(function(){
				$cache.shareIconsContainer.show();
			})
		}
	}

	/*************** app.components.social.custom public object ***************/
	app.components = app.components || {};
	app.components.social = app.components.social || {};
	app.components.social.custom= {
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
