/**
 * Components specific config
 **/

( function(app, $) {

	var settings =	{};
	
	if (app.device.currentDevice() === 'mobile') {
		var mobile_settings = {
			pdp_last_visited: {
				lazy : true,
				wrap : "circular",
				visibleItems: 2,
				vertical: false,
				controls: {
					enable: true
				}
			}
		};
		settings = $.extend(true, settings, mobile_settings);
	}

	/*************** app.jcarousel.settings ***************/
	app.jcarousel = app.jcarousel || {};
	app.jcarousel.settings = app.jcarousel.settings || {};
	app.jcarousel.settings = $.extend(true, app.jcarousel.settings, settings);

}(window.app = window.app || {}, jQuery));