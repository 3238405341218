(function(app, $) {
	function PasswordReset() {
		app.popups.Popup.call(this, 'PasswordReset');
	}

	PasswordReset.prototype = Object.create(app.popups.Popup.prototype);
	PasswordReset.prototype.constructor = app.popups.Popup;

	PasswordReset.prototype.open = function(params) {
		this.variant = (params && params.variant) || 'primary';
		this.isOpened = true;
		const popup = this;

		fetchForm.call(this, params.layoutURL).then((formHTML) => {
			if (popup.isOpened) {
				popup.markup = getMarkup();
				app.popups.Popup.prototype.open.call(popup, params);

				const formWrapperElement = popup.$markup.find('.js-popup-form')[0];

				formWrapperElement.setAttribute('data-variant', popup.variant);
				formWrapperElement.innerHTML = formHTML;
				formWrapperElement.querySelector('form').dataset.source = 'customPopup';
				formWrapperElement.querySelector('form').dataset.variant = popup.variant;
				app.popups.Popup.prototype.setHeightCSSVariable.call(this);
				app.validator.init();
				app.progress.hide();
				this.$markup.find('.js-popup-close-button').remove();
				this.$markup.find('.js-reset_password-submit').on('click', (e) => onSubmitButtonClick(e));
			}
		}).catch(() => {
			popup.close();
		});
	};

	PasswordReset.prototype.initMarkup = function() {
		app.progress.show(this.$markup);

		app.popups.Popup.prototype.initMarkup.call(this);
	};

	function getMarkup() {
		return app.util.renderTemplate(document.getElementById('passwordResetPopupTemplate').innerHTML);
	}

	function fetchForm(layoutURL) {
		return new Promise((resolve, reject) => {
			if (this.form) {
				return resolve(this.form);
			}

			$.ajax({
				url: app.util.ajaxUrl(layoutURL)
			}).done((response) => {
				this.form = response;

				resolve(this.form);
			}).fail(() => {
				reject();
			});
		});
	}

	function onSubmitButtonClick(e) {
		e.preventDefault();

		var $form = $(e.target).closest('form');

		$form.validate();

		if ($form.valid()) {
			$form.submit();
		}
	}

	app.popups.PasswordReset = new PasswordReset();
})((window.app = window.app || {}), jQuery);
