(function(app, $) {
	var $cache = null;

	const iframeDefaults = {
		class: 'js-product-vimeo b-product-vimeo-iframe',
		frameborder: 0,
		width: '100%',
		height: '100%'
	};

	const videoDefaults = {
		autoplay: 1
	};

	function initializeCache() {
		$cache = {
			document: $(document),
			quickViewContainerSel: '.js-quick_view_main',
			quickViewContainerClass: 'js-quick_view_main',
			vimeoThumbnailSel: '.js-thumbnail-vimeo',
			colorsPickerWrapperSel: '.js-variation-colors_wrap',
			vimeoThumbnailInColorSel: '.js-vimeo-thumb-color',
			simpleVimeoContainerSelector: '.js-vimeo-container',
			simpleVimeoPlayerContainerSelector: '.js-vimeo-player',
			imagesContainerSelector: '.js-container_main_image'
		};
	}

	function initializeDom() {
		initAnchorsInSwatches();
	}

	function initializeEvents() {
		$cache.document.on('quickview.opened', function() {
			var $quickView = $($cache.quickViewContainerSel);
			$cache.document.trigger('init.vimeo.thumbnails', {
				thumbnails: $quickView.find($cache.vimeoThumbnailSel)
			});
			$cache.document.trigger('grid-update', {
				container: $quickView
			});
			initAnchorsInSwatches($quickView);
		});

		$cache.document.on('changeVariation.changed', function(e, params) {
			if (params && params.container) {
				initAnchorsInSwatches(params.container);
			}
		});
	}

	function simpleVimeoInit(playerElement, options) {
		const vimeoPlayer = getPlayer({
			id: playerElement.data('vimeo-video-id'),
			queryParams: {
				autoplay: options?.autoplay ? 1 : 0,
				autopause: options?.autopause ? 1 : 0,
				muted: options?.muted ? 1 : 0,
				loop: options?.loop ? 1 : 0,
				background: options?.background ? 1 : 0
			},
			iframe: {
				allow: 'autoplay'
			}
		});

		playerElement.empty();

		playerElement.append(vimeoPlayer.element);

		$($cache.imagesContainerSelector).on('scroll', function(e) {
			app.util.throttle(vimeoContainerScrollHandler(e, vimeoPlayer), 1);
		});
	}

	function vimeoContainerScrollHandler(e, vimeoPlayer) {
		const currentScrollPosition = e.target.scrollTop;
		const playerElementPosition = $($cache.simpleVimeoContainerSelector)?.offset().top;

		if(currentScrollPosition > playerElementPosition) {
			vimeoPlayer.play();
			$($cache.imagesContainerSelector).off('scroll');
		}
	}

	function getPlayer(parameters) {
		const queryParams = parameters.queryParams || {};
		const params = $.param({ ...videoDefaults, ...queryParams });
		const iframeExtendedParams = parameters.iframe || {};

		const iframeParams = {
			...iframeDefaults,
			...iframeExtendedParams,
			id: `video-frame-${parameters.id}`,
			src: `https://player.vimeo.com/video/${parameters.id}?${params}`
		};

		const iframe = $('<iframe>', iframeParams);

		return new Vimeo.Player(iframe);
	}

	function initAnchorsInSwatches(container) {
		var colorSwatches = null;
		if (container) {
			colorSwatches = container
				.find($cache.colorsPickerWrapperSel)
				.find($cache.vimeoThumbnailInColorSel);
		} else {
			colorSwatches = $($cache.colorsPickerWrapperSel)
				.find($cache.vimeoThumbnailInColorSel);
		}

		const playerElement = $($cache.simpleVimeoPlayerContainerSelector).closest($cache.simpleVimeoPlayerContainerSelector);
		const config = app.util.getConfig('product.pdp.vimeo.settings');

		$cache.document.trigger('init.vimeo.thumbnails', {
			thumbnails: colorSwatches,
			dataProp: 'vimeoThumbnailSrc'
		});

		if (playerElement.length && !app.isMobileView()) {
			simpleVimeoInit(playerElement, config);
		}
	}

	app.components = app.components || {};
	app.components.video = app.components.video || {};
	app.components.video.custom = {
		init: function() {
			initializeCache();
			initializeDom();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
