(function(app, $) {
	var $cache = {};
	var closeTimer, openTimer;
	var openNavDeviceEvent = isTabletView() ? 'click' : 'mouseenter';
	var closeNavDeviceEvent = isTabletView() ? 'click' : 'mouseleave';
	var configuration = {
		// Delay before menu opened recommended value 200ms
		openDelay: undefined,
		// Delay before menu will close after mouse leave
		closeDelay: undefined,
		// Run function to get default opened menu item
		// this will have effect when page open and also when menu close
		getOpenByDefault: false,
		// Depth-of-menu restriction, should be number of max opens menu items.
		depthRhestriction: false,
		// Depth-of-menu restriction on tablet, should be number of max opens menu items.
		depthRhestrictionTablet: false,
		// Add possibility add special classes for menu active level.
		// Each class add for each level f.e. ['active-level-1', false, 'active-level-3'] or ['active-level-1']
		activeLevelClass: false,
		// Level to prevent from transfer on first <a> click on tablet
		tabFirstClickPrevMenuLev: 1
	};
	// this attributes control menu state
	var menuObject = {
		currentPositionArr: [],
		// Menu position string, which will opens by default when "mouseleave" for example "2-3"
		openByDefault: undefined,
		//This value hardcoded to code simplification, mean about it like infinity.
		//In face this is mean that you may have max 10 times the nesting menu.
		depthRhestriction: 10,
		//Last clicked prevented level according to configuration.tabFirstClickPrevMenuLev option
		tabLastClickedElement: undefined
	};
	var mcurrentClassDeleted = false;

	function initParams(params) {
		if (params) {
			$.extend(configuration, params);

			// Get default opened menu from template
			if (isTabletView()) {
				menuObject.depthRhestriction = configuration.depthRhestrictionTablet || menuObject.depthRhestriction;
			} else {
				menuObject.depthRhestriction = configuration.depthRhestriction || menuObject.depthRhestriction;
			}
		}
	}

	function setDefaultOpenMenu() {
		var $preselectedMenuItem = $cache.navigationMenu.find($cache.preselectedCategorySel);
		var cookieLandingPage = $.cookie('usr-landingpage');
		var cookiePreselectedMenuItem = cookieLandingPage && $('[data-category-id="' + cookieLandingPage + '"]');
		if ($preselectedMenuItem.length) {
			// This case work for PLP & PDP pages
			setPreselectedElement($preselectedMenuItem.first());
		} else if (cookieLandingPage && cookiePreselectedMenuItem.length) {
			// This case work for Customer service pages and pages without category related content
			setPreselectedElement(cookiePreselectedMenuItem.first());
		}
	}

	function setPreselectedElement($element) {
		menuObject.openByDefault = $element.data('position').toString();

		if (isTabletView()) {
			menuObject.tabLastClickedElement = $element.get(0);
		}
	}

	function initializeCache() {
		$cache = {
			window: $(window),
			document: $(document),
			body: $('body'),
			navigationMenu: $('.js-main_navigation'),
			homepageHeaderOverlay: $('.js-homepage_header-overlay'),
			pdNewLabel: '.js-pd_new-label',
			menuCategoryItemSel:
				'.js-menu_category-item, .js-menu_category-level_2-item, .js-menu_category-level_3-item, .js-menu_category-level_4-item',
			menuSubcategoryItemSel:
				'.js-menu_category-level_2-item, .js-menu_category-level_3-item, .js-menu_category-level_4-item',
			menuCategoryItem: $('.js-menu_category-item'),
			activeMenuCategoryItemSel : '.js-preselected-category',
			menuSubcategoryWrapperSel: '.js-menu_subcategory_wrapper',
			preselectedCategorySel: '.js-preselected-category',
			menuBackSel: '.b-menu_category-item--hovered .b-menu_category-link_container',
			hideCls: 'h-hidden',
			transparentGreyClass: 'transparent-grey',
			mcurrentClass: 'm-current',
			header: $('header'),
			headerTransparent: $('.js-transparent_header'),
			lastViewWasTablet: isTabletView()
		};
	}

	function toggleOverlay(action) {
		if (app.device.currentDevice() === 'desktop') {
			$cache.homepageHeaderOverlay[action]($cache.hideCls);
			$cache.body[action]($cache.transparentGreyClass);
		}
	}

	function hideMenu(timeout) {
		clearTimeout(closeTimer);
		closeTimer = setTimeout(function() {
			if (menuObject.openByDefault) {
				showConcurrentMenuTree(menuObject.openByDefault);
			} else {
				hideFromLastElementToLevel(0);
				toggleOverlay('removeClass');
			}
		}, timeout);
	}

	function showConcurrentMenuTree(newPositionData) {
		if (newPositionData) {
			var newPositionArr = newPositionData.toString().split('-');
			var changeIndex;
			var deepofMenu =
				menuObject.depthRhestriction < newPositionArr.length ? menuObject.depthRhestriction : newPositionArr.length;
			var i, length;

			toggleOverlay('addClass');

			for (i = 0, length = menuObject.currentPositionArr.length; i < length; i++) {
				if (menuObject.currentPositionArr[i] !== newPositionArr[i]) {
					changeIndex = i;
					break;
				}
			}

			if (typeof changeIndex !== 'undefined') {
				//Hide not needed elements of menu
				hideFromLastElementToLevel(changeIndex);
			}

			if (newPositionArr.length > menuObject.currentPositionArr.length && newPositionArr.length <= deepofMenu) {
				//Show needed elements
				changeIndex = changeIndex || menuObject.currentPositionArr.length;
				for (i = changeIndex, length = deepofMenu; i < length; i++) {
					menuObject.currentPositionArr.push(newPositionArr[i]);
					openMenuElement(menuObject.currentPositionArr.join('-'), menuObject.currentPositionArr.length);
				}
			}
		}
	}

	function openMenuElement(dataValue, menuLevel) {
		var menuItem = $('[data-position="' + dataValue + '"]');
		var itemWrapperContainer = menuItem.children($cache.menuSubcategoryWrapperSel);
		if (itemWrapperContainer.length) {
			configuration.activeLevelClass &&
				configuration.activeLevelClass[menuLevel - 1] &&
				menuItem.addClass(configuration.activeLevelClass[menuLevel - 1]);
			showMenuItem(itemWrapperContainer);
		}

		adjustNewLabelBlock();
	}

	/**
	 * Adjusting new label width for multiple lines.
	 */
	const adjustNewLabelBlock = () => {
		const nodes = document.querySelectorAll($cache.pdNewLabel);

		for (const { parentElement: node } of nodes) {
			node.style.display = 'inline';

			/**
			 * @type {DOMRect}
			 */
			const { width } = node.getBoundingClientRect();

			if (width) {
				node.style.width = `${width}px`;
			}

			node.style.display = 'flex';
		}
	};

	function hideFromLastElementToLevel(level) {
		//level mean index element in menuObject array
		for (var i = menuObject.currentPositionArr.length; i > level; i--) {
			hideMenuElement(menuObject.currentPositionArr.join('-'), menuObject.currentPositionArr.length);
			menuObject.currentPositionArr.pop();
		}
	}

	function hideMenuElement(dataValue, menuLevel) {
		var menuItem = $('[data-position="' + dataValue + '"]');
		var itemWrapperContainer = menuItem.children($cache.menuSubcategoryWrapperSel);
		if (itemWrapperContainer.length) {
			configuration.activeLevelClass &&
				configuration.activeLevelClass[menuLevel - 1] &&
				menuItem.removeClass(configuration.activeLevelClass[menuLevel - 1]);
			hideMenuItem(itemWrapperContainer);
		}
	}

	function showMenuItem(element) {
		element.show();
		element.scrollTop(0);
	}

	function hideMenuItem(element) {
		element.hide();

	}

	function isAlreadyOpened(positionId) {
		var testData = menuObject.currentPositionArr.join('-');
		return (
			testData.indexOf(positionId) === 0 &&
			(testData[positionId.length] === '-' || testData[positionId.length] === undefined)
		);
	}

	/**
	* @function
	* @description Checks if the viewport is in tablet resolution.
	* This function reads a CSS custom property (`--isTabletResolution`) to determine
	* if the current viewport is in tablet view.
	* @returns {boolean} True if in tablet resolution, false otherwise.
	*/

	function isTabletView() {
		return getComputedStyle(document.documentElement).getPropertyValue('--isTabletResolution') === 'true';
	}

	function initializeDOM() {
		if (typeof menuObject.openByDefault === 'undefined') {
			toggleOverlay('removeClass');
		}
	}

	function initializeEvents() {
		$cache.window.on('resize', app.util.debounce(function() {
			var currentViewIsTablet = isTabletView();

			if ($cache.lastViewWasTablet !== currentViewIsTablet) {
				window.location.reload();
			}

			$cache.lastViewWasTablet = currentViewIsTablet;
		}, 500));

		$cache.navigationMenu
			.on(openNavDeviceEvent, $cache.menuCategoryItemSel, function(event) {
				event.stopPropagation();
				clearTimeout(closeTimer);
				//Get position data
				var position = $(this).data('position');
				if (position) {
					position = position.toString();
					// Show menu
					if (!isAlreadyOpened(position)) {
						if (configuration.openDelay) {
							clearTimeout(openTimer);
							openTimer = setTimeout(function() {
								showConcurrentMenuTree(position);
							}, configuration.openDelay);
						} else {
							showConcurrentMenuTree(position);
						}
					}
				}
			})
			.on(openNavDeviceEvent, $cache.menuBackSel, function(event) {
				event.stopImmediatePropagation();
				event.preventDefault();
				hideFromLastElementToLevel(0);
			})
			.on(closeNavDeviceEvent, function() {
				hideMenu(configuration.closeDelay);
			});

		//Prevent open link for menu from first click
		if (isTabletView()) {
			$cache.navigationMenu.on('click', $cache.menuCategoryItemSel, function(event) {
				if (!mcurrentClassDeleted) {
					$cache.navigationMenu.find($cache.activeMenuCategoryItemSel).removeClass($cache.mcurrentClass);
					mcurrentClassDeleted = true;
				}

				var position = $(this).data('position');
				if (
					position &&
					position.toString().split('-').length <= configuration.tabFirstClickPrevMenuLev &&
					!event.currentTarget.isEqualNode(menuObject.tabLastClickedElement)
				) {
					menuObject.tabLastClickedElement = event.currentTarget;
					return false;
				}
			});
		}
	}

	/**
	 * @namespace app.components.global.categoryflyout public object
	 **/
	app.components = app.components || {};
	app.components.categoryflyout = app.components.categoryflyout || {};
	app.components.categoryflyout.custom = {
		// initializations
		init: function(params) {
			initializeCache();
			initParams(params);
			initializeDOM();
			initializeEvents();
			menuObject.openByDefault && showConcurrentMenuTree(menuObject.openByDefault);
		}
	};
})((window.app = window.app || {}), jQuery);
