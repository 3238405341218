(function (app, $) {
	var $cache = {};

	function initializeCache(config) {
		$cache = {
			document: $(document),
			window: $(window),
			body: $('body'),
			header: $('header'),
			headerSel: 'header',
			main: $('main'),
			mainHeader: $('.js-header_main'),
			headerIcons: $('.js-search-icon, .js-mini_cart-title'),
			leftHeaderNavigation: $('.l-main_navigation'),
			menuButton: $('.js-vertical_menu-button'),
			menuCategoryLvl3: $('.b-menu_category-level_3'),
			tabletHeaderMenuNav: $('.tablet-header-menu-nav'),
			promoBanner: $('.js-header-promo-container'),
			promoBannerHeight: 0,
			closePromoButton: $('.js-header-promo-close'),
			productDetailsWrapper: $('.js-product-details-wrapper'),
			refinementsBlock: $('.js-refinements-sticky'),
			breadcrumbsBlock: $('.js-breadcrumbs'),
			promoBannerClass: 'with-promo',
			hMinimizedClass_hHiddenClass: 'h-minimized h-hidden',
			owlCarouselSel: '.js-owl_carousel',
			scrolledHeaderCssClass: config.scrollConfig.cssClass,
			scrolledHeaderBreakPoint: config.scrollConfig.breakPoint,
			menuActiveClass: 'm-active',
			hHiddenClass: 'h-hidden',
			stickyKitStuckSel: 'is_stuck',
			fixedLogoSel: '.js-outer-logo-wrapper',
			fixedServiceMenuSel: '.js-service_menu-wrapper',
			disablePaddingManagement: config.disablePaddingManagement,
			promoBannerCookieName: app.preferences.promoBannerCookieName,
			promoHideTimeout: 1 * 60 * 60 * 1000, // Hours * minutes * seconds * milliseconds
			headerDropdown: $('.js-header-dropdown'),
			headerExpandedClass: 'm-dropdown-opened', //For transparent header with midnight.js effect
			menuOpened: 'm-menu_opened',
			activeLvl2CategorySel: '.js-menu_category-level_2-item--current',
			minicartTitleSel: '.js-mini_cart-title',
			minicartQuantityValueSel: '.js-minicart-quantity_value',
			wishlistTitleSel: '.js-wish_list-title',
			wishlistQuantityValueSel: '.js-wishlist-quantity_value',
			wishlistEmptyClass: 'b-wishlist_empty'
		};

		$cache.topOffsetForFixedElements =  parseInt($cache.mainHeader.css("margin-bottom"), 10);

		$cache.event = {
			headerChange: 'header.change',
			toggleHideall: 'toggle.hideall'
		};
	}

	function isHeaderDropdownOpened(headerDropdowns) {
		var dropdownOpened = false;

		headerDropdowns.each(function () {
			if($(this).height()) {
				dropdownOpened = true;
				return false; //break
			}
		});

		return dropdownOpened;
	}

	function adaptBodyPadding() {
		if (!$cache.disablePaddingManagement) {
			var headerHeight = $cache.mainHeader.height();

			$cache.body.css('padding-top', headerHeight + 'px');
		}

		//Remove extra header border when header's height = 0
		if(app.util.getConfig('navigation.vertical')) {
			if(isHeaderDropdownOpened($cache.headerDropdown)) {
				$cache.header.addClass($cache.headerExpandedClass);
			} else {
				$cache.header.removeClass($cache.headerExpandedClass);
			}
		}
	}

	function initPromoBanner() {
		if( !$.cookie($cache.promoBannerCookieName) ) {
			$cache.promoBanner.removeClass($cache.hMinimizedClass_hHiddenClass);
			$cache.productDetailsWrapper.addClass($cache.promoBannerClass);
		} else {
			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);
		}

		$cache.promoBannerHeight = parseInt($cache.promoBanner.outerHeight());

		$cache.closePromoButton.on('click', function(){
			var expiration = new Date();

			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);
			$cache.productDetailsWrapper.removeClass($cache.promoBannerClass);

			expiration.setTime($cache.promoHideTimeout + expiration.getTime());
			$.cookie($cache.promoBannerCookieName, true, { expires: expiration, path: '/' });

			$cache.header.trigger($cache.event.headerChange, { height: $cache.promoBannerHeight });
		});
	}

	function setTopOffsetToFixedElement(el) {
		var topOffset = $cache.header.height() + $cache.topOffsetForFixedElements;
		el.animate({
			"top" :  topOffset + "px"
		}, 0);
	}

	function setFixedTopOfsset (){
		setTopOffsetToFixedElement($($cache.leftHeaderNavigation).eq(0));
		setTopOffsetToFixedElement($($cache.fixedLogoSel).eq(0));
		setTopOffsetToFixedElement($($cache.fixedServiceMenuSel).eq(0));
	}

	function initializeEvents() {
		$cache.headerIcons.on('click', function (){
			$cache.header.trigger('header.change', {
				headerHeight: $cache.header.height()
			});
		});

		if($cache.mainHeader.length && $cache.mainHeader.css('position') === 'fixed'){
			new ResizeSensor($cache.mainHeader.get(0), function(){
				adaptBodyPadding();

				if(app.util.getConfig('navigation.vertical')) {
					setFixedTopOfsset();
				}
			});

			$cache.window.resize(function(){
				adaptBodyPadding();

				if(app.util.getConfig('navigation.vertical')) {
					setFixedTopOfsset();
				}
			});
		}

		// show|hide tablet menu with categories & customer services|contact links, hide 3rd level categories
		$cache.menuButton.on('click', function() {
			$cache.document.trigger($cache.event.toggleHideall);
			$cache.header.trigger($cache.event.headerChange);

			$cache.menuButton.toggleClass($cache.menuActiveClass);
			$cache.body.toggleClass($cache.menuOpened);
			$cache.tabletHeaderMenuNav.toggleClass($cache.hHiddenClass);
			$cache.document.find($cache.menuCategoryLvl3).each(function() {
				var $this = $(this);
				if($this.closest($cache.activeLvl2CategorySel).length > 0) {
					$this.removeClass($cache.hHiddenClass);
				} else {
					$this.toggleClass($cache.hHiddenClass);
				}
			});
			if($cache.leftHeaderNavigation.css('display') == 'none'){
				$cache.leftHeaderNavigation.toggleClass($cache.menuActiveClass);
				if ( !app.device.isMobileView() ) {
					app.components.global.navigation.adjustLeftNavHeight();
				}
			}
			else if($cache.leftHeaderNavigation.css('display') == 'block'){
				$cache.leftHeaderNavigation.toggleClass($cache.menuActiveClass);
				$cache.document.find($cache.menuCategoryLvl3).removeClass($cache.hHiddenClass);
			}

			$cache.document.trigger('category_menu.open');
		});

		var onscrollUpdatingEventTriggered = false;
		// add specific class to header when scrolling position is higher then 0 and page has some content
		$cache.window.on('scroll', function(){
			$cache.header = $($cache.headerSel);
			if ($cache.header.length && (($cache.main.height() > window.innerHeight) || app.util.getConfig('slimHeaderInAllPages'))) {
				var breakPoint = $cache.scrolledHeaderBreakPoint;

				if(
					!!app.components.header &&
					app.components.header.hasOwnProperty('custom') &&
					app.components.header.custom.hasOwnProperty('headerBreakpointCalc') &&
					typeof app.components.header.custom.headerBreakpointCalc === 'function'
				){
					breakPoint = app.components.header.custom.headerBreakpointCalc();
				}

				var scrolledHeader = $cache.header.hasClass($cache.scrolledHeaderCssClass);

				if(!onscrollUpdatingEventTriggered && $cache.window.scrollTop() > breakPoint){
					$cache.header.trigger('onscroll.updating');
					$cache.header.addClass($cache.scrolledHeaderCssClass);
					$cache.header.trigger('onscroll.updated');
					onscrollUpdatingEventTriggered = true;
				}
				else if(onscrollUpdatingEventTriggered && $cache.window.scrollTop() <= breakPoint){
					$cache.header.trigger('onscroll.updating');
					$cache.header.removeClass($cache.scrolledHeaderCssClass);
					$cache.header.trigger('onscroll.updated');

					if(!$cache.disablePaddingManagement) {
						app.components.toggler.custom.updateBodyPaddingTop($cache.mainHeader.outerHeight());
					}

					onscrollUpdatingEventTriggered = false;
				}

				if (app.device.isMobileView() && scrolledHeader !== $cache.header.hasClass($cache.scrolledHeaderCssClass)) {
					$cache.header.trigger('update.header');
				}

				var headerHeight = $cache.header.height();
				var breadcrumbsHeight = $cache.breadcrumbsBlock.height() || 0;
				var bodyPaddingTop = headerHeight + breadcrumbsHeight;
				$cache.refinementsBlock.css('padding-top', $cache.refinementsBlock.hasClass($cache.stickyKitStuckSel) ? bodyPaddingTop + 'px' : '0');
			}
		});

		//navigation menu is closing, if click outside on tablet
		$cache.document.on('click', hideNavigation);
		$('.js-toggler').on('click', hideNavigation); // to bypass toggler's e.stopPropagation()

		function hideNavigation(e) {
			if ( ($cache.menuButton.hasClass('m-active')) && !($(e.target).hasClass('l-main_navigation')) && !($(e.target).parents('.l-main_navigation.js-main_navigation.m-active').length || $(e.target).hasClass('js-vertical_menu-button')) ){
				e.preventDefault();
				$cache.menuButton.toggleClass('m-active');
				$cache.tabletHeaderMenuNav.toggleClass('h-hidden');
				$cache.leftHeaderNavigation.toggleClass('m-active');
				$cache.document.find('.b-menu_category-level_3').removeClass('h-hidden');
			}
		}
	}

	function updateMinicartQuantity(quantity) {
		const $minicartTitle = $($cache.minicartTitleSel);
		const $minicartQuantityValue = $($cache.minicartQuantityValueSel);

		if ($minicartTitle.length && $minicartQuantityValue.length) {
			$minicartTitle.removeClass('h-click-disable');
			const quantityTemplate = app.configs.minicart.quantityTemplate;
			const renderedTemplate = app.util.renderTemplate(quantityTemplate, {
				quantity: quantity.toString()
			});

			$minicartQuantityValue.text(renderedTemplate);

			if (quantity === 0 && !app.configs.minicart.showZeroValue) {
				$minicartQuantityValue.parent().addClass('h-hidden');
			} else {
				$minicartQuantityValue.parent().removeClass('h-hidden');
			}
		}
	}

	function update(updateID, data) {
		switch (updateID) {
			case 'updateMinicartQuantity':
				updateMinicartQuantity(data.quantity);

				break;
			case 'updateWishlistQuantity': {
				const $wishlistQuantityValue = $($cache.wishlistQuantityValueSel);

				app.wishlist.updateWishlistQuantity({
					quantity: data.quantity,
					wishlistTitleCtnr: $($cache.wishlistTitleSel),
					wishlistQuantityCtnr: $wishlistQuantityValue.parent(),
					wishlistQuantityValueCtnr: $wishlistQuantityValue
				});

				break;
			}
			default:
				break;
		}
	}

	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.header = {
		init: function(config) {
			initializeCache(config);
			initPromoBanner();
			initializeEvents();
			app.minicart.subscribe(this);
			app.wishlist.subscribe(this);
		},
		update: update
	};
})(window.app = window.app || {}, jQuery);
