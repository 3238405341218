const domainScriptId = app.preferences.oneTrustDomainId;
const srcValue = `https://cdn.cookielaw.org/consent/${domainScriptId}/otSDKStub.js`;

class OneTrust {
	constructor() {
		this.isOpen = false;
	}

	static getScript() {
		const scriptElement = document.createElement('script');

		scriptElement.setAttribute('src', srcValue);
		scriptElement.setAttribute('data-domain-script', domainScriptId);
		scriptElement.setAttribute('charset', 'UTF-8');

		return scriptElement;
	}

	isShouldBeOpened() {
		return !this.isOpen;
	}

	open() {
		this.isOpen = true;

		window.OptanonWrapper = () => {
			if (!!window.OneTrust && window.OneTrust.IsAlertBoxClosedAndValid() && !window.OneTrust.isOptanonWrapperOpenedNextBanner) {
				app.bannersMgr.checkAndOpenBanner();
				window.OneTrust.isOptanonWrapperOpenedNextBanner = true;
			}
		};

		const script = this.constructor.getScript();

		document.body.appendChild(script);
	}
}

app.banners.onetrust = new OneTrust();
